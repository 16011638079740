<ng-container *ngIf="isLoggedIn">
  <app-main *ngIf="!openSetup"></app-main>
  <ng-container *ngIf="openSetup">
    <router-outlet></router-outlet>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
</ng-container>

<app-loading-page></app-loading-page>

<ng-container *ngIf="isLoggedIn">
  <app-modal-nfe-print></app-modal-nfe-print>
  <app-modal-nfe-analysis></app-modal-nfe-analysis>
  <app-modal-nfe-xml></app-modal-nfe-xml>
  <app-modal-nfe-manifestation-log></app-modal-nfe-manifestation-log>
  <app-modal-nfe-product-analysis></app-modal-nfe-product-analysis>
  <app-modal-carta-correcao></app-modal-carta-correcao>
  <app-modal-grid-legend></app-modal-grid-legend>
  <app-modal-sefaz-situation></app-modal-sefaz-situation>
  <app-modal-view-manifestation-justification></app-modal-view-manifestation-justification>
  <app-modal-nfe-manifest-confirmation></app-modal-nfe-manifest-confirmation>
  <app-modal-nfe-manifest-resume></app-modal-nfe-manifest-resume>
  <app-modal-custom-grid-confirmation></app-modal-custom-grid-confirmation>
  <app-modal-tag-selection></app-modal-tag-selection>
  <app-modal-select-master></app-modal-select-master>
  <app-modal-product-advanced-report></app-modal-product-advanced-report>
</ng-container>
<p-toast
  [baseZIndex]="10000"
  [preventOpenDuplicates]="true"
  key="global-toast"></p-toast>
