import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';

import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { FooterModule } from '../footer/footer.module';
import { MenuModule } from '../menu/menu.module';
import { TopbarModule } from '../topbar/topbar.module';
import { MainComponent } from './main.component';

@NgModule({
  declarations: [MainComponent],
  exports: [MainComponent],
  imports: [
    BreadcrumbModule,
    ButtonModule,
    CommonModule,
    DashboardModule,
    FooterModule,
    MenuModule,
    RouterModule,
    ToolbarModule,
    TopbarModule,
    ToastModule
  ]
})
export class MainModule {}
