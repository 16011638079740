import { Component, OnDestroy, OnInit } from '@angular/core';
import DevExpress from 'devextreme';
import { Observable, Subscription, filter } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { ModalOptions } from '../../../core/models/interfaceObjects/modal/modal-options';
import { ModalService } from '../../../core/services/modal/modal.service';

@Component({
  selector: 'app-modal-custom-grid-confirmation',
  templateUrl: './modal-custom-grid-confirmation.component.html',
  styleUrls: ['./modal-custom-grid-confirmation.component.scss']
})
export class ModalCustomGridConfirmationComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  subscription: Subscription[] = [];
  ask = 'Deseja confirmar a exclusão da GRID PERSONALIZADA salva?';
  private modalName: string;
  data: any;
  modalTitle = 'Personalização da Grid';

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const observable: Observable<ModalOptions> = this.modalService
      .listenChanges()
      .pipe(
        filter(
          res =>
            res.name === 'MODAL_CONFIRMACAO_EXCLUSAO_GRID' ||
            res.name === 'MODAL_CONFIRMACAO_SOBRESCRITA_GRID' ||
            res.name === 'MODAL_CONFIRMACAO_EXCLUSAO_ALERTAS'
        )
      );
    const subscription = observable.subscribe((res: ModalOptions) => {
      this.modalName = res?.name ? res?.name : '';
      this.modalTitle = res?.data?.title ? res?.data?.title : this.modalTitle;
      this.open = true;
      this.ask = res.data?.message;
      this.data = res?.data;
    });
    this.subscription.push(subscription);
  }

  close() {
    this.open = false;
    this.modalName = undefined as any;
    this.ask = undefined as any;
    this.loading = false;
    this.data = undefined as any;
  }

  confirm() {
    this.modalService.answer({
      id: CONFEAR_PERMISSIONS.TODOS,
      name: this.modalName,
      data: {
        confirm: true,
        idFuncionalidade: this.data.idFuncionalidade
      }
    });
    this.close();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  cancel() {
    this.modalService.answer({
      id: CONFEAR_PERMISSIONS.TODOS,
      name: this.modalName,
      data: {
        confirm: false,
        idFuncionalidade: this.data.idFuncionalidade
      }
    });
    this.close();
  }
}
