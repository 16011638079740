import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { EventoManifestacao } from '../../../core/models/entities/nfe/evento-manifestacao';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-modal-grid-legend',
  templateUrl: './modal-grid-legend.component.html',
  styleUrls: ['./modal-grid-legend.component.scss']
})
export class ModalGridLegendComponent implements OnInit, OnDestroy {
  open: boolean;
  legend: EventoManifestacao | null;
  subscription: Subscription[] = [];

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res?.name === 'MODAL LEGEND' && res.open) {
        this.legend = res.data?.legend;
        this.open = true;
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.open = false;
    this.legend = null;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
