<section #menu id="menu-container" [class.hidden-menu]="!display">
  <section id="vertical-line-blue"></section>
  <section class="container-items">
    <app-menu-tree [data]="items"></app-menu-tree>
  </section>

  <div class="logos-container">
    <a href="https://oportunidades.certificaminas.com/confear" target="_blank">
      <img
        id="logo-certificate"
        src="assets/certificado-digital.png"
        alt="certificado digital" />
    </a>
    <div class="logo-egtec-container">
      <span>Desenvolvido por</span>
      <img src="assets/logos/logo-egtec.png" alt="certificado digital" />
    </div>
  </div>
</section>
