import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { jsPDF } from 'jspdf';
import { MessageService } from 'primeng/api';
import { Subscription, filter, lastValueFrom } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../consts/CONFEAR-PERMISSIONS';
import { DOWNLOAD_OPTIONS } from '../../consts/DOWNLOAD-OPTIONS';
import { EXPORT_OPTIONS } from '../../consts/EXPORT-OPTIONS';
import { GRID_CUSTOM_EVENTS } from '../../consts/GRID-CUSTOM-EVENTS';
import { GRID_OPTIONS } from '../../consts/GRID-OPTIONS';
import { MANIFEST_OPTIONS } from '../../consts/MANIFEST-OPTIONS';
import { GridOptionsEnum } from '../../enums/grid-options.enum';
import { EventoManifestacao } from '../../models/entities/nfe/evento-manifestacao';
import { Nfe } from '../../models/entities/nfe/nfe';
import { Funcionalidade } from '../../models/entities/user/funcionalidade';
import { User } from '../../models/entities/user/user';
import { ModalAnswer } from '../../models/interfaceObjects/modal/modal-answer';
import { UserGridConfigRequest } from '../../models/interfaceObjects/user-grid-config/user-grid-config-request';
import { UserGridConfigResponse } from '../../models/interfaceObjects/user-grid-config/user-grid-config-response';
import { NfeManifestacaoRequest } from '../../models/requests/nfe-manifestacao-request';
import { DefaultResponse } from '../../models/responses/default-response';
import { AuthService } from '../../services/auth/auth.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ModalService } from '../../services/modal/modal.service';
import { NfeService } from '../../services/nfe/nfe.service';

@Component({
  template: ''
})
export abstract class BaseGridComponent<T> implements AfterViewInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  data: DefaultResponse<T[]>;
  dataSource: T[] = [];
  userPermissions: Funcionalidade[] = [];
  legendOptions: EventoManifestacao[] = [];
  currentData: Nfe | any;
  currentLegend: EventoManifestacao;
  formatCurrency = { style: 'currency', currency: 'BRL' };
  selectedGridOption: GridOptionsEnum = GridOptionsEnum.PERSONALIZADA;
  selectedRows: T[] = [];
  gridConfig: UserGridConfigResponse;
  countGridRows = `${this.selectedRows?.length} / ${this.dataSource?.length}`;
  hasCustomGrid: boolean;
  subscriptions: Subscription[] = [];
  disableSaveGrid: boolean;
  disableDeleteGrid: boolean;
  hasChanges: boolean;
  readonly GRID_PERSONALIZADA: GridOptionsEnum = GridOptionsEnum.PERSONALIZADA;
  readonly manifestOptions = MANIFEST_OPTIONS;
  readonly notaCancelada = 0;
  readonly notaComCCe = 999;
  readonly notaStatusContigencia = 99;
  readonly exportOptions = EXPORT_OPTIONS;
  readonly downloadOptions = DOWNLOAD_OPTIONS;
  readonly gridOptions = GRID_OPTIONS;
  readonly NFE_CANCELED = 101;
  private screenWidth: number;
  private screenHeight: number;
  protected idFuncionalidade: number;
  readonly MODAL_CONFIRMACAO_MANIFESTACAO = 'MODAL_CONFIRMACAO_MANIFESTACAO';
  readonly MODAL_ETIQUETAS = 'TAG_MODAL_ANSWER';
  readonly MODAL_RESUMO_MANIFESTACAO = 'MODAL_RESUMO_MANIFESTACAO';
  readonly MODAL_CONFIRMACAO_EXCLUSAO_GRID = 'MODAL_CONFIRMACAO_EXCLUSAO_GRID';
  readonly MODAL_CONFIRMACAO_SOBRESCRITA_GRID =
    'MODAL_CONFIRMACAO_SOBRESCRITA_GRID';
  gridName: string;
  user: User;
  readonly moreOptionsButton = [
    {
      id: CONFEAR_PERMISSIONS.ABRIR_NFE_IMPRESSAO.toString(),
      label: 'Abrir NF-e para impressão (Shift+I)',
      icon: 'pi pi-file',
      order: 1
    },
    {
      id: CONFEAR_PERMISSIONS.TODOS.toString(),
      label: 'Download PDF',
      icon: 'pi pi-download',
      order: 2
    },
    {
      id: CONFEAR_PERMISSIONS.TODOS.toString(),
      label: 'Download XML',
      icon: 'pi pi-download',
      order: 3
    },
    {
      id: CONFEAR_PERMISSIONS.TODOS.toString(),
      label: 'Copiar chave NF-e',
      icon: 'pi pi-file',
      order: 4
    },
    {
      id: CONFEAR_PERMISSIONS.ANALISAR_NFE.toString(),
      label: 'Analisar esta NF-e (Shift+A)',
      icon: 'pi pi-file',
      order: 5
    },
    {
      id: CONFEAR_PERMISSIONS.ETIQUETAS.toString(),
      label: 'Etiquetas (Shift+E)',
      icon: 'pi pi-file',
      order: 6
    },
    {
      id: CONFEAR_PERMISSIONS.ABRIR_XML.toString(),
      label: 'Abrir XML',
      icon: 'pi pi-file',
      order: 7
    },
    {
      id: CONFEAR_PERMISSIONS.ABRIR_NFE_SITE_SEFAZ.toString(),
      label: 'Abrir NF-e no site da SEFAZ',
      icon: 'pi pi-file',
      order: 8
    },
    {
      id: CONFEAR_PERMISSIONS.HISTORICO_MANIFESTACAO.toString(),
      label: 'Histórico de manifestações',
      icon: 'pi pi-file',
      order: 9
    },
    {
      id: CONFEAR_PERMISSIONS.VISUALIZAR_JUSTIFICATIVA_CORRECAO.toString(),
      label: 'Visualizar justificativa da manifestação',
      icon: 'pi pi-file',
      order: 10
    },
    {
      id: CONFEAR_PERMISSIONS.VISUALIZAR_CARTA_CORRECAO.toString(),
      label: 'Visualizar carta de correção (CC-e)',
      icon: 'pi pi-file',
      order: 11
    },
    {
      id: CONFEAR_PERMISSIONS.VERIFICAR_SITUACAO_NFE_SEFAZ_ESTADUAL.toString(),
      label: 'Verificar situação da NF-e na SEFAZ estadual',
      icon: 'pi pi-file',
      order: 12
    }
  ];

  protected constructor(
    protected nfeService: NfeService,
    protected authService: AuthService,
    protected modalService: ModalService,
    protected messageService: MessageService,
    protected loadingService: LoadingService
  ) {}

  set gridPermission(id) {
    this.idFuncionalidade = id;
  }

  ngAfterViewInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  setCustomGrid() {
    if (this.gridConfig?.desktop && this.dataGrid?.instance) {
      if (this.screenWidth > 500) {
        this.dataGrid.instance.state(this.gridConfig?.desktop);
        this.selectedGridOption = GridOptionsEnum.PERSONALIZADA;
      } else {
        this.dataGrid.instance.state(this.gridConfig?.mobile);
        this.selectedGridOption = GridOptionsEnum.REDUZIDA;
      }
    } else {
      this.selectedGridOption = GridOptionsEnum.COMPLETA;
    }
  }

  async loadInitalConfigs() {
    this.getUserPermissions();
    await this.getCustomGrid();
    this.setCustomGrid();
    this.listenAnswerModal();
    if (this.userPermissions?.length > 0) {
      await this.listLegend();
    }
  }

  getUserPermissions() {
    const subscription = this.authService
      .getUserObservable()
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.userPermissions = user.grupo[0]?.funcionalidades;
        }
      });

    this.subscriptions.push(subscription);
  }

  async listLegend() {
    const response = await lastValueFrom(this.nfeService.listLegend());
    this.legendOptions = response.dados;
  }

  @HostListener('window:keydown.shift.i', ['$event'])
  shortcutKeyModalNfePrint() {
    const hasPermission = this.userPermissions.find(
      permission => +permission.id === CONFEAR_PERMISSIONS.ABRIR_NFE_IMPRESSAO
    );
    if (hasPermission && this.currentData && this.selectedRows.length <= 1) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.ABRIR_NFE_IMPRESSAO,
        data: this.currentData,
        open: true
      });
    }
  }

  @HostListener('window:keydown.shift.a', ['$event'])
  shortcutKeyModaNfeAnalysis() {
    const hasPermission = this.userPermissions.find(
      permission => +permission.id === CONFEAR_PERMISSIONS.ANALISAR_NFE
    );
    if (hasPermission && this.currentData && this.selectedRows.length <= 1) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.ANALISAR_NFE,
        data: this.currentData,
        open: true
      });
    }
  }

  @HostListener('window:keydown.shift.e', ['$event'])
  shortcutKeyModaNfeEtiquetas() {
    if (
      this.isTagPermission() &&
      (this.selectedRows.length || this.currentData)
    ) {
      const data = this.selectedRows.length
        ? { nfes: this.selectedRows }
        : this.currentData;
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.ETIQUETAS,
        data,
        open: true
      });
    }
  }

  refreshDataGrid($event: any) {}

  selectAll() {
    if (this.selectedRows.length < this.dataSource.length) {
      this.dataGrid.instance.selectRows(this.dataSource, true);
      this.selectedRows = this.dataSource;
    } else {
      this.dataGrid.instance.deselectRows(this.selectedRows);
    }
  }

  export(event: any, filename: string) {
    switch (event?.itemData?.value) {
      case 1:
        this.exportPdf(filename);
        break;
      case 2:
        this.onExportingCSV(event, filename + '.csv');
        break;
      case 3:
        this.exportWorksheet(event, filename + '.xlsx');
        break;
      case 4:
        this.exportWorksheet(event, filename + '.xls');
        break;
    }
  }

  onExportingCSV(event, filename: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Nfes');
    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet: worksheet
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          filename
        );
      });
    });

    event.cancel = true;
  }

  exportPdf(filename: string) {
    const doc = new jsPDF('landscape');
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGrid.instance
    }).then(() => {
      doc.save(filename + '.pdf');
    });
  }

  exportWorksheet(event, filename: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Nfes');

    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          filename
        );
      });
    });
    event.cancel = true;
  }

  selectRow(event: any) {
    this.currentData = event.data;
  }

  openNfeToPrint(row: any) {
    this.currentData = row.data;
    this.shortcutKeyModalNfePrint();
  }

  openModalLegend(event) {
    this.modalService.open({
      id: CONFEAR_PERMISSIONS.TODOS,
      name: 'MODAL LEGEND',
      open: true,
      data: {
        legend: event.itemData
      }
    });
  }

  listenGridChanges(event: any) {
    this.handlerGridMultipleSeletion(event);
    if (this.isCustomGridEvent(event.fullName)) {
      this.hasChanges = true;
      this.selectedGridOption = GridOptionsEnum.PERSONALIZADA;
      this.setSaveButtonState();
      this.setDeleteButtonState();
    } else {
      this.hasChanges = false;
    }
  }

  handlerGridMultipleSeletion(event) {
    if (event.name === 'selectedRowKeys') {
      this.selectedRows = event.value;
      this.countGridRows = `${this.selectedRows?.length} / ${this.dataSource?.length}`;
    }
  }

  addRowClass({ rowElement, data }) {
    if (data?.statusSefaz?.id === this.NFE_CANCELED) {
      rowElement.classList.add('row-nfe-canceled');
    }

    /*if (data?.totalCartaCorrecao > 0) {
      rowElement.classList.add('row-nfe-with-cce');
    }*/

    if (data?.indDestacar) {
      rowElement.classList.add('row-bold');
    }
  }

  async handleCustomGrid() {
    if (this.gridConfig?.desktop) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.TODOS,
        name: this.MODAL_CONFIRMACAO_SOBRESCRITA_GRID,
        open: true,
        data: {
          message:
            'Encontramos uma GRID Personalizada salva. Deseja sobrescrevê-la?',
          idFuncionalidade: this.idFuncionalidade
        }
      });
    } else {
      await this.saveCustomGrid();
    }
  }

  async saveCustomGrid() {
    const { columns, filterPanel, filterValue, searchText } =
      this.dataGrid.instance.state();
    const columnsWithoutFilters = columns.map(column => {
      const {
        dataField,
        dataType,
        fixed,
        name,
        visible,
        visibleIndex,
        groupIndex
      } = column;

      return {
        dataField,
        dataType,
        fixed,
        name,
        visible,
        visibleIndex,
        groupIndex
      };
    });

    const gridConfig: UserGridConfigRequest = {
      nomeGrid: this.gridName,
      idFuncionalidade: this.idFuncionalidade,
      configuracao: {
        columns: columnsWithoutFilters,
        filterPanel,
        filterValue,
        searchText
      }
    };

    const response = await lastValueFrom(
      this.authService[
        this.gridConfig?.desktop ? 'updateGridConfig' : 'saveGridConfig'
      ](gridConfig)
    );

    if (response?.dados) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: response?.dados?.mensagem
          ? response?.dados?.mensagem
          : 'Grid salva com sucesso',
        key: 'global-toast'
      });
      this.hasChanges = false;
      await this.getCustomGrid();
    }
  }

  async getCustomGrid() {
    const response = await lastValueFrom(
      this.authService.getGridConfig(this.idFuncionalidade)
    );

    if (!response?.error) {
      this.gridConfig = response?.dados;
      if (this.gridConfig?.desktop) {
        this.hasCustomGrid = true;
      }
    }

    this.setDeleteButtonState();
    this.setSaveButtonState();
  }

  setSaveButtonState() {
    this.disableSaveGrid = !(
      this.selectedGridOption === this.GRID_PERSONALIZADA && this.hasChanges
    );
  }

  setDeleteButtonState() {
    this.disableDeleteGrid = !(
      this.selectedGridOption === this.GRID_PERSONALIZADA && this.hasCustomGrid
    );
  }

  async resetGridConfig() {
    const response = await lastValueFrom(
      this.authService.deleteGridConfig(this.idFuncionalidade)
    );

    if (response?.mensagem) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: response?.mensagem,
        key: 'global-toast'
      });
      if (this.dataGrid?.instance) {
        this.dataGrid.instance.state(null);
      }
      this.selectedGridOption = GridOptionsEnum.COMPLETA;
      this.hasCustomGrid = false;
      this.hasChanges = false;
      this.gridConfig = undefined as any;

      this.setSaveButtonState();
      this.setDeleteButtonState();
    }
  }

  async confirmResetGridConfig() {
    if (this.gridConfig?.desktop) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.TODOS,
        name: this.MODAL_CONFIRMACAO_EXCLUSAO_GRID,
        open: true,
        data: {
          idFuncionalidade: this.idFuncionalidade,
          message: 'Deseja confirmar a exclusão da GRID Personalizada salva?'
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  async selectGridOption(event: any) {
    this.selectedGridOption = event.item.value;
    if (
      event.item.value === GridOptionsEnum.PERSONALIZADA &&
      !this.hasChanges
    ) {
      await this.getCustomGrid();
    }
    if (!this.gridConfig) {
      this.dataGrid.instance.state(null);
      return;
    }
    const { mobile, desktop } = this.gridConfig;

    if (this.dataGrid?.instance && this.gridConfig) {
      switch (this.selectedGridOption) {
        case GridOptionsEnum.REDUZIDA:
          if (mobile) {
            this.dataGrid.instance.state(mobile);
            this.hasChanges = false;
          }
          break;
        case GridOptionsEnum.COMPLETA:
          this.hasChanges = false;
          this.dataGrid.instance.state(null);

          break;
        case GridOptionsEnum.PERSONALIZADA:
          if (!this.hasChanges) {
            this.dataGrid.instance.state(desktop);
          }
          break;

        default:
          this.dataGrid.instance.state(null);
      }
    }

    this.setDeleteButtonState();
    this.setSaveButtonState();
  }

  openModalManifestationConfirmation(event: any, singleRow?: any) {
    let data = singleRow ? [singleRow] : this.selectedRows;

    if (this.selectedRows.length || singleRow) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.MANIFESTACAO_NFE,
        name: this.MODAL_CONFIRMACAO_MANIFESTACAO,
        open: true,
        data: {
          nfes: data,
          eventoManifestacao: {
            id: event.itemData?.id,
            descricao: event.itemData?.descricao
          }
        }
      });
    }
  }

  openModalTagSituation() {
    if (this.selectedRows.length) {
      this.modalService.open({
        id: CONFEAR_PERMISSIONS.ETIQUETAS,
        open: true,
        data: {
          nfes: this.selectedRows
        }
      });
    }
  }

  listenAnswerModal() {
    const subscription = this.modalService
      .listenAnswer()
      .subscribe((answer: ModalAnswer) => {
        if (
          answer.id === CONFEAR_PERMISSIONS.MANIFESTACAO_NFE &&
          answer.name === this.MODAL_CONFIRMACAO_MANIFESTACAO
        ) {
          this.manifestar(
            answer.data.justificativa,
            answer.data.eventoManifestacao,
            answer?.data?.nfes
          );
        }

        if (
          answer.id === CONFEAR_PERMISSIONS.TODOS &&
          answer.name === this.MODAL_CONFIRMACAO_EXCLUSAO_GRID
        ) {
          if (
            answer?.data?.confirm &&
            answer.data.idFuncionalidade === this.idFuncionalidade
          ) {
            this.resetGridConfig();
          }
        }

        if (
          answer.id === CONFEAR_PERMISSIONS.TODOS &&
          answer.name === this.MODAL_CONFIRMACAO_SOBRESCRITA_GRID &&
          answer.data.idFuncionalidade === this.idFuncionalidade
        ) {
          if (answer?.data?.confirm) {
            this.saveCustomGrid();
          }
        }
      });
    this.subscriptions.push(subscription);
  }

  manifestar(justificativa: string, eventoManifestacao, nfes: any[]) {
    this.loadingService.open();
    const manifestacaoRequest: NfeManifestacaoRequest = {
      justificativa,
      eventoManifestacao,
      nfes: [
        ...nfes.map((nfe: any) => {
          const { id, chaveNfe, cnpjDestinatario } = nfe;
          return {
            id,
            chaveNfe,
            cnpjDestinatario
          };
        })
      ]
    };
    const subscription = this.nfeService
      .manifest(manifestacaoRequest)
      .subscribe(res => {
        if (res?.dados) {
          manifestacaoRequest.nfes?.forEach(nfe => {
            let foundNfe: any = this.dataSource.find((data: any) => {
              if (nfe.chaveNfe === data?.chaveNfe) {
                return data;
              }
            });

            foundNfe.eventoManifestacao = this.manifestOptions.find(option => {
              if (option.id === manifestacaoRequest.eventoManifestacao.id) {
                return option;
              }
            });
          });
          this.loadingService.close();
          this.modalService.open({
            id: CONFEAR_PERMISSIONS.MANIFESTACAO_NFE,
            name: this.MODAL_RESUMO_MANIFESTACAO,
            open: true,
            data: res.dados
          });
          this.dataGrid.instance.clearSelection();
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'As notas foram manifestadas com sucesso!',
              key: 'global-toast'
            });
          }, 1000);
        }
      });
    this.subscriptions.push(subscription);
  }

  isCustomGridEvent = eventName => {
    const name = eventName.split('.');
    return GRID_CUSTOM_EVENTS.includes(name[name.length - 1]);
  };

  tagModalObservable() {
    return this.modalService
      .listenAnswer()
      .pipe(filter(answer => answer.name === this.MODAL_ETIQUETAS));
  }

  openTabSefaz(event) {
    if (
      event?.item?.id === CONFEAR_PERMISSIONS.ABRIR_NFE_SITE_SEFAZ.toString()
    ) {
      const urlSefaz = `http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=&nfe=${event?.data?.chaveNfe}`;
      window.open(urlSefaz, '_blank');
    }
  }

  isTagPermission() {
    return this.userPermissions.find(
      permission => +permission.id === CONFEAR_PERMISSIONS.ETIQUETAS
    );
  }

  ngOnDestroy() {
    this.modalService.clear();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.dataGrid = undefined as any;
    this.data = undefined as any;
    this.dataSource = [];
    this.userPermissions = [];
    this.currentData = undefined;
    this.selectedGridOption = 0;
    this.selectedRows = [];
    this.gridConfig = undefined as any;
    this.hasCustomGrid = false;
  }

  async downloadInLot(event: any) {
    const { downloadPdf, downloadXml } = event?.itemData;
    const nfes = this.selectedRows.map((data: any) => {
      return data.id;
    });
    if (this.selectedRows.length) {
      this.loadingService.open();
      const response = await lastValueFrom(
        this.nfeService.downloadInLot({
          nfes,
          downloadPdf,
          downloadXml
        })
      );
      if (response?.error) {
        let errorMsg =
          nfes.length > 600
            ? 'O sistema permite o download de, no máximo, 600 notas.'
            : 'Não foi possível efetuar o download.';
        this.messageService.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: errorMsg,
          key: 'global-toast',
          sticky: true
        });
      } else {
        const file = new Blob([response], { type: 'application/zip' });
        const url = window.URL.createObjectURL(file);
        window.open(url);
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        detail: 'Por favor, selecione pelo menos uma nota fiscal.',
        key: 'global-toast'
      });
    }
    this.loadingService.close();
  }

  onCellPrepared(event: any) {
    if (event.rowType === 'data') {
      if (
        event.column.caption !== 'CLASS. COMPRA' &&
        event.column.caption !== 'CUSTO REAL'
      ) {
        if (event?.data?.codCorLinha === '1') {
          event.cellElement.style.cssText = 'background-color: #f5f5f5';
        } else {
          event.cellElement.style.cssText = 'background-color: white';
        }
      } else {
        if (event?.data?.classificacaoCompra) {
          event.cellElement.style.cssText = `text-align:center;background-color: ${event?.data?.classificacaoCompra?.cor}`;
        }
      }
    }
  }
}
