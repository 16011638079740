import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from 'core/models/entities/company/company';
import { User } from 'core/models/entities/user/user';
import { UsuarioSistema } from 'core/models/entities/user/usuario-sistema';
import { AuthService } from 'core/services/auth/auth.service';
import { CompanyService } from 'core/services/company/company.service';
import { SidebarFiltersService } from 'core/services/sidebar-filters/sidebar-filters.service';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-filters',
  templateUrl: './company-filters.component.html',
  styleUrls: ['./company-filters.component.scss']
})
export class CompanyFiltersComponent implements OnInit {
  @Input() usuariosMaster: UsuarioSistema[];
  @Output() filters = new EventEmitter();
  form: FormGroup;
  loggedUser: User;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private sidebarFiltersService: SidebarFiltersService,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    this.buildForm();
    await this.listUsuarioMaster();

    this.listenSidebarFilterActions();
  }

  listenSidebarFilterActions() {
    this.sidebarFiltersService.receive().subscribe(action => {
      if (action?.send) {
        this.send();
      }

      if (action?.reset) {
        this.reset();
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      idUsuariosMaster: [],
      cnpjEmpresas: []
    });
  }

  async listUsuarioMaster() {
    const response = await lastValueFrom(
      this.authService.listSistemUserByGroup('1')
    );
    if (response?.dados) {
      this.usuariosMaster = response?.dados;
    }
  }

  send() {
    let errors: string[] = [];
    if (this.form?.errors) {
      errors = Object.values(this.form.errors);
    }
    if (this.form.valid) {
      this.filters.emit(this.form.value);
      this.sidebarFiltersService.close();
    } else {
      errors.forEach(error => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Campos obrigatórios',
          key: 'global-toast',
          sticky: true,
          detail: error
        });
      });
    }
  }

  reset() {
    this.form.reset({ idUsuariosMaster: [], cnpjEmpresas: [] });
  }

  OnChangeUsuariosMaster(event) {
    this.form.get('idUsuariosMaster')?.setValue(event);
  }

  async getLoggedUser() {
    this.authService.getUserObservable().subscribe(user => {
      if (user) {
        this.loggedUser = user;
      }
    });
  }
}
