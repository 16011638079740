import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { GridProductAdvancedReportModule } from '../../grid-product-advanced-report/grid-product-advanced-report.module';
import { ModalProductAdvancedReportComponent } from './modal-product-advanced-report.component';

@NgModule({
  declarations: [ModalProductAdvancedReportComponent],
  imports: [
    CommonModule,
    ModalModule,
    LoadingFragmentModule,
    GridProductAdvancedReportModule
  ],
  exports: [ModalProductAdvancedReportComponent]
})
export class ModalProductAdvancedReportModule {}
