import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class InitialSetupService {
  private url = `${environment.baseUrl}/setup-inicial`;

  constructor(private http: HttpClient) {}

  list(): Observable<any> {
    return this.http.get<any>(`${this.url}/etapas`).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
  }

  update(id: number) {
    return this.http.put<any>(`${this.url}/atualiza-etapa/${id}`, {}).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }
}
