import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-head-modal-user-register-update',
  templateUrl: './head-modal-user-register-update.component.html',
  styleUrls: ['./head-modal-user-register-update.component.scss']
})
export class HeadModalUserRegisterUpdateComponent {
  @Input() currentLogin = 'serv.barra';

  constructor() {}
}
