import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MenuItem } from '../../models/entities/menu/menuItem';
import { User } from '../../models/entities/user/user';
import { LocalStorage } from '../../models/interfaceObjects/local-storage/local-storage';
import { IOMenuItem } from '../../models/interfaceObjects/menu/IOMenuItem';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  localStorageSubject: BehaviorSubject<LocalStorage | null>;
  private localStorageValue: LocalStorage | null;

  constructor() {
    this.localStorageValue = this.getBrowserStorage();
    this.localStorageSubject = new BehaviorSubject<LocalStorage | null>(
      this.localStorageValue
    );
  }

  init() {
    const confear = {
      user: this.localStorageValue?.user ?? '',
      menu: this.localStorageValue?.menu ?? '',
      IOMenu: this.localStorageValue?.menu ?? ''
    };
    localStorage.setItem('confear', JSON.stringify(confear));
    this.localStorageValue = this.getBrowserStorage();

    this.localStorageSubject.next(this.localStorageValue);
  }

  getValue = () => this.localStorageValue;

  getBrowserStorage = () => {
    const storage = localStorage.getItem('confear');
    if (storage) {
      return JSON.parse(storage);
    }

    return null;
  };

  getObservable = (): Observable<LocalStorage | null> =>
    this.localStorageSubject.asObservable();

  updateProperty(propertyName: string, value: User | IOMenuItem | MenuItem[]) {
    if (this.localStorageValue?.hasOwnProperty(propertyName)) {
      this.localStorageValue[propertyName] = value;
      localStorage.setItem('confear', JSON.stringify(this.localStorageValue));
    }
    this.localStorageSubject.next(this.localStorageValue);
  }

  clear = (): void => {
    localStorage.removeItem('confear');
    this.localStorageSubject.next(null);
  };
}
