import { ManifestOptions } from '../models/interfaceObjects/manifest-options';

export const MANIFEST_OPTIONS: ManifestOptions[] = [
  {
    id: 2,
    descricao: 'Confirmação da Operação',
    icone: {
      class: 'material-icons',
      cor: '#009F4F',
      lib: 'material',
      nome: 'check_circle'
    }
  },
  {
    id: 3,
    descricao: 'Desconhecimento da Operação',
    icone: {
      class: 'material-icons',
      cor: '#1A11F7',
      lib: 'material',
      nome: 'help'
    }
  },
  {
    id: 4,
    descricao: 'Operação não realizada',
    icone: {
      class: 'material-icons',
      cor: '#FF0000',
      lib: 'material',
      nome: 'cancel'
    }
  }
];
