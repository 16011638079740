import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

interface Config {
  send?: boolean;
  reset?: boolean;
  close?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SidebarFiltersService {
  private subject: BehaviorSubject<Config> = new BehaviorSubject<Config>({});

  constructor() {}

  send() {
    this.subject.next({ send: true });
  }

  reset() {
    this.subject.next({ reset: true });
  }

  close() {
    this.subject.next({ close: true });
  }

  receive(): Observable<Config> {
    return this.subject;
  }
}
