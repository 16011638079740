import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription, lastValueFrom } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { User } from '../../../core/models/entities/user/user';
import { AuthService } from '../../../core/services/auth/auth.service';
import { ModalService } from '../../../core/services/modal/modal.service';

@Component({
  selector: 'app-modal-select-master',
  templateUrl: './modal-select-master.component.html',
  styleUrls: ['./modal-select-master.component.scss']
})
export class ModalSelectMasterComponent implements OnInit, OnDestroy {
  open: boolean;
  masterList: { id: number; nome: string }[] = [];
  selectedMaster: { id: number; nome: string };
  loggedUser: User;
  subscription: Subscription[] = [];
  private MODAL_CHANGE_USER_MASTER = 'MODAL_CHANGE_USER_MASTER';

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.TODOS &&
        res.open &&
        res.name === this.MODAL_CHANGE_USER_MASTER
      ) {
        this.getLoggedUser();
        this.open = true;
      }
    });

    this.subscription.push(subscription);
  }

  getLoggedUser() {
    this.loggedUser = this.authService.getUserValue();
    this.masterList = this.loggedUser.usuariosMaster;
  }

  close() {
    this.masterList = [];
    this.open = false;
    this.selectedMaster = undefined as any;
  }

  async confirm() {
    if (this.selectedMaster) {
      const response = await lastValueFrom(
        this.authService.changeMaster(this.selectedMaster?.id)
      );

      if (response) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Perfil alterado com sucesso!',
          key: 'global-toast',
          sticky: true
        });

        await this.router.navigate(['inicio']);
      }
    }
    this.close();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
