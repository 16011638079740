import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WelcomePageSetupRoutingModule } from './welcome-page-setup-routing.module';
import { WelcomePageSetupComponent } from './welcome-page-setup.component';

@NgModule({
  declarations: [WelcomePageSetupComponent],
  exports: [WelcomePageSetupComponent],
  imports: [CommonModule, WelcomePageSetupRoutingModule]
})
export class WelcomePageSetupModule {}
