import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { TieredMenuModule } from 'primeng/tieredmenu';

import { NotificationDropdownModule } from '../notification-dropdown/notification-dropdown.module';
import { TopbarComponent } from './topbar.component';

@NgModule({
  declarations: [TopbarComponent],
  exports: [TopbarComponent],
  imports: [
    CommonModule,
    BadgeModule,
    TieredMenuModule,
    NotificationDropdownModule
  ]
})
export class TopbarModule {}
