import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { MenuModule } from '../menu/menu.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [DashboardComponent],
  exports: [DashboardComponent],
  imports: [CommonModule, RouterModule, MenuModule, BreadcrumbModule]
})
export class DashboardModule {}
