import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges {
  @ViewChild('dialog') dialog;
  @Input() display: boolean;
  @Input() modalTitle: string;
  @Input() nfeNumber;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() width = '60vw';
  @Input() height = '';
  @Input() hasLineHeader = true;
  @Input() blockScroll = false;
  @Input() overflowYHidden = false;
  @Input() closable = true;
  @Input() closeOnEscape = true;
  @Input() removeTitleMargin = true;
  @Input() modalFull: boolean;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.modalFull) {
      this.maximize();
    }
  }
  maximize() {
    this.dialog.maximize();
  }

  close(event: any) {
    this.closeModal.emit(false);
  }
}
