import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalViewManifestationJustificationComponent } from './modal-view-manifestation-justification.component';

@NgModule({
  declarations: [ModalViewManifestationJustificationComponent],
  imports: [CommonModule, ModalModule, LoadingFragmentModule],
  exports: [ModalViewManifestationJustificationComponent]
})
export class ModalViewManifestationJustificationModule {}
