import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl
} from '@angular/platform-browser';

@Component({
  selector: 'app-modal-video-setup',
  templateUrl: './modal-video-setup.component.html',
  styleUrls: ['./modal-video-setup.component.scss']
})
export class ModalVideoSetupComponent implements OnInit {
  @Input() open: boolean;
  @Output() responseModal = new EventEmitter();
  urlVideo = 'https://www.youtube.com/embed/LacrF_BzjDQ';
  safeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlVideo);
  }

  close() {
    this.responseModal.emit({ close: true });
  }
}
