<app-modal
  [overflowYHidden]="true"
  [blockScroll]="true"
  [hasLineHeader]="false"
  [width]="'90vw'"
  (closeModal)="close()"
  [display]="open">
  <section header>Abrir XML NF-e {{ nfe?.numeroNfe }}</section>
  <app-loading-fragment [loading]="loading"></app-loading-fragment>
  <section class="container-xml">
    <section class="container-buttons-xml" id="container-nfe-option">
      <button
        pButton
        (click)="download()"
        label="BAIXAR XML"
        class="btn-download-xml"
        icon="pi pi-file-excel"></button>

      <div class="container-info">
        <span class="vertical-line"></span>
        <em class="pi pi-info-circle"></em>
        Para realizar uma busca no documento abaixo, pressione F3 e digite a
        palavra que gostaria de procurar no documento.
      </div>
    </section>
    <div class="content-xml">
      <pre lang="xml">{{ xml }}</pre>
    </div>
  </section>
</app-modal>
