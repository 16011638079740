import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../core/models/entities/company/company';
import { CompanyService } from '../../core/services/company/company.service';
import { LoadingService } from '../../core/services/loading/loading.service';

@Component({
  selector: 'app-digital-certificate-management',
  templateUrl: './digital-certificate-management.component.html',
  styleUrls: ['./digital-certificate-management.component.scss']
})
export class DigitalCertificateManagementComponent implements OnInit {
  dataSource: any;
  pageTitle = 'Gerenciamento de Certificado Digital';
  selectedCompany: Company;
  showModalCertificate: boolean;
  urlNewCertificate = 'https://oportunidades.certificaminas.com/confear';

  constructor(
    private companyService: CompanyService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.loadingService.open();
    await this.getCompanies();
    this.loadingService.close();
  }

  async getCompanies() {
    const response = await lastValueFrom(this.companyService.list(true));
    this.dataSource = response.dados.map(company => {
      const { diasExpiracao, tipoCertificado } = company;
      const max = tipoCertificado === 'A1' ? 365 : 1095;
      const perc = (100 * (max - diasExpiracao)) / max;
      const cnpjMask = company.cnpj;
      return { ...company, progress: perc, cnpjMask };
    });
  }
  formatBarLabel() {
    return ``;
  }

  formatLabelProgressbar(row): string {
    const { diasExpiracao, dataValidade } = row?.data;
    const stringDate = new Date(dataValidade).toLocaleDateString();
    return diasExpiracao > 0
      ? `Validade: ${stringDate} (faltam ${diasExpiracao} dias)`
      : `Expirado em ${stringDate} `;
  }

  refreshDataGrid(): void {
    this.getCompanies();
  }

  openModalValidate(company: Company) {
    this.selectedCompany = company;
    this.showModalCertificate = true;
  }

  listenModalValidate(event) {
    if (event?.update) {
      this.refreshDataGrid();
    }
    this.showModalCertificate = false;
    this.selectedCompany = undefined as any;
  }
  newCertificate() {
    window.open(this.urlNewCertificate, '_blank');
  }
  cnpjTransform(cell) {
    return cell.cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
}
