import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { Cce } from '../../../core/models/entities/nfe-cc/cce';
import { NfeCartaCorrecao } from '../../../core/models/entities/nfe-cc/nfe-carta-correcao';
import { Nfe } from '../../../core/models/entities/nfe/nfe';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-modal-carta-correcao',
  templateUrl: './modal-carta-correcao.component.html',
  styleUrls: ['./modal-carta-correcao.component.scss']
})
export class ModalCartaCorrecaoComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfe: Nfe | any;
  subscription: Subscription[] = [];
  cartasCorrecao: NfeCartaCorrecao = {
    chaveNfe: '',
    destinatario: '',
    fornecedor: '',
    cnpjFornecedor: '',
    dataEmissao: '',
    cces: []
  };
  selectedCartaCorrecao: Cce;
  errorMessage: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.VISUALIZAR_CARTA_CORRECAO &&
        res.open
      ) {
        this.resetFields();
        setTimeout(() => {
          this.nfe = res.data;
          if (this.nfe.totalCartaCorrecao > 0) {
            this.loading = true;
            this.open = true;
          }
          this.getCC();
        }, 500);
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.resetFields();
    this.open = false;
    this.loading = false;
  }

  getCC() {
    this.nfeService
      .getCartaCorrecao(this.nfe.chaveNfe)
      .subscribe((response: any) => {
        if (response.dados) {
          this.cartasCorrecao = response.dados;
          this.selectedCartaCorrecao =
            response?.dados?.cces?.length > 0 ? response?.dados?.cces[0] : null;
        }

        if (response?.error) {
          this.errorMessage = response.error?.mensagem?.reduce((acc, cc) => {
            this.errorMessage.concat(cc);
          });
        }
        this.loading = false;
      });
  }

  resetFields() {
    this.errorMessage = '';
    this.nfe = undefined;
    this.cartasCorrecao = {
      chaveNfe: '',
      destinatario: '',
      fornecedor: '',
      cnpjFornecedor: '',
      dataEmissao: '',
      cces: []
    };
  }

  selectCce(cce: Cce) {
    this.selectedCartaCorrecao = cce;
  }

  getIndex(index: number) {
    return this.cartasCorrecao?.cces?.length
      ? this.cartasCorrecao?.cces?.length - index
      : 0;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
