import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MenuService } from '../../services/menu/menu.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  display = true;
  subscription: Subscription[] = [];

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    this.listenDisplayMenu();
  }

  listenDisplayMenu() {
    const subscription = this.menuService
      .getDisplayMenuObservable()
      .subscribe((display: boolean) => {
        this.display = display;
      });

    this.subscription.push(subscription);
  }

  mostrarMenu() {
    this.display = !this.display;
  }
  toggleMenu() {
    this.display ? this.menuService.close() : this.menuService.open();
  }

  ngOnDestroy() {
    this.subscription.forEach(subs => subs.unsubscribe());
  }
}
