import { Type } from '@angular/core';

export class Step {
  id: number;
  label: string;
  isCurrent: boolean;
  isLast: boolean;
  isFirst: boolean;
  isVisited: boolean;
  next: boolean;
  previous: boolean;
  component: Type<any>;
  order: number;
  icon?: string;
  url?: string;
  isRequired?: boolean;
  data?: any;

  static markAsVisited(steps: Step[], current: Step) {
    steps.forEach((step, index, array) => {
      step.isCurrent = step.order === current.order;
      if (step.order < current.order) {
        step.isVisited = true;
      }
    });
  }

  static getCurrent(steps: Step[]): Step {
    return steps.find((step: Step) => {
      return step.isCurrent;
    }) as Step;
  }
}
