import { Component, OnInit } from '@angular/core';

import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {
  display: boolean;
  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.listen().subscribe((display: boolean) => {
      this.display = display;
    });
  }
}
