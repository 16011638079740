import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-cost-legend',
  templateUrl: './modal-cost-legend.component.html',
  styleUrls: ['./modal-cost-legend.component.scss']
})
export class ModalCostLegendComponent {
  @Input() open: boolean;
  @Output() closeModal = new EventEmitter();
  constructor() {}

  close() {
    this.closeModal.emit({ close: true });
  }
}
