import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../../core/components/icon/icon.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalGridLegendComponent } from './modal-grid-legend.component';

@NgModule({
  declarations: [ModalGridLegendComponent],
  exports: [ModalGridLegendComponent],
  imports: [CommonModule, ModalModule, IconModule]
})
export class ModalGridLegendModule {}
