<app-modal
  [width]="'40vw'"
  height="300px"
  modalTitle="Alteração de Perfil de Acesso"
  (closeModal)="close()"
  [removeTitleMargin]="false"
  [display]="open">
  <section content class="container-modal-grid-confirmation">
    <section class="p-fluid grid formgrid">
      <div class="input-group field col-12">
        <label for="master-select" class="">
          Selecione o usuário Master desejado:
        </label>
        <p-dropdown
          *ngIf="masterList?.length"
          [(ngModel)]="selectedMaster"
          id="master-select"
          placeholder="Selecione"
          [options]="masterList"
          optionLabel="nome"></p-dropdown>
      </div>
    </section>
  </section>
  <section footer class="container-buttons">
    <button
      (click)="close()"
      class="btn-cancel"
      icon="pi pi-times"
      label="Cancelar"
      pButton
      type="button"></button>
    <button
      (click)="confirm()"
      [disabled]="!selectedMaster"
      class="btn-confirm mr-2"
      icon="pi pi-check"
      label="Alterar Perfil"
      pButton
      type="button"></button>
  </section>
</app-modal>
