import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() visible: boolean;
  @Input() titleSidebar: string;
  @Output() closeSidebar = new EventEmitter();
  @Input() width = '50vw';
  constructor() {}

  close() {
    this.closeSidebar.emit({ visible: false });
  }
}
