<app-modal
  width="350px"
  [modalTitle]="modalTitle"
  [removeTitleMargin]="false"
  (closeModal)="close()"
  [display]="open">
  <section class="container-modal-grid-confirmation">
    <span class="text-center mb-4">{{ ask }}</span>
    <section class="container-buttons">
      <button
        pButton
        type="button"
        (click)="confirm()"
        label="Sim"
        class="btn-confirm mr-2"
        icon="pi pi-check"></button>
      <button
        pButton
        type="button"
        (click)="cancel()"
        label="Não"
        class="btn-cancel"
        icon="pi pi-times"></button>
    </section>
  </section>
</app-modal>
