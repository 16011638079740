import { Component, Input, OnInit } from '@angular/core';

import { Step } from '../../../../core/models/interfaceObjects/step/step';
import { StepService } from '../../../../core/services/step/step.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {
  @Input() steps: Step[];
  currentStep: Step;

  constructor(private stepService: StepService) {}

  ngOnInit() {
    this.stepService.listenCurrent().subscribe(current => {
      this.currentStep = current;
    });
  }

  calcWidth() {
    return `calc(${100 / this.steps.length}vw)`;
  }
}
