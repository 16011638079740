import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UsuarioSistema } from 'core/models/entities/user/usuario-sistema';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { SoftwarePdv } from '../../../core/models/entities/company/softwarePdv';
import { User } from '../../../core/models/entities/user/user';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CompanyService } from '../../../core/services/company/company.service';
import { EgtecService } from '../../../core/services/egtec/egtec.service';
import { validateCNPJ } from '../../../core/utils/validateCnpj';

@Component({
  selector: 'app-modal-register-company',
  templateUrl: './modal-register-company.component.html',
  styleUrls: ['./modal-register-company.component.scss']
})
export class ModalRegisterCompanyComponent implements OnChanges {
  @Output() responseModal = new EventEmitter();
  @Input() company: any;
  @Input() open: boolean;
  @Input() softwares: SoftwarePdv[];
  @Input() usuariosSistema: UsuarioSistema[];
  form: FormGroup;
  loading: boolean;
  title = 'Cadastro de Empresa';
  loggedUser: User;

  constructor(
    private fb: FormBuilder,
    private egtecService: EgtecService,
    private companyService: CompanyService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    this.getLoggedUser();
    this.buildForm();
    await this.activateUsuarioMasterField();
    if (this.company) {
      this.title = 'Edição de Empresa';
      this.setForm();
    } else {
      this.title = 'Cadastro de Empresa';
      this.form.disable();
      this.cnpjControl?.enable();
    }
  }

  clear() {
    this.form?.reset({ emitEvent: true });
  }

  setForm() {
    const {
      cnpj,
      cnpjMatriz,
      nomeFantasia,
      razaoSocial,
      telefoneComercial,
      endereco,
      inscricaoEstadual,
      softwarePdv,
      contatos,
      id
    } = this.company;

    let responsavel;
    if (contatos?.length) {
      responsavel = contatos?.find(contato => {
        return contato?.indResponsavelEmpresa === true;
      });
    }
    this.form.patchValue({
      id,
      endereco: {
        logradouro: endereco?.logradouro,
        complemento: endereco?.complemento,
        bairro: endereco?.bairro,
        numero: endereco?.numero,
        cidade: endereco?.cidade,
        uf: endereco?.uf,
        cep: endereco?.cep
      },
      responsavel: {
        email: responsavel?.email,
        celular: responsavel?.celular,
        nome: responsavel?.nome
      },
      telefoneComercial,
      razaoSocial,
      softwarePdv: {
        id: softwarePdv.id,
        descricao: softwarePdv.descricao
      },
      inscricaoEstadual,
      cnpj,
      nomeFantasia,
      cnpjMatriz,
      contatos: contatos
    });
    this.configEditMode();
  }

  configEditMode() {
    if (this.cnpjControl?.value) {
      this.cnpjControl?.disable();
    }
    this.enderecoGroup.disable();
    this.enderecoGroup.get('numero')?.enable();
    this.enderecoGroup.get('complemento')?.enable();

    if (!this.enderecoGroup.get('bairro')?.value) {
      this.enderecoGroup.get('bairro')?.enable();
    }

    if (!this.enderecoGroup.get('cidade')?.value) {
      this.enderecoGroup.get('cidade')?.enable();
    }

    if (!this.enderecoGroup.get('uf')?.value) {
      this.enderecoGroup.get('uf')?.enable();
    }
    this.idUsuarioMaster?.disable();
  }

  get cnpjControl() {
    return this.form.get('cnpj');
  }

  get matrizControl() {
    return this.form.get('cnpjMatriz');
  }

  get responsavelGroup() {
    return this.form.controls['responsavel'] as FormGroup;
  }

  get enderecoGroup() {
    return this.form.controls['endereco'] as FormGroup;
  }

  async getCompanyInfo() {
    if (validateCNPJ(this.cnpjControl?.value)) {
      this.loading = true;
      const validate = await lastValueFrom(
        this.companyService.validate(this.cnpjControl?.value)
      );
      this.loading = false;
      if (validate?.dados) {
        const { idMaster } = validate.dados;
        const usuarioLogado = User.getLoggedMaster(
          this.authService.getUserValue()
        );
        let message =
          usuarioLogado?.id === idMaster
            ? 'Este CNPJ já está vinculado à sua conta!'
            : 'Este CNPJ está vinculado a outra conta do CONFEAR. Por favor, verifique se o CNPJ foi digitado corretamente.';
        this.messageService.add({
          severity: 'warn',
          summary: 'Alerta',
          key: 'global-toast',
          sticky: true,
          detail: message
        });
      } else if (validate?.error) {
        this.loading = true;
        const response = await lastValueFrom(
          this.egtecService.getCompany(this.cnpjControl?.value)
        );
        this.loading = false;
        const company = response?.dados;
        this.form.enable();
        if (company !== undefined) {
          this.bindForm(company);
        }
        this.matrizControl?.disable();
        await this.searchMatriz(this.cnpjControl?.value);
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Alerta',
        key: 'global-toast',
        sticky: true,
        detail: 'CNPJ inválido!'
      });
    }
  }

  async searchMatriz(cnpj: string) {
    const response = await lastValueFrom(this.companyService.getMatriz(cnpj));

    if (response?.dados) {
      this.matrizControl?.setValue(response?.dados?.cnpj);
    }
  }

  bindForm(company) {
    const nomeResponsavel = company?.qsa?.length ? company?.qsa[0]?.nome : '';
    this.form.setValue(
      {
        ...this.form.value,
        ...{
          nomeFantasia: company.fantasia,
          razaoSocial: company.nome,
          telefoneComercial: company.telefone,
          idUsuarioMaster: null,
          endereco: {
            logradouro: company.logradouro,
            complemento: company.complemento,
            bairro: company.bairro,
            numero: company.numero,
            cidade: company.municipio,
            uf: company.uf,
            cep: company.cep
          },
          responsavel: {
            email: company.email,
            nome: nomeResponsavel,
            celular: null
          }
        }
      },
      { emitEvent: true }
    );
  }

  buildForm() {
    this.form = this.fb.group(
      {
        id: [null],
        endereco: this.fb.group({
          cep: [null, Validators.required],
          logradouro: [null, Validators.required],
          numero: [null],
          complemento: [null],
          bairro: [null, Validators.required],
          cidade: [null, Validators.required],
          uf: [null, Validators.required]
        }),
        responsavel: this.fb.group({
          nome: [null, Validators.required],
          email: [null, [Validators.required, Validators.email]],
          celular: [null, Validators.required]
        }),
        cnpj: [null, Validators.required],
        nomeFantasia: [null, Validators.required],
        cnpjMatriz: [{ value: null, disabled: true }],
        razaoSocial: [null, Validators.required],
        telefoneComercial: [null, Validators.required],
        inscricaoEstadual: [null],
        softwarePdv: [{ id: 0, descricao: '' }],
        idUsuarioMaster: [null],
        contatos: [null]
      },
      {
        validators: [this.cnpjValidation, this.phoneValidation]
      }
    );
  }

  phoneValidation: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const telefoneComercial = control?.get('telefoneComercial');
    const celular = control?.get('responsavel')?.get('celular');
    if (telefoneComercial?.value && telefoneComercial?.value.length < 10) {
      return {
        phoneError: 'O telefone está incompleto'
      };
    }

    if (celular?.value && celular?.value.length < 10) {
      return {
        phoneError: 'O telefone está incompleto'
      };
    }
    return null;
  };

  cnpjValidation: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const cnpj = control?.get('cnpj');
    if (!validateCNPJ(cnpj?.value)) {
      return {
        cnpjError: 'O CNPJ da empresa está inválido'
      };
    }

    return null;
  };

  close() {
    this.clear();
    this.responseModal.emit({ close: true });
  }

  register() {
    this.responseModal.emit({ form: this.form.getRawValue() });
  }

  async activateUsuarioMasterField() {
    if (this.loggedUser.indAdministradorSistema) {
      const response = await lastValueFrom(
        this.authService.listSistemUserByGroup('1')
      );
      this.idUsuarioMaster?.setValidators(Validators.required);
      if (response?.dados) {
        this.usuariosSistema = response?.dados;

        if (this.company) {
          const user = this.usuariosSistema.find(user => {
            return user.login === this.company?.master?.login;
          });

          if (user) {
            this.idUsuarioMaster?.setValue(user.id);
          }
        }
      }
    }
  }

  getLoggedUser() {
    this.loggedUser = this.authService.getUserValue();
  }

  get idUsuarioMaster() {
    return this.form.get('idUsuarioMaster');
  }
}
