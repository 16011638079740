import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { NfeAnalysisModule } from '../../nfe-analysis/nfe-analysis.module';
import { ModalNfeAnalysisComponent } from './modal-nfe-analysis.component';

@NgModule({
  declarations: [ModalNfeAnalysisComponent],
  exports: [ModalNfeAnalysisComponent],
  imports: [CommonModule, ModalModule, NfeAnalysisModule, LoadingFragmentModule]
})
export class ModalNfeAnalysisModule {}
