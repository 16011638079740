import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';

import { MenuTreeComponent } from '../menu-item/menu-tree.component';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent, MenuTreeComponent],
  exports: [MenuComponent, MenuTreeComponent],
  imports: [CommonModule, SidebarModule, TreeModule]
})
export class MenuModule {}
