<app-modal
  modalTitle="Visualizando Legenda"
  (closeModal)="close()"
  [display]="open"
  width="40vw">
  <div class="container-content-modal-legend">
    <div class="header-content-modal-legend">
      <app-icon
        fontSize="23px"
        class="icon-modal-legend"
        [icon]="legend?.icone"></app-icon>
      <h4 class="description-legend">{{ legend?.descricao }}</h4>
    </div>
    <div class="content-modal">{{ legend?.descricaoDetalhada }}</div>
  </div>
</app-modal>
