<p-messages severity="info" class="mensagem-aviso">
  <ng-template pTemplate>
    <i class="pi pi-calendar" style="font-size: 1.5rem"></i>
    <div class="ml-2">
      Participe de nossos treinamentos quinzenais, quinta-feira às 15:30, para
      tirar dúvidas e aprofundar o seu conhecimento no CONFEAR.
      <a
        href="https://us02web.zoom.us/webinar/register/WN_KhqgWW9AQM6KCs3Ty3rCnw"
        target="_blank">
        Inscreva-se aqui
      </a>
      !
    </div>
  </ng-template>
</p-messages>
<p-messages severity="success" class="mensagem-aviso">
  <ng-template pTemplate>
    <i class="pi pi-video" style="font-size: 1.5rem"></i>
    <div class="ml-2">
      Você já conhece a nossa central de conhecimento, com tutoriais e vídeos do
      CONFEAR?
      <a href="https://faq.egtec.com.br/confear" target="_blank">
        Clique aqui para acessar
      </a>
      !
    </div>
  </ng-template>
</p-messages>
<app-page-wrapping [pageTitle]="pageTitle">
  <section content>
    <section class="container-home">
      <dx-data-grid
        class="grid-container"
        id="gridContainer"
        [hoverStateEnabled]="true"
        [columnMinWidth]="100"
        [allowColumnResizing]="true"
        [dataSource]="dataSource"
        [remoteOperations]="false"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [showBorders]="true">
        <dxo-search-panel
          [width]="240"
          [visible]="true"
          [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-group-panel
          emptyPanelText="Arraste uma coluna para agrupar"
          [visible]="true"></dxo-group-panel>
        <dxo-toolbar>
          <dxi-item>
            <section
              *ngIf="loggedUser?.indAdministradorSistema"
              (click)="toggleSidebarFilter()"
              class="container-button-filters-grid"
              title="Abre os filtros avançados">
              <i class="pi pi-filter-slash"></i>
              <span class="select-all-rows">Filtros</span>
            </section>
          </dxi-item>
          <dxi-item name="searchPanel" location="after"></dxi-item>
          <dxi-item title="Atualiza lista" text="Refresh" [visible]="true">
            <dx-button icon="refresh" (onClick)="refreshDataGrid()"></dx-button>
          </dxi-item>
          <dxi-item name="groupPanel"></dxi-item>
        </dxo-toolbar>
        <dxi-column
          [width]="'100px'"
          filterType="exclude"
          [allowSearch]="false"
          cssClass="container-icon-status"
          alignment="center"
          caption="STATUS"
          dataType="number"
          dataField="status.descricao"
          cellTemplate="statusTemplate"></dxi-column>
        <dxi-column
          dataType="string"
          alignment="left"
          caption="EMPRESA"
          [width]="'170px'"
          dataField="nomeFantasia"
          cellTemplate="empresaTemplate"></dxi-column>
        <div
          *dxTemplate="let data of 'empresaTemplate'"
          [title]="
            'Em operação desde ' +
            (data?.data?.dataIntegracaoCertificado | date: 'dd/MM/yyyy HH:mm')
          ">
          {{ data?.value }}
        </div>
        <dxi-column
          dataType="string"
          alignment="center"
          [width]="'100px'"
          caption="FILIAL"
          dataField="filial"></dxi-column>
        <dxi-column
          [width]="'50px'"
          alignment="center"
          dataType="string"
          caption="UF"
          dataField="endereco.uf"></dxi-column>
        <dxi-column
          [minWidth]="150"
          caption="DATA ÚLTIMA VERIFICAÇÃO"
          dataType="date"
          alignment="center"
          [format]="'dd/MM/yyyy HH:mm'"
          dataField="dataUltimaVerificacao"></dxi-column>
        <dxi-column
          [minWidth]="150"
          caption="DATA EMISSÃO NFE MAIS RECENTE"
          dataType="date"
          alignment="center"
          [format]="'dd/MM/yyyy HH:mm'"
          dataField="dataEmissaoNFeMaisRecente"></dxi-column>
        <dxi-column
          caption="CERTIFICADO DIGITAL"
          [minWidth]="130"
          dataType="date"
          dataField="dataValidade"
          cellTemplate="progressBarTemplate"></dxi-column>
        <dxi-column
          alignment="center"
          dataType="number"
          [width]="'100px'"
          caption="NF-E ARMAZENADAS"
          dataField="totalNfeArmazenadas"
          cellTemplate="nfeArmazenadasTemplate"></dxi-column>
        <div
          class="text-center"
          *dxTemplate="let data of 'nfeArmazenadasTemplate'">
          {{ data.value | number }}
        </div>

        <div *dxTemplate="let data of 'statusTemplate'">
          <app-icon
            [id]="data?.data?.id"
            (mouseenter)="toggleWithTemplate(data?.data)"
            (mouseleave)="toggleWithTemplate(data?.data)"
            [icon]="data?.data?.status?.icone"
            [title]="data?.data?.status?.descricao"></app-icon>
          <dx-tooltip
            *ngIf="data?.data?.status.id === statusNotaNaoCaptando"
            [target]="currentTooltip?.nativeElement"
            [(visible)]="withTemplateVisible"
            [hideOnOutsideClick]="false">
            <div *dxTemplate="let data = data; of: 'content'">
              <app-content-tooltip-info
                title="ERRO APRESENTADO"
                text="A nota fiscal eletrônica não consta na base
                 de dados da SEFAZ estadual."></app-content-tooltip-info>
            </div>
          </dx-tooltip>
        </div>
        <div *dxTemplate="let data of 'progressBarTemplate'">
          <div class="container-progressbar">
            <dx-progress-bar
              [class.progressbar-blue]="
                data?.data.progress >= 0 && data?.data.progress < 30
              "
              [class.progressbar-green]="
                data?.data.progress >= 30 && data?.data.progress < 60
              "
              [class.progressbar-yellow]="
                data?.data.progress >= 60 && data?.data.progress < 91
              "
              [statusFormat]="formatBarLabel"
              [class.progressbar-red]="data?.data.progress >= 91"
              [class.complete]="data?.data.progress === 100"
              [min]="0"
              [max]="100"
              [value]="data?.data.progress"></dx-progress-bar>
            <span class="label-progressbar">
              {{ formatLabelProgressbar(data) }}
            </span>
          </div>
        </div>
      </dx-data-grid>
      <div class="container-status-sefaz">
        <div class="container-title">
          <h1 class="title-sefaz">STATUS SEFAZ</h1>
          <span
            #iconSefaz
            (mouseenter)="toggleWithTemplateSefaz()"
            (mouseleave)="toggleWithTemplateSefaz()"
            class="material-icons-outlined"
            id="icon-info">
            info
          </span>
          <dx-tooltip
            [target]="iconSefaz"
            [(visible)]="toggleVisibileSefaz"
            [hideOnOutsideClick]="false">
            <div *dxTemplate="let data = data; of: 'content'">
              <app-content-tooltip-info
                text="Lista dos status da SEFAZ por cada estado com
                 o informe de seus problemas. "></app-content-tooltip-info>
            </div>
          </dx-tooltip>
        </div>
        <section class="sefaz-table">
          <div class="sefaz-table-header">
            <div class="item-header">UF</div>
            <div class="item-header">STATUS</div>
          </div>
          <div class="sefaz-table-body">
            <div class="row" *ngFor="let item of statuSefazList">
              <span class="item-body">{{ item.uf }}</span>
              <div class="item-body-status">
                <span appStatusSefaz [status]="item.idStatus">
                  {{ item.status }}
                </span>
                <span
                  *ngIf="item.idStatus !== statusSefazOtimo"
                  class="status-hour-delay">
                  Atraso de até
                  {{ item.mediaHoras | time }}
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </section>
</app-page-wrapping>
<app-sidebar
  *ngIf="loggedUser?.indAdministradorSistema"
  (closeSidebar)="listenSidebar($event)"
  [titleSidebar]="'Filtro Avançado'"
  [visible]="filterVisible">
  <app-home-filters
    (filters)="listenFilterValues($event)"
    content></app-home-filters>

  <div class="col-12 flex justify-content-start p-1" footer>
    <button
      (click)="send()"
      pButton
      id="btn-pesquisar"
      label="Pesquisar"
      class="btn-dark-blue ml-2 mr-2"
      type="button"
      title="Pesquisar"
      icon="pi pi-search"
      iconPos="left"></button>
    <button
      (click)="reset()"
      pButton
      id="btn-limpar"
      class="btn-dark-purple mr-2"
      type="button"
      title="Limpar Filtros"
      icon="pi pi-filter-slash"
      iconPos="left"></button>
  </div>
</app-sidebar>

<app-modal-user-register-update
  [display]="openModalUpdateUserRegister"></app-modal-user-register-update>
