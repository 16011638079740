<app-modal (closeModal)="close()" [display]="open">
  <section header>
    Justificativa de Manifestação da NF-e
    <span class="nfe-key-header-modal">{{ nfe?.numeroNfe }}</span>
  </section>
  <app-loading-fragment
    height="340px"
    [loading]="loading"></app-loading-fragment>
  <ng-container *ngIf="!errorMessage">
    <section class="content-icon-message">
      <div class="content">
        <span class="material-icons-outlined icon">error</span>
        <span>{{ justificativaManifestacao }}</span>
      </div>
    </section>
  </ng-container>
  <div *ngIf="errorMessage">
    <span>{{ errorMessage }}</span>
  </div>
</app-modal>
