import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, catchError, throwError } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { LoadingService } from '../../services/loading/loading.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  closingSession: boolean;

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        this.showErrors(err.status, err?.error?.mensagem);

        return throwError(err);
      })
    );
  }

  showErrors(statusCode: number, message: string[]) {
    let severity: string;
    let summary: string;

    const detail = message?.reduce((acc: string, cc: string) => {
      return acc.concat(cc);
    });

    this.loadingService.close();

    switch (statusCode) {
      case 422:
        severity = 'warn';
        summary = 'Alerta';
        this.messageService.add({
          severity,
          summary,
          key: 'global-toast',
          sticky: true,
          detail
        });
        break;
      case 401:
        /* NÃO DEVE EXIBIR ALERTA, SISTEMA IRÁ TRATAR O RETORNO*/
        break;
      case 409:
        /* NÃO DEVE EXIBIR ALERTA, SISTEMA IRÁ TRATAR O RETORNO*/
        break;
      case 421:
        break;
      default:
        severity = 'error';
        summary = 'Erro';
        this.messageService.add({
          severity,
          summary,
          key: 'global-toast',
          sticky: true,
          detail
        });
        break;
    }
  }
}
