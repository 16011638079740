import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../../core/models/entities/company/company';
import { SoftwarePdv } from '../../../core/models/entities/company/softwarePdv';
import { ServidorIntegracaoService } from '../../../core/services/servidor-integracao/servidor-integracao.service';

@Component({
  selector: 'app-modal-pdv-integration-register',
  templateUrl: './modal-pdv-integration-register.component.html',
  styleUrls: ['./modal-pdv-integration-register.component.scss']
})
export class ModalPdvIntegrationRegisterComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  @Input() open: boolean;
  @Input() companies: Company[] | any = [];
  @Input() softwares: SoftwarePdv[];
  @Output() closeModal = new EventEmitter();
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private servidorIntegracaoService: ServidorIntegracaoService
  ) {}

  close(event) {
    this.closeModal.emit(event);
    this.form.reset({}, { emitEvent: false });
  }

  ngOnInit() {
    this.buildForm();
    // this.listenSoftwarePdvControl();
  }

  buildForm() {
    this.form = this.fb.group({
      softwarePdv: [null, [Validators.required]],
      servidor: [null, [Validators.required]],
      empresas: [[], [Validators.required]]
    });
  }

  get empresas() {
    return this.form.get('empresas');
  }

  get servidor() {
    return this.form.get('servidor');
  }

  get softwarePdv() {
    return this.form.get('softwarePdv');
  }

  listenSoftwarePdvControl() {
    this.softwarePdv?.valueChanges.subscribe(changes => {
      this.checkIfPdvIsEqual();
    });
  }

  /*
  checkIfPdvIsEqual() {
    const { id } = this.softwarePdv?.value;
    const { empresas } = this.form?.value;
    let selectedCompanies = [];
    if (empresas?.length) {
      selectedCompanies = this.companies.filter(company => {
        return empresas.includes(company.id);
      });

      const excludeCompanies = selectedCompanies.filter(
        (company: Company) => company?.softwarePdv?.id !== id
      );

      const excludeCompaniesId = excludeCompanies.map(
        (company: Company) => company.id
      );

      if (excludeCompaniesId.length) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          key: 'global-toast',
          sticky: true,
          detail:
            'O software PDV da(s) empresa(s) selecionada(s) é diferente do selecionado para o servidor. ' +
            'Apenas empresas com o mesmo software PDV selecionado devem ser vinculadas a este servidor.'
        });

        setTimeout(() => {
          this.dataGrid.instance.deselectRows(excludeCompaniesId);
        }, 1000);

        const allowCompanies = empresas.filter(
          company => !excludeCompaniesId.includes(company.id)
        );

        this.empresas?.setValue(allowCompanies);
      }
    }
  }
*/

  checkIfPdvIsEqual() {
    const { id } = this.softwarePdv?.value;
    const { empresas } = this.form?.value;
    let selectedCompanies = [];
    if (empresas?.length) {
      selectedCompanies = this.companies.filter(company => {
        return empresas.includes(company.id);
      });

      const hasDiff = selectedCompanies.some(
        (company: Company) => company?.softwarePdv?.id !== id
      );

      if (hasDiff) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          key: 'global-toast',
          sticky: true,
          detail:
            'O software PDV da(s) empresa(s) selecionada(s) é diferente do selecionado para o servidor. ' +
            'Apenas empresas com o mesmo software PDV selecionado devem ser vinculadas a este servidor.'
        });
        return false;
      }
      return true;
    }
  }

  listenSelectedRows(event: any) {
    if (event.name === 'selectedRowKeys') {
      if (event.value !== this.empresas?.value) {
        this.empresas?.setValue(event.value);
      }
    }
  }

  async register() {
    const request = {
      idSoftwarePDV: this.softwarePdv?.value?.id,
      descricaoSoftwarePDV: this.softwarePdv?.value?.descricao,
      descricaoServidor: this.servidor?.value,
      empresas: this.empresas?.value
    };
    if (this.checkIfPdvIsEqual()) {
      const response = await lastValueFrom(
        this.servidorIntegracaoService.register(request)
      );
      if (response?.mensagem) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          key: 'global-toast',
          sticky: true,
          detail: response?.mensagem
        });
      }
      this.close({ register: true });
    }
  }
}
