import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';

import { environment } from '../../environments/environment';
import { Setor } from '../models/entities/setor';
import { DefaultResponse } from '../models/responses/default-response';
import { handleResponseError } from '../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class SetorService {
  private url = `${environment.baseUrl}/setores`;

  constructor(private httpClient: HttpClient) {}

  list(): Observable<DefaultResponse<Setor>> {
    return this.httpClient.get<DefaultResponse<Setor>>(`${this.url}`).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
  }
}
