<div class="container-page">
  <app-page-wrapping
    pageTitle="Gerenciamento de Integração com PDV"
    [height]="'auto'">
    <section buttons>
      <div class="flex mb-2">
        <button
          (click)="openModalRegister()"
          [disabled]="disableCreateServer(data)"
          pButton
          title="Incluir novo servidor"
          class="btn-dark-blue mr-2"
          type="button"
          label="Incluir novo servidor ">
          <span class="material-icons-two-tone mr-2">reset_tv</span>
        </button>
        <div class="container-info border-blue font-bold mr-2">
          <span class="company-total mr-1">TOTAL DE EMPRESAS:</span>
          <span class="company-total-count">{{ data?.totais?.empresas }}</span>
        </div>
        <div class="container-info font-bold mr-2">
          <span class="company-attach mr-1">EMPRESAS VINCULADAS:</span>
          <span class="company-attach-count">
            {{ getEmpresasVinculadas(data) }}
          </span>
        </div>
      </div>
    </section>
    <section content>
      <section class="container-home">
        <dx-data-grid
          class="grid-container"
          id="gridContainer"
          [hoverStateEnabled]="true"
          [columnMinWidth]="50"
          [allowColumnResizing]="true"
          [dataSource]="dataSourceServer"
          [remoteOperations]="false"
          [allowColumnReordering]="true"
          [rowAlternationEnabled]="true"
          [showBorders]="true">
          <dxo-search-panel
            [width]="240"
            [visible]="true"
            [highlightCaseSensitive]="true"></dxo-search-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-group-panel
            emptyPanelText="Arraste uma coluna para agrupar"
            [visible]="true"></dxo-group-panel>
          <dxo-toolbar>
            <dxi-item name="searchPanel" location="after"></dxi-item>
            <dxi-item name="groupPanel"></dxi-item>
          </dxo-toolbar>

          <dxi-column
            [allowSearch]="false"
            [allowFiltering]="false"
            [showInColumnChooser]="false"
            [width]="60"
            alignment="left"
            caption="STATUS"
            cellTemplate="statusTemplate"
            dataField="status"
            dataType="number"
            [fixed]="true">
            <dxo-header-filter [dataSource]="statusFilter"></dxo-header-filter>
          </dxi-column>
          <div *dxTemplate="let row of 'statusTemplate'">
            <app-icon
              [id]="row?.data?.id"
              fontSize="20px"
              [icon]="row?.data?.status?.icone"
              [title]="row?.data?.status?.descricao"></app-icon>
          </div>
          <dxi-column
            dataType="string"
            alignment="left"
            caption="SERVIDOR"
            [minWidth]="130"
            dataField="nome"></dxi-column>
          <dxi-column
            [width]="100"
            dataType="string"
            alignment="center"
            caption="ID INST."
            dataField="id"></dxi-column>
          <dxi-column
            dataType="string"
            alignment="center"
            caption="SOFT. DE PDV"
            dataField="reponsavel"
            cellTemplate="softwarePdvTemplate"></dxi-column>
          <div *dxTemplate="let row of 'softwarePdvTemplate'">
            {{ row?.data?.softwarePdv?.descricao }}
          </div>
          <dxi-column
            [width]="80"
            dataType="string"
            alignment="left"
            caption="CHAVE"
            dataField="chaveDownload"></dxi-column>
          <dxi-column
            dataType="string"
            alignment="left"
            caption="INSTALAÇÃO/ATUALIZAÇÃO"
            dataField="plugin"
            cellTemplate="pluginTemplate"></dxi-column>
          <div *dxTemplate="let row of 'pluginTemplate'">
            <div [class.text-red]="row?.data?.plugin?.indVersaoDeatualizada">
              <span class="font-bold">
                {{
                  row?.data?.plugin?.dataUltimaCaptacao === null
                    ? 'Não Instalado'
                    : row?.data?.plugin?.versaoAtual + ' '
                }}
              </span>
              {{ row?.data?.plugin?.dataAtualizacao | date: 'dd/MM/YYYY' }}
            </div>
            <div>{{ row?.data?.plugin?.dataUltimaCaptacao | daysAgo }}</div>
          </div>
          <dxi-column
            [width]="100"
            dataType="string"
            alignment="center"
            caption="EMPRESAS"
            dataField="empresas"
            cellTemplate="empresasTemplate"></dxi-column>
          <div *dxTemplate="let row of 'empresasTemplate'">
            <div>
              {{ row?.data?.empresas?.length }}
            </div>
          </div>
          <dxi-column
            [width]="150"
            dataType="string"
            alignment="center"
            caption="Diretório Integração"
            dataField="diretorioIntegracao"></dxi-column>
          <dxi-column
            [width]="150"
            dataType="date"
            [format]="'dd/MM/yyyy HH:mm'"
            alignment="center"
            caption="Data Última Integração"
            dataField="dataUltimaIntegracao"></dxi-column>

          <dxi-column
            [width]="120"
            dataType="string"
            alignment="center"
            caption="Tot. Notas Última Int."
            dataField="totalNotasUltimaIntegracao"></dxi-column>

          <dxi-column
            [width]="100"
            dataType="string"
            alignment="center"
            caption="INSTALADOR"
            cellTemplate="instaladorTemplate"></dxi-column>
          <div *dxTemplate="let cell of 'instaladorTemplate'">
            <button
              (click)="download(cell)"
              pButton
              class="btn-green"
              title="Clique para baixar o instalador do plugin"
              icon="pi pi-download"
              label="Download"></button>
          </div>
          <dxi-column
            [width]="80"
            dataType="string"
            alignment="center"
            caption="EMPRESAS"
            cellTemplate="vincularTemplate"></dxi-column>
          <div *dxTemplate="let row of 'vincularTemplate'">
            <button
              (click)="openModalAttach(row?.data)"
              pButton
              class="btn-orange"
              title="Clique para gerenciar as empresas vinculadas a esse servidor"
              icon="pi pi-building"
              label=""></button>
          </div>
          <dxi-column
            [width]="60"
            dataType="string"
            alignment="center"
            caption="AÇÃO"
            cellTemplate="actionsTemplate"></dxi-column>
          <div *dxTemplate="let row of 'actionsTemplate'">
            <button
              (click)="openModalDelete(row?.data)"
              pButton
              title="Clique para excluir o servidor"
              class="btn-delete"
              icon="pi pi-trash"
              label=""></button>
          </div>

          <dxo-master-detail
            [enabled]="true"
            template="detail"></dxo-master-detail>
          <div *dxTemplate="let row of 'detail'">
            <app-pdv-integration-grid-detail
              [dataSource]="
                row.data?.empresas
              "></app-pdv-integration-grid-detail>
          </div>
        </dx-data-grid>
      </section>
    </section>
  </app-page-wrapping>
  <h3 class="mt-4">Empresas não vinculadas</h3>
  <section class="default-border-page">
    <app-grid-pdv-companies
      mode="single"
      [companies]="dataSourceCompanies"></app-grid-pdv-companies>
  </section>
</div>

<app-modal-pdv-integration-register
  [companies]="data?.empresasNaoVinculadas"
  [softwares]="softwaresPdv"
  (closeModal)="listenModalRegister($event)"
  [open]="showModalRegister"></app-modal-pdv-integration-register>

<app-modal-pdv-delete
  [server]="selectedServer"
  [open]="showModalDelete"
  (closeModal)="listenModalDelete($event)"></app-modal-pdv-delete>

<app-modal-pdv-attach-company
  [open]="showModalAttach"
  [server]="selectedServer"
  (closeModal)="listenModalAttach($event)"
  [empresasNaoVinculadas]="
    data?.empresasNaoVinculadas
  "></app-modal-pdv-attach-company>
