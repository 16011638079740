<app-modal
  [blockScroll]="true"
  [hasLineHeader]="true"
  [width]="'90vw'"
  (closeModal)="close()"
  [display]="open">
  <section header>
    Visualizar Carta de Correção (CC-e) NF-e {{ nfe?.numeroNfe }}
  </section>
  <app-loading-fragment [loading]="loading"></app-loading-fragment>

  <ng-container *ngIf="!errorMessage">
    <section class="container-cc-info">
      <div class="item-info">
        <b class="mr-1">Chave de Acesso:</b>
        {{ cartasCorrecao?.chaveNfe }}
      </div>
      <div class="item-info">
        <b class="mr-1">Destinatário:</b>
        {{ cartasCorrecao?.destinatario }}
      </div>
      <div class="item-info">
        <b class="mr-1">Fornecedor:</b>
        {{ cartasCorrecao?.fornecedor }} - CNPJ:
        {{ cartasCorrecao.cnpjFornecedor | mask: '00.000.000/0000-00' }}
      </div>
      <div class="item-info">
        <b class="mr-1">Data Emissão:</b>
        {{ cartasCorrecao?.dataEmissao }}
      </div>
    </section>
    <section class="main-content">
      <div class="container-flex mr-2">
        <h4 class="font-bold mt-4 mb-2">
          Selecione abaixo uma Carta de Correção
        </h4>
        <div id="cc-item-container">
          <div
            [class.selected]="selectedCartaCorrecao === cce"
            class="cc-item"
            (click)="selectCce(cce)"
            *ngFor="let cce of cartasCorrecao?.cces; index as i">
            <span class="index">{{ getIndex(i) }} -</span>
            {{ cce?.dataEvento }}
          </div>
        </div>
      </div>
      <div class="container-flex">
        <h4 class="font-bold mt-4 mb-2">Texto de Correção</h4>
        <div id="cc-text-container">
          <div class="cc-text">{{ selectedCartaCorrecao?.textoCorrecao }}</div>
        </div>
      </div>
    </section>
  </ng-container>
  <div *ngIf="errorMessage">
    <span>{{ errorMessage }}</span>
  </div>
</app-modal>
