<section class="container-welcome-setup">
  <section class="container-top">
    <img
      class="confear-logo"
      src="assets/logos/logo-confear-branco.png"
      alt="" />
    <section class="container-top-links">
      <div class="top-link mr-2">
        <span class="material-icons-outlined">live_tv</span>
        VÍDEO TUTORIAL
      </div>
      <div class="top-link mr-2">
        <span class="material-icons-outlined">help</span>
        PRECISA DE AJUDA?
      </div>
    </section>
  </section>
  <section class="main-content-setup">
    <div class="content-info">
      <h3 class="title">SEJA BEM-VINDO AO CONFEAR</h3>
      Parabéns! Você deu um passo importante para gestão das suas empresas.
      <br />
      <br />
      Agora vamos guiá-lo para realizar a configuração do seu ambiente aqui no
      CONFEAR. Serão apenas quatro etapas, conforme informado abaixo.
    </div>
    <img class="img-business" src="assets/setup-business.png" alt="setup" />
    <section class="container-steps">
      <div class="flex flex-wrap">
        <div *ngFor="let step of stepBoxes" class="step-box mr-2">
          <div class="top">
            <span class="material-icons-outlined">{{ step.icon }}</span>
          </div>
          <div class="bottom">{{ step.label }}</div>
        </div>
      </div>

      <div class="btn-next" (click)="start()">
        <p class="mr-2">COMEÇAR</p>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
    </section>
  </section>
</section>
