import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'core/models/entities/user/user';
import { AuthService } from 'core/services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom } from 'rxjs';

import { CertificateService } from '../../../core/services/certificate/certificate.service';

@Component({
  selector: 'app-modal-validate-certificate',
  templateUrl: './modal-validate-certificate.component.html',
  styleUrls: ['./modal-validate-certificate.component.scss']
})
export class ModalValidateCertificateComponent implements OnInit {
  @ViewChild(FileUpload, { static: false }) uploadFile: FileUpload;
  @Input() open: boolean;
  @Input() company: any;
  @Output() closeModal = new EventEmitter();
  form: FormGroup;
  loggedUser: User;
  showModalCertificateContract: boolean;

  constructor(
    private fb: FormBuilder,
    private certificateService: CertificateService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getLoggedUser();
    this.buildForm();
  }

  close() {
    this.closeModal.emit({ close: true });
    this.clear();
  }

  clear() {
    this.uploadFile.clear();
    this.form.reset();
  }

  buildForm() {
    this.form = this.fb.group({
      aceiteTermo: [null, null],
      infoAceiteTermo: [null, null],
      senha: [null, [Validators.required]],
      arquivo: [null, [Validators.required]]
    });
  }

  get arquivoControl() {
    return this.form.get('arquivo');
  }

  onUploaded(event: any) {
    this.arquivoControl?.setValue(event.currentFiles[0]);
  }

  async validate() {
    const termoAceito = this.getAceiteTermo();
    if (termoAceito == false || termoAceito == undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        key: 'global-toast',
        sticky: true,
        detail:
          'É necessário concordar com o termo de adesão antes de prosseguir.'
      });
    } else {
      const request = {
        ...this.form.value,
        idEmpresa: this.company.id,
        indAceiteTermo: this.getAceiteTermo()
      };
      const response = await lastValueFrom(
        this.certificateService.validate(request)
      );
      if (response?.mensagem) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          key: 'global-toast',
          sticky: true,
          detail: response.mensagem
        });
        this.clear();
        this.closeModal.emit({ update: true });
      }
    }
  }
  getLoggedUser() {
    this.loggedUser = this.authService.getUserValue();
  }

  getAceiteTermo() {
    if (this.company?.idUsuarioAceiteTermo == 0) {
      return this.form?.get('aceiteTermo')?.value;
    } else {
      return true;
    }
  }

  loadContrato(event: Event) {
    event.preventDefault();
    this.showModalCertificateContract = true;
  }
  listenModalContract() {
    this.showModalCertificateContract = false;
  }
}
