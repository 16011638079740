<section class="container">
  <div class="flex flex-column align-items-center">
    <img id="img" src="assets/image/user-register-update.png" alt="" />
    <div>
      <span class="font-bold">Login atual:</span>
      <span class="color-blue ml-1">{{ currentLogin }}</span>
    </div>
  </div>
  <div class="container-title">
    <h1 class="title">Bem-vindo ao CONFEAR WEB</h1>
    <span class="subtitle">
      Precisamos atualizar algumas informações para acesso ao novo sistema
    </span>
  </div>
</section>
