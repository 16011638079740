<section class="container-breadcrumb-setup">
  <div
    (click)="previous()"
    class="btn-previous"
    [class.p-hidden-space]="current?.isFirst">
    <span class="material-icons-outlined mr-2 circle-left">navigate_next</span>
    <p>Anterior</p>
  </div>
  <div class="container-steps">
    <div
      [class.step-visited]="step?.isVisited"
      [class.step-current]="step?.isCurrent"
      *ngFor="let step of steps"
      class="step">
      <span class="material-icons-outlined mr-1">{{ step.icon }}</span>
      {{ step.label }}
    </div>
  </div>
  <div (click)="next()" class="btn-next">
    <p class="mr-2">{{ current?.isLast ? 'Concluir' : 'Próximo' }}</p>
    <span *ngIf="!current?.isLast" class="material-icons-outlined">
      navigate_next
    </span>
  </div>
</section>
