import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { Nfe } from '../../../core/models/entities/nfe/nfe';
import { ModalService } from '../../../core/services/modal/modal.service';

@Component({
  selector: 'app-modal-nfe-analysis',
  templateUrl: './modal-nfe-analysis.component.html',
  styleUrls: ['./modal-nfe-analysis.component.scss']
})
export class ModalNfeAnalysisComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfe: Nfe | undefined;
  subscription: Subscription[] = [];
  messageError: string;
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.ANALISAR_NFE && res.open) {
        this.resetField(res.data);
        this.loading = true;
        this.open = true;
      }
    });

    this.subscription.push(subscription);
  }

  resetField(data) {
    this.nfe = undefined;
    setTimeout(() => {
      this.nfe = data;
    }, 1000);
  }

  close() {
    this.loading = false;
    this.open = false;
    this.messageError = '';
  }

  listenNfeLoading(data) {
    this.messageError = '';
    this.loading = false;
    if (data?.error) {
      this.messageError = data?.error;
      this.open = false;
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
