import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { SetorService } from '../../../../../core/services/setor.service';
import { StepService } from '../../../../../core/services/step/step.service';

@Component({
  selector: 'app-form-data-update',
  templateUrl: './form-data-update.component.html',
  styleUrls: ['./form-data-update.component.scss']
})
export class FormDataUpdateComponent implements OnInit {
  form = this.fb.group({
    login: [null, [Validators.required]],
    apelido: [null],
    setor: [null],
    celular: [
      null,
      [Validators.required, Validators.maxLength(11), Validators.minLength(11)]
    ]
  });
  setores = [];

  constructor(
    private stepService: StepService,
    private fb: FormBuilder,
    private setorService: SetorService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  get login() {
    return this.form.get('login');
  }

  ngOnInit(): void {
    const step = this.stepService.find(1);
    if (step) {
      this.form.patchValue({ login: step?.data?.email });
      this.login?.disable();
    }
    this.getSetores();
  }

  getSetores() {
    this.setorService.list().subscribe(response => {
      this.setores = response?.dados;
    });
  }

  async next() {
    if (this.form.valid) {
      const response = await lastValueFrom(
        this.authService.updateUserRegister(this.form.getRawValue())
      );

      if (response.mensagem) {
        this.messageService.add({
          key: 'global-toast',
          severity: 'success',
          summary: 'Sucesso',
          detail: response.mensagem,
          life: 4000
        });
        this.stepService.next();
      }
    }
  }
}
