import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { Servidor } from '../../../core/models/entities/servidor-integracao/servidor';
import { ServidorIntegracaoService } from '../../../core/services/servidor-integracao/servidor-integracao.service';

@Component({
  selector: 'app-modal-pdv-delete',
  templateUrl: './modal-pdv-delete.component.html',
  styleUrls: ['./modal-pdv-delete.component.scss']
})
export class ModalPdvDeleteComponent {
  @Input() server: Servidor;
  @Input() open: boolean;
  @Output() closeModal = new EventEmitter();
  confirmMessage: string;

  constructor(
    private servidorIntegracao: ServidorIntegracaoService,
    private messageService: MessageService
  ) {}

  close(event) {
    this.closeModal.emit(event);
    this.confirmMessage = '';
  }

  validation() {
    return this.confirmMessage?.toUpperCase() !== 'CONFIRMAR';
  }

  async confirm() {
    const response = await lastValueFrom(
      this.servidorIntegracao.delete(this.server.id)
    );

    if (response?.mensagem) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        key: 'global-toast',
        sticky: true,
        detail: response?.mensagem
      });
      this.close({ delete: true });
    }
  }
}
