import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { BaseGridComponent } from '../../core/components/base-grid/base-grid';
import { CONFEAR_PERMISSIONS } from '../../core/consts/CONFEAR-PERMISSIONS';
import { AnaliseNfe } from '../../core/models/entities/nfe-analise/analise-nfe';
import { DefaultResponse } from '../../core/models/responses/default-response';
import { AuthService } from '../../core/services/auth/auth.service';
import { LoadingService } from '../../core/services/loading/loading.service';
import { ModalService } from '../../core/services/modal/modal.service';
import { NfeService } from '../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-nfe-analysis',
  templateUrl: './nfe-analysis.component.html',
  styleUrls: ['./nfe-analysis.component.scss']
})
export class NfeAnalysisComponent
  extends BaseGridComponent<any>
  implements OnChanges, OnInit
{
  @Input() pageTitle: string;
  @Input() nfe;
  @Output() nfeLoaded = new EventEmitter<any>();
  @Input() getData: boolean;
  override data: DefaultResponse<AnaliseNfe>;
  errorMessage: string;
  currentTooltip: any;
  showToggleCustoReal: boolean;
  showModalCostLegend: boolean;

  constructor(
    protected override nfeService: NfeService,
    protected override authService: AuthService,
    protected override modalService: ModalService,
    protected override messageService: MessageService,
    protected override loadingService: LoadingService
  ) {
    super(
      nfeService,
      authService,
      modalService,
      messageService,
      loadingService
    );
  }

  async ngOnInit() {
    this.gridPermission = CONFEAR_PERMISSIONS.ANALISAR_NFE;
    await this.loadInitalConfigs();
  }

  ngOnChanges() {
    if (this.nfe) {
      this.errorMessage = '';
      this.getNfe();
    }
  }

  async getNfe() {
    //TODO refatorar essa linha
    const chave = this.nfe?.chaveNfe ? this.nfe?.chaveNfe : this.nfe.chave;

    const response = await lastValueFrom(this.nfeService.nfeAnalysis(chave));

    if (response?.dados) {
      let count = 0;
      this.dataSource = response.dados?.produtos.map(row => {
        return { ...row, idGrid: count++ };
      });
      this.data = response;
      this.nfeLoaded.emit({ success: true });
    }

    if (response?.error?.mensagem) {
      let error = response?.error?.mensagem.reduce((acc, cc) => {
        error.concat(cc);
      });
      this.nfeLoaded.emit({ error: error });
    }
  }

  getClassCoastAlert() {
    if (this.data?.dados?.totalizadores) {
      const { custoTotalValorMedioNota } = this.data?.dados?.totalizadores;

      if (custoTotalValorMedioNota < 0) {
        return 'maior';
      } else if (custoTotalValorMedioNota > 0) {
        return 'menor';
      }

      return 'igual';
    }
  }

  getCoastAlertMessage() {
    if (this.data?.dados?.totalizadores) {
      const { custoTotalValorMedioNota } = this.data?.dados?.totalizadores;

      let value = Math.abs(custoTotalValorMedioNota);
      const result = Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
      if (custoTotalValorMedioNota < 0) {
        return `maior ${result} contra`;
      } else if (custoTotalValorMedioNota > 0) {
        return `menor ${result} contra`;
      }

      return 'igual';
    }
  }

  override refreshDataGrid() {
    this.getNfe();
  }

  openProductModal(row) {
    this.modalService.open({
      id: CONFEAR_PERMISSIONS.TODOS,
      name: 'PRODUCT MODAL',
      open: true,
      data: {
        product: row.data,
        nfe: this.nfe
      }
    });
  }

  toggleCustoReal(row) {
    if (row?.data?.valorMedio) {
      this.currentTooltip = row?.data?.idGrid;
      this.showToggleCustoReal = !this.showToggleCustoReal;
    }
  }

  openModalCostLegend() {
    this.showModalCostLegend = true;
  }

  closeModalCostLegend() {
    this.showModalCostLegend = false;
  }

  percentFormat(cell) {
    return (cell?.value * 100).toFixed(2).toString().replace('.', ',');
  }
}
