import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalSelectMasterComponent } from './modal-select-master.component';

@NgModule({
  declarations: [ModalSelectMasterComponent],
  exports: [ModalSelectMasterComponent],
  imports: [
    CommonModule,
    ModalModule,
    DropdownModule,
    ButtonModule,
    FormsModule
  ]
})
export class ModalSelectMasterModule {}
