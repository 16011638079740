import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { FinishedUserUpdateComponent } from './finished-user-update/finished-user-update.component';
import { FormCodeValidationComponent } from './forms/form-code-validation/form-code-validation.component';
import { FormDataUpdateComponent } from './forms/form-data-update/form-data-update.component';
import { FormEmailValidationComponent } from './forms/form-email-validation/form-email-validation.component';
import { HeadModalUserRegisterUpdateComponent } from './head-modal-user-register-update/head-modal-user-register-update.component';
import { ModalUserRegisterUpdateComponent } from './modal-user-register-update.component';
import { StepsComponent } from './steps/steps.component';

@NgModule({
  declarations: [
    ModalUserRegisterUpdateComponent,
    HeadModalUserRegisterUpdateComponent,
    FormEmailValidationComponent,
    FormCodeValidationComponent,
    FormDataUpdateComponent,
    FinishedUserUpdateComponent,
    StepsComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    InputMaskModule
  ],
  exports: [
    ModalUserRegisterUpdateComponent,
    HeadModalUserRegisterUpdateComponent
  ]
})
export class ModalUserRegisterUpdateModule {}
