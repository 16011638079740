import { ChangePasswordComponent } from '../components/change-password/change-password.component';

export const mockStep = {
  id: 0,
  icon: '',
  label: '',
  url: '',
  isCurrent: false,
  isLast: false,
  isFirst: false,
  isVisited: false,
  isRequired: false,
  next: false,
  order: 1,
  component: ChangePasswordComponent,
  previous: false
};
