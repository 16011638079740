<app-modal
  modalTitle="Confirmação de exclusão"
  width="55vw"
  (closeModal)="close({ close: true })"
  [closable]="false"
  [display]="open">
  <section class="container-modal-pdv-delete mt-3">
    <div class="content-modal-pdv-delete">
      <span class="pi pi-trash mb-3"></span>
      <div class="ask-delete mb-3">
        Você realmente deseja excluir este servidor?
      </div>
      <div class="server-info font-bold mb-3">
        <span>Nome do servidor: {{ server?.nome }}</span>
        <span>Chave: {{ server?.chaveDownload }}</span>
        <span>Software de PDV: {{ server?.softwarePdv?.descricao }}</span>
      </div>
      <div class="warning-info mb-3">
        <span class="font-bold">Atenção para os impactos desta ação!</span>
        <span>- Paralisação da integração com o software de PDV/ERP ;</span>
        <span>- Exclusão de todos as informações de nossa base de dados.</span>
      </div>
      <div class="container-textarea mb-1">
        <input [(ngModel)]="confirmMessage" class="uppercase" pInputText />
      </div>
      <div class="text-info mb-3">
        Escreva a palavra “CONFIRMAR” no campo acima.
      </div>
      <section class="container-buttons">
        <button
          (click)="confirm()"
          [disabled]="validation()"
          pButton
          label="Sim"
          class="btn-confirm mr-2"
          icon="pi pi-check"></button>
        <button
          type="button"
          (click)="close({ close: true })"
          pButton
          label="Não"
          class="btn-cancel"
          icon="pi pi-times"></button>
      </section>
    </div>
  </section>
</app-modal>
