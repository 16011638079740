<app-modal
  modalTitle="Autorização de uso do Certificado Digital"
  width="50vw"
  [removeTitleMargin]="false"
  (closeModal)="close()"
  [display]="open">
  <p-scrollPanel
    [style]="{ width: '100%', height: '200px' }"
    styleClass="custombar1">
    <p>TERMOS DE ADESÃO E COMPROMISSO</p>
    <p>SOFTWARE CONFEAR</p>

    <p>
      <strong>ESTRUTURAL GESTÃO E TECNOLOGIA LTDA</strong>
      , pessoa jurídica de direito privado, inscrita sob o CNPJ nº
      05.421.883/0001-27, com sede e escritório administrativo situado na Rua
      Dona Nina, nº 148, lote 38, Porto da Roça, Saquarema/RJ, neste ato
      representada na forma de seu Contrato Social, doravante denominado
      simplesmente
      <strong>EGTEC</strong>
      ; e
      <strong>USUÁRIO DO SOFTWARE CONFEAR</strong>
      , pessoa física ou jurídica, identificada em acordo com a confirmação de
      cadastro, doravante denominado simplesmente
      <strong>CLIENTE</strong>
      .
    </p>

    <p>Em conjunto denominadas partes, considerando que:</p>

    <ul>
      <li>
        a) a EGTEC é proprietária do software denominado “CONFEAR” - captação
        online de nota fiscal eletrônica e armazenamento (SOFTWARE CONFEAR);
      </li>
      <li>
        b) a EGTEC reserva o direito de fazer uso do certificado digital do
        CLIENTE exclusivamente para exercer as funções da rotina de Nota Fiscal
        Eletrônica (NF-e) junto aos órgãos governamentais responsáveis por
        emissão de NF-e;
      </li>
      <li>
        c) o SOFTWARE CONFEAR possui as seguintes funções e descrições técnicas:
        captação automática, realizada diretamente por meio dos órgãos
        governamentais responsáveis por emissão de NF-e, de arquivos em formato
        .xml de NF-e emitidas em face dos CNPJ’s cadastrados; armazenamento das
        NF-e captadas em data center próprio, referentes aos cinco últimos
        exercícios fiscais; manifestação do destinatário sobre as operações
        cobertas pelas NF-e captadas; consulta e impressão das NF-e captadas;
        execução das rotinas operacionais sobre as NF-e captadas; acesso direto
        da contabilidade às NF-e captadas; configuração de alertas para atender
        fatos específicos das rotinas de NF-e; análise da NF-e com relação a
        itens como desconto, custo unitário real e impostos; sistema
        multiusuário, 100% (cem por cento) configurado pelo CLIENTE; sistema
        multi-empresas, 100% (cem por cento) configurado pelo CLIENTE;
      </li>
    </ul>

    <h3>1. Aceitação das Condições de Uso</h3>

    <p>
      1.1. O presente Termo de Adesão e Compromisso tem por finalidade
      normatizar o uso do serviço oferecido pela EGTEC. Ao usar o SOFTWARE
      CONFEAR, o CLIENTE está ciente de que estará sujeito aos manuais e regras
      aplicáveis à este. Ao enviar seus dados e informações cadastrais, o
      CLIENTE assume que leu e concordou com a versão mais recente do Termo de
      Adesão e Compromisso e se vincula, automática e irrevogavelmente, às
      regras nele contidas.
    </p>
    <p>
      1.2. O serviço oferecido poderá envolver conexão remota para acesso ao
      equipamento do CLIENTE, seja para acesso ao certificado digital A3 e/ou
      realizar downloads das NF-e ao equipamento, bem como utilizar espaço em
      Disco Rígido (HD) interno.
    </p>
    <p>
      1.3. O CLIENTE compromete-se a manter todos os dados cadastrais
      atualizados e responsabiliza-se com a veracidade, a qualquer tempo, das
      informações prestadas.
    </p>
    <p>
      1.4. O CLIENTE expressamente declara e garante, para todos os fins de
      direito, que possui capacidade jurídica para celebrar este Contrato.
    </p>

    <h3>2. Descrição do serviço</h3>

    <p>
      2.1. O SOFTWARE CONFEAR fornece as ferramentas de armazenamento e
      organização necessárias para que o CLIENTE mantenha controle sobre as NF-e
      que forem emitidas em face do(s) CNPJ(s) cadastrado(s) pelo CLIENTE,
      conforme seu interesse.
    </p>
    <p>
      2.2. Ao CLIENTE é facultado cadastrar outros CNPJ’s e/ou usuários conforme
      o seu interesse, responsabilizando-se, neste caso, nos exatos termos e
      condições do presente instrumento, em relação à estes.
    </p>
    <p>
      2.3. O SOFTWARE CONFEAR tem como função principal coletar e armazenar
      NF-e, dos últimos 5 (cinco) exercícios fiscais, disponibilizadas pelos
      órgãos governamentais responsáveis pela emissão das NF-e, seja em âmbito
      estadual ou nacional.
    </p>

    <h3>3. Senha e segurança</h3>

    <p>
      3.1. Todo CLIENTE e usuário que utilizar o serviço é responsável pela
      guarda segura e pela confidencialidade da sua senha, além de ser
      inteiramente responsável por toda e qualquer atividade, lançamento e
      registro de informações que ocorram sob o uso da mesma, inclusive para
      efeitos legais.
    </p>
    <p>
      3.2. O CLIENTE compromete-se e responsabiliza-se em realizar o bloqueio
      temporário de login e senha, no site do SOFTWARE CONFEAR, diante qualquer
      uso não autorizado da sua senha ou qualquer quebra de segurança de que
      tome conhecimento, imediatamente após sua ciência.
    </p>
    <p>
      3.3. A EGTEC não será responsável por qualquer perda que possa ocorrer
      como consequência do uso não autorizado por terceiros da senha do CLIENTE
      ou usuário.
    </p>
    <p>
      3.4. Para proteger o sigilo de sua senha do certificado digital e de
      acesso ao sistema, recomenda-se ao CLIENTE:
    </p>

    <ul>
      <li>
        a) sair de sua conta ao final de cada sessão e assegurar que a mesma não
        seja acessada por terceiros não autorizados; e,
      </li>
      <li>
        b) não informar sua senha, seja do certificado digital ou de acesso ao
        sistema, nem mesmo à EGTEC, por e-mail, telefone ou outros meios.
      </li>
    </ul>

    <h3>4. Compartilhamento das informações</h3>

    <p>
      4.1. A EGTEC compromete-se a não comercializar e/ou tornar público os
      dados declarados pelo CLIENTE, sejam eles dados cadastrais, NF-e, com
      exceção das informações e documentos expressamente autorizados pelo
      CLIENTE.
    </p>
    <p>
      4.2. A EGTEC não se responsabiliza pelo mau uso do certificado digital
      pelo CLIENTE, sendo este de responsabilidade única e exclusiva do CLIENTE.
    </p>

    <h3>5. Conduta e Obrigações do CLIENTE</h3>

    <p>5.1. Como condição para utilizar o serviço, o CLIENTE concorda em:</p>

    <ul>
      <li>a) fornecer informações verdadeiras e exatas;</li>
      <li>
        b) autorizar e permitir o acesso ao certificado digital, que deverá
        estar válido e funcionando corretamente, ao SOFTWARE CONFEAR, assumindo
        a responsabilidade pelas informações prestadas;
      </li>
      <li>
        c) quanto ao certificado digital tipo A1: depositar, armazenar e
        instalar o certificado digital em cofre virtual vinculado ao data
        center, autorizando o SOFTWARE CONFEAR a realizar download das NF-e para
        o data center, responsabilizando-se a EGTEC pela segurança e privacidade
        das informações prestadas;
      </li>
      <li>
        d) quanto ao certificado digital tipo A3: manter o certificado instalado
        e conectado ao equipamento responsável pela coleta de dados e autorizar
        o SOFTWARE CONFEAR a, sempre que o equipamento estiver ativo e em
        conexão com a internet, fazer download das NF-e para o equipamento e,
        após, realizar o upload destas para o data center;
      </li>
      <li>
        e) garantir condições mínimas para correto desempenho do SOFTWARE
        CONFEAR, ciente de que este fará uso de recursos do equipamento, que
        devem estar disponíveis, em conformidade com as especificações que
        seguem (no mínimo): certificado digital válido, processador Core 2 Duo
        (ou similar); memória de 2 GB RAM; sistema operacional Windows XP; banda
        de internet de 2 Mbps.
      </li>
      <li>
        f) estar ciente de que o melhor desempenho do SOFTWARE CONFEAR depende
        de equipamento com configuração igual ou superior às que seguem:
        processador Core 3 (ou similar); memória de 4 GB RAM; sistema
        operacional Windows 7; banda de internet de 10 Mbps.
      </li>
      <li>
        g) estar ciente da necessidade de uso de impressora para realizar
        impressão das NF-e, se for de interesse do CLIENTE, bem como da
        necessidade de entrada USB disponível para utilização do certificado
        digital A3.
      </li>
      <li>
        h) aceitar ser o único responsável por toda e qualquer informação
        cadastrada no sistema, estando sujeito às consequências, administrativas
        e legais, decorrentes de declarações falsas ou inexatas que vierem a
        causar prejuízos ao bom desempenho das funções do SOFTWARE CONFEAR, à
        Administração Pública em geral ou a terceiros;
      </li>
      <li>i) não utilizar o serviço vinculado a fins ilícitos ou proibidos;</li>
      <li>
        j) não utilizar o serviço para transmitir/divulgar material ilícito,
        proibido ou difamatório, que viole a privacidade de terceiros, ou que
        seja abusivo, ameaçador, discriminatório, injurioso, ou calunioso;
      </li>
      <li>
        k) não transmitir e/ou divulgar qualquer material que viole direitos de
        terceiros, incluindo direitos de propriedade intelectual;
      </li>
      <li>
        l) não interferir ou interromper o serviço, as redes ou os servidores
        conectados ao serviço;
      </li>
      <li>
        m) não criar falsa identidade ou utilizar-se de subterfúgios com a
        finalidade de enganar outras pessoas ou de obter benefícios;
      </li>
      <li>
        n) manter-se adimplente quanto à obrigações relacionadas ao serviço;
      </li>
      <li>
        o) autorizar a EGTEC a usar o nome e logomarca cadastrados no SOFTWARE
        CONFEAR para fins exclusivamente de divulgação e promoção deste na
        condição de CLIENTE.
      </li>
    </ul>

    <h3>6. Conduta e Obrigações da EGTEC</h3>

    <p>6.1. A EGTEC reserva-se o direito de:</p>

    <ul>
      <li>
        a) fazer uso do certificado digital do CLIENTE exclusivamente para
        exercer as funções da rotina de NF-e junto aos órgãos governamentais
        responsáveis pela emissão de NF-e.
      </li>
      <li>
        b) realizar, sem aviso prévio, auditorias periódicas acerca das
        informações cadastradas fornecidas pelo CLIENTE;
      </li>
      <li>
        c) rescindir o Contrato e cancelar o acesso do CLIENTE ao serviço, bem
        como suprimir o registro das informações do CLIENTE sempre que verificar
        a má utilização por este do SOFTWARE CONFEAR, ou a prática de abusos na
        sua utilização e no lançamento de informações cadastrais, bem como em
        situação de inadimplência contratual. Entende-se por abuso toda e
        qualquer atividade que ocasione prejuízo ou lesão de direitos de ou a
        terceiros. A prática de ato delituoso, por intermédio do SOFTWARE
        CONFEAR, ocasionará apuração por meio de avaliação interna e caso
        constatada a responsabilidade do CLIENTE serão adotadas medidas
        repressivas que poderão envolver a interrupção automática e imediata de
        acesso do CLIENTE ao sistema.
      </li>
    </ul>

    <p>
      6.2. A EGTEC não se responsabiliza pelas declarações falsas ou inexatas
      prestadas pelo CLIENTE que vierem a causar prejuízos a terceiros, à
      Administração Pública em geral ou ao próprio CLIENTE.
    </p>

    <h3>7. PROPRIEDADE INTELECTUAL</h3>

    <p>
      7.1. Os produtos, gráficos, interface de usuário, clipes de áudio, clipes
      de vídeo, conteúdo editorial e scripts e software utilizados para
      implementar o SOFTWARE CONFEAR contêm informações exclusivas e material
      que são propriedade da EGTEC e/ou seus licenciantes e são protegidos pelas
      leis de propriedade intelectual e outras leis aplicáveis, incluindo, sem
      limitação, direitos autorais. O CLIENTE concorda que não utilizará tais
      informações ou materiais exclusivos de nenhuma forma, exceto para uso dos
      serviços em conformidade com este Termo. Nenhuma parte dos serviços poderá
      ser reproduzida de qualquer forma ou por qualquer meio, exceto conforme
      expressamente permitido por este Termo. O CLIENTE concorda em não
      modificar, alugar, arrendar, emprestar, vender, distribuir ou criar obras
      derivadas baseadas no SOFTWARE CONFEAR de qualquer forma, e não deverá
      explorar o SOFTWARE CONFEAR de qualquer forma não autorizada.
    </p>
    <p>
      7.2. Sem prejuízo de qualquer disposição deste Termo, a EGTEC e seus
      representantes reservam-se o direito de alterar e/ou suspender, conteúdos
      ou outros materiais do SOFTWARE CONFEAR, a qualquer momento, sem aviso. Em
      hipótese alguma, a EGTEC será responsabilizada por ter realizado tais
      alterações. A EGTEC poderá ainda impor limites sobre o uso de ou acesso a
      determinadas funcionalidades ou partes do SOFTWARE CONFEAR, em qualquer
      caso e sem aviso ou responsabilidade.
    </p>
    <p>
      7.3. O logotipo, marca e logomarca da EGTEC e do SOFTWARE CONFEAR e outras
      marcas, marcas de serviço, gráficos e logos usados em relação ao SOFTWARE
      CONFEAR são marcas ou marcas registradas da EGTEC no Brasil e/ou outros
      países. Outras marcas, marcas de serviço, gráficos e logos usados em
      relação ao SOFTWARE CONFEAR podem ser marcas de seus respectivos
      proprietários. O CLIENTE não recebe qualquer direito ou licença com
      relação a quaisquer das marcas acima mencionadas e qualquer uso de tais
      marcas.
    </p>

    <h3>8. Modificações deste Termo de Adesão e Autorização</h3>

    <p>
      8.1. A EGTEC reserva-se o direito de alterar o conteúdo deste Termo, sendo
      responsabilidade do CLIENTE consultá-lo regularmente.
    </p>
    <p>
      8.2. O uso continuado do serviço implica na concordância do CLIENTE com
      todas as regras, condições e avisos emanados do presente Termo, enquanto
      no SOFTWARE CONFEAR constar o cadastramento das informações pessoais
      fornecidas pelo CLIENTE.
    </p>

    <h3>9. Valor, Prazo e Reajuste</h3>

    <p>
      9.1. A EGTEC faz jus ao pagamento pelos serviços prestados em conjunto à
      licença de uso do SOFTWARE CONFEAR, conforme importância mensal fixada e
      optada pelo CLIENTE no site de vendas do SOFTWARE CONFEAR;
    </p>
    <p>
      9.2. O presente Termo terá prazo de acordo com a opção escolhida pelo
      CLIENTE no site de vendas do SOFTWARE CONFEAR, que poderá ser mensal,
      trimestral, semestral ou anual. Fica o CLIENTE ciente e a EGTEC autorizada
      a eliminar todos os dados cadastrais e suas respectivas Nf-e do sistema,
      contados 30 (trinta) dias corridos a partir do término do prazo optado;
    </p>
    <p>
      9.3. O CLIENTE declara ter ciência de que, conforme disposto no site de
      vendas do SOFTWARE CONFEAR, o valor da licença de uso do SOFTWARE CONFEAR
      será ajustado todo dia 01 de outubro pelo índice IGP-M (Índice Geral de
      Preço do Mercado) ou outro índice que o venha substituir.
    </p>

    <h3>10. Rescisão</h3>

    <p>
      10.1. Este Termo poderá ser rescindido, a qualquer momento, nas seguintes
      hipóteses:
    </p>

    <ul>
      <li>
        a) por parte do CLIENTE, no caso do não pagamento de duas parcelas
        consecutivas ajustadas, não se eximindo, neste caso, do pagamento das
        referidas parcelas;
      </li>
      <li>
        b) por qualquer das partes, na hipótese de violação de quaisquer
        obrigações nele contidas, caso a violação não seja interrompida e
        reparada no prazo de 30 (trinta) dias, contados a partir do recebimento
        de notificação escrita (física ou eletrônica) da parte lesada
        solicitando a cessação da violação;
      </li>
      <li>
        c) em caso de declaração de falência, recuperação judicial, dissolução
        ou liquidação judicial ou extrajudicial, requeridas ou homologadas pelas
        partes.
      </li>
    </ul>

    <p>
      10.2. Em caso de rescisão não restarão quaisquer obrigações entre as
      partes e, desta forma, poderá a EGTEC interromper o acesso ao SOFTWARE
      CONFEAR imediatamente após a rescisão, respeitando o disposto no item
      acima.
    </p>
    <p>
      10.3. Em caso de rescisão a partir de qualquer das hipóteses descritas
      acima, fica o CLIENTE ciente e a EGTEC autorizada a eliminar todos os
      dados cadastrais e suas respectivas Nf-e do sistema, contados 90 (noventa)
      dias corridos a partir da rescisão.
    </p>

    <h3>11. Legislação Aplicável</h3>

    <p>
      Aplica-se ao presente Termo, e às responsabilidades nele contidas, toda a
      legislação federal e estadual que lhe for pertinente. Fica eleito o Foro
      Central da Comarca do Rio de Janeiro, Capital, para dirimir quaisquer
      questões oriundas deste Termo, desistindo as partes de qualquer outro, por
      mais privilegiado que seja. O clique do campo "Li e Aceito os Termos do
      Contrato" é considerado, para todos os fins de direito, como aceitação
      pelo CLIENTE, via web, de todos os termos e condições do presente Termo,
      passando o mesmo a regular a relação entre as partes.
    </p>
  </p-scrollPanel>
  <section class="container-footer-modal" footer>
    <button
      pButton
      title="Cancelar"
      (click)="close()"
      class="btn-dark-red"
      type="button"
      label="Fechar"
      icon="pi pi-times"></button>
  </section>
</app-modal>
