import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { FieldsetModule } from 'primeng/fieldset';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalNfeManifestResumeComponent } from './modal-nfe-manifest-resume.component';

@NgModule({
  declarations: [ModalNfeManifestResumeComponent],
  exports: [ModalNfeManifestResumeComponent],
  imports: [CommonModule, ModalModule, FieldsetModule, AccordionModule]
})
export class ModalNfeManifestResumeModule {}
