<app-modal
  [modalFull]="true"
  modalTitle="Adicionar novo servidor"
  (closeModal)="close({ cancel: true })"
  [display]="open">
  <section *ngIf="open" class="container-modal-register-company">
    <form class="p-fluid grid formgrid" [formGroup]="form">
      <div class="col-12 flex justify-content-end mt-2 mb-3">
        <span class="required-symbol">*</span>
        <span class="required-info">Campo(s) de preenchimento obrigatório</span>
      </div>
      <div class="input-group field lg:col-6 md:col-6 col-12">
        <label for="server">
          Nome do Servidor
          <span class="required-symbol">*</span>
        </label>
        <input formControlName="servidor" id="server" type="text" pInputText />
      </div>
      <div class="input-group field lg:col-6 md:col-6 col-12">
        <label for="softwarePdv">Software de PDV</label>
        <p-dropdown
          placeholder="Selecione"
          optionLabel="descricao"
          id="softwarePdv"
          [options]="softwares"
          formControlName="softwarePdv"></p-dropdown>
      </div>
    </form>
    <div class="default-border-page">
      <app-grid-pdv-companies
        (selectedRows)="listenSelectedRows($event)"
        [companies]="companies"></app-grid-pdv-companies>
    </div>
  </section>
  <form
    [formGroup]="form"
    (ngSubmit)="register()"
    class="container-footer-modal"
    footer>
    <button
      pButton
      title="Cancelar"
      (click)="close({ cancel: true })"
      class="btn-dark-red"
      type="button"
      label="Cancelar"
      icon="pi pi-times"></button>
    <button
      pButton
      [disabled]="form?.invalid"
      title="Adicionar servidor"
      class="btn-green mr-2"
      type="submit"
      label="Adicionar servidor"
      icon="pi pi-check"></button>
  </form>
</app-modal>
