import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { Nfe } from '../../../core/models/entities/nfe/nfe';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';
import { SefazService } from '../../../core/services/sefaz/sefaz.service';

@Component({
  selector: 'app-modal-sefaz-situation',
  templateUrl: './modal-sefaz-situation.component.html',
  styleUrls: ['./modal-sefaz-situation.component.scss']
})
export class ModalSefazSituationComponent implements OnInit, OnDestroy {
  open: boolean;
  nfe: any;
  subscription: Subscription[] = [];
  status: { id: number; descricao: string; mensagem: string };
  loading: boolean;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService,
    private sefazService: SefazService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService
      .listenChanges()
      .subscribe(async res => {
        if (
          res?.id ===
            CONFEAR_PERMISSIONS.VERIFICAR_SITUACAO_NFE_SEFAZ_ESTADUAL &&
          res.open
        ) {
          this.loading = true;
          this.nfe = res.data;
          this.open = true;
          await this.getStatus();
        }
      });

    this.subscription.push(subscription);
  }

  async getStatus() {
    const cnpjDestinatario = this.nfe?.cnpjDestinatario
      ? this.nfe?.cnpjDestinatario
      : this.nfe?.destinatario?.cnpj;
    const response = await lastValueFrom(
      this.sefazService.getStatus(this.nfe.chaveNfe, cnpjDestinatario)
    );
    if (!response?.error) {
      this.status = response;
    }
    this.loading = false;
  }

  close() {
    this.open = false;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
