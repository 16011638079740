import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DefaultResponse } from '../../models/responses/default-response';
import { ServidorIntegracaoResponse } from '../../models/responses/servidor-integracao/servidor-integracao-response';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class ServidorIntegracaoService {
  private url = `${environment.baseUrl}/servidor-integracao`;

  constructor(private http: HttpClient) {}

  list(): Observable<DefaultResponse<ServidorIntegracaoResponse>> {
    return this.http
      .get<DefaultResponse<ServidorIntegracaoResponse>>(`${this.url}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  register(obj: any): Observable<any> {
    return this.http.post<any>(`${this.url}`, obj).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, {}).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  attach(obj: any): Observable<any> {
    return this.http
      .put<any>(`${this.url}/${obj.id}/empresas`, { empresas: obj.empresas })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
