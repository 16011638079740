import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentTooltipInfoComponent } from './content-tooltip-info.component';

@NgModule({
  declarations: [ContentTooltipInfoComponent],
  exports: [ContentTooltipInfoComponent],
  imports: [CommonModule]
})
export class ContentTooltipInfoModule {}
