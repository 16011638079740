import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-tooltip-info',
  templateUrl: './content-tooltip-info.component.html',
  styleUrls: ['./content-tooltip-info.component.scss']
})
export class ContentTooltipInfoComponent {
  @Input() title;
  @Input() text;
  constructor() {}
}
