import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { loadMessages, locale } from 'devextreme/localization';
import * as ptMessage from 'devextreme/localization/messages/pt.json';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';

import { AuthService } from '../../core/services/auth/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'confear-web-front';
  isLoggedIn = true;
  externalPage: boolean;
  externalRoutes = ['alterar-senha', 'esqueci-minha-senha', '/'];
  openSetup = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private config: PrimeNGConfig,
    private route: ActivatedRoute,
    private gaService: GoogleAnalyticsService,
    private titleService: Title
  ) {
    loadMessages(ptMessage);
    locale(navigator.language);
    this.config.setTranslation({
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 0,
      today: 'Hoje'
    });
  }

  ngOnInit() {
    this.changeHubspotIcon();
    this.listenUser();
    this.sendGa();
  }

  changeHubspotIcon() {
    const container = document.getElementById('chat-widget-launcher');
    console.log(container);
  }

  sendGa() {
    if (!environment.production) {
      console.log('tráfego interno');
      this.gaService.set({ traffic_type: 'internoegtec' });
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.gaService.pageView(event?.url, this.titleService.getTitle());
      });
  }

  listenUser() {
    this.authService.getUserObservable().subscribe(user => {
      if (user?.refreshTokenExpired) {
        this.isLoggedIn = false;
        this.router.navigate(['login'], {
          queryParams: { tokenExpired: true }
        });
      } else if (user?.sessionDuplicated) {
        this.isLoggedIn = false;
        this.router.navigate(['login']);
      } else {
        this.isLoggedIn = !!user;
        this.openSetup = !user?.indSetupInicialConcluido;
        this.externalPage = this.externalRoutes.some(route => {
          return (this.externalPage = this.router?.url === route);
        });

        if (!this.isLoggedIn && !this.externalPage) {
          this.router.navigate(['login']);
        }
      }
    });
  }
}
