<app-modal
  modalTitle="Histórico de manifestações NF-e"
  [nfeNumber]="nfe?.numeroNfe"
  (closeModal)="close()"
  [display]="open">
  <app-loading-fragment
    height="200px"
    [loading]="loading"></app-loading-fragment>
  <div *ngIf="!errorMessage" class="container-modal-manifestation-log">
    <section class="header-manifestation-log">
      <div class="cell">Evento</div>
      <div class="cell">Data</div>
      <div class="cell">Login</div>
    </section>
    <section *ngFor="let log of logs" class="row-manifestation-log">
      <div class="cell">
        <app-icon
          fontSize="23px"
          marginRight="5px"
          [icon]="log.icone"></app-icon>
        <span class="event-description">{{ log.evento }}</span>
      </div>
      <div class="cell">{{ log.data | date: 'dd/MM/yyyy HH:mm' }}</div>
      <div class="cell">{{ log.login }}</div>
    </section>
  </div>

  <div *ngIf="errorMessage">
    <span>{{ errorMessage }}</span>
  </div>
</app-modal>
