import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StatusSefazDirective } from './status-sefaz.directive';

@NgModule({
  declarations: [StatusSefazDirective],
  exports: [StatusSefazDirective],
  imports: [CommonModule]
})
export class StatusSefazModule {}
