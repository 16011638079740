import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxTooltipModule
} from 'devextreme-angular';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

import { ModalModule } from '../../core/components/modal/modal.module';
import { PageWrappingModule } from '../../core/components/page-wrapping/page-wrapping.module';
import { CustoRealModule } from '../../core/pipes/custo-real/custo-real.module';
import { ModalCostLegendModule } from '../modals/modal-cost-legend/modal-cost-legend.module';
import { NfeAnalysisComponent } from './nfe-analysis.component';

@NgModule({
  declarations: [NfeAnalysisComponent],
  imports: [
    CommonModule,
    DxDataGridModule,
    PageWrappingModule,
    ModalModule,
    DxButtonModule,
    DxDropDownButtonModule,
    ButtonModule,
    DxTooltipModule,
    CustoRealModule,
    TooltipModule,
    ModalCostLegendModule
  ],
  exports: [NfeAnalysisComponent]
})
export class NfeAnalysisModule {}
