import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { LoadingFragmentComponent } from './loading-fragment.component';

@NgModule({
  declarations: [LoadingFragmentComponent],
  exports: [LoadingFragmentComponent],
  imports: [CommonModule, ProgressSpinnerModule]
})
export class LoadingFragmentModule {}
