<section id="container-menu-tree">
  <div class="group" *ngFor="let node of voDataTree">
    <ng-container
      *ngTemplateOutlet="
        node?.children?.length ? GroupTemplate : LeafTemplate;
        context: { item: node }
      "></ng-container>
  </div>

  <ng-template #LeafTemplate let-item="item">
    <div
      [style.padding-left.px]="item?.level * 12"
      class="menu-item"
      [class.root-item-selected]="
        (item?.expanded && item?.root) || (item?.actived && item?.root)
      "
      [class.root-item]="item?.root"
      (click)="expandItem(item)">
      <em *ngIf="item?.icon" class="menu-item-icon" [class]="item?.icon?.class">
        {{ item?.icon?.lib === 'material' ? item?.icon?.name : '' }}
      </em>
      <em *ngIf="!item?.root" class="material-icons menu-icon-arrow-right">
        navigate_next
      </em>
      <span *ngIf="!item?.url" class="menu-label">{{ item?.label }}</span>
      <a
        [class.link-actived]="item?.actived && !item?.root"
        *ngIf="item?.url"
        (click)="setMenuItem(item)"
        class="menu-label menu-link">
        {{ item?.label }}
      </a>
      <span
        *ngIf="item?.children?.length > 0"
        class="material-icons menu-expand-icon">
        {{ item?.expanded ? 'expand_more' : 'navigate_next' }}
      </span>
    </div>
  </ng-template>

  <ng-template #GroupTemplate let-item="item">
    <ng-container
      *ngTemplateOutlet="LeafTemplate; context: { item: item }"></ng-container>
    <section class="container-menu-subitem" [class.first-level]="item?.root">
      <div
        [class.hidden]="!item?.expanded"
        class="menu-subitem"
        *ngFor="let child of item?.children">
        <ng-container
          *ngTemplateOutlet="
            child?.children?.length ? GroupTemplate : LeafTemplate;
            context: { item: child }
          "></ng-container>
      </div>
    </section>
  </ng-template>
</section>
