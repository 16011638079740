import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';

import { NotificationDropdownComponent } from './notification-dropdown.component';

@NgModule({
  declarations: [NotificationDropdownComponent],
  exports: [NotificationDropdownComponent],
  imports: [CommonModule, TagModule, ButtonModule]
})
export class NotificationDropdownModule {}
