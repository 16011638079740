import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalNfeProductAnalysisComponent } from './modal-nfe-product-analysis.component';

@NgModule({
  declarations: [ModalNfeProductAnalysisComponent],
  imports: [CommonModule, ModalModule],
  exports: [ModalNfeProductAnalysisComponent]
})
export class ModalNfeProductAnalysisModule {}
