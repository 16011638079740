<div class="container">
  <div *ngFor="let step of steps" class="step">
    <span [style.width]="calcWidth()" *ngIf="!step.isFirst" class="line"></span>
    <div class="container-circle">
      <span
        class="circle"
        [ngClass]="step.isVisited ? 'visited' : 'notVisited'"
        [class.current]="step.isCurrent">
        {{ step.order }}
      </span>
      <span class="title">{{ step.label }}</span>
    </div>
    <span *ngIf="!step.isLast" class="line"></span>
  </div>
</div>
<ng-content></ng-content>
<ng-container *ngComponentOutlet="currentStep.component"></ng-container>
