<section
  *ngIf="loading"
  class="container-loading-fragment"
  [style.height]="height">
  <img id="logo-img" src="assets/logos/logo-confear.png" alt="logo confear" />
  <p-progressSpinner
    [style]="{ width: '50px', height: '50px' }"
    styleClass="custom-spinner"
    strokeWidth="8"
    fill="var(--surface-ground)"
    animationDuration=".5s"></p-progressSpinner>
</section>
