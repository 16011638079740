import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../../core/models/entities/company/company';
import { Servidor } from '../../../core/models/entities/servidor-integracao/servidor';
import { ServidorIntegracaoService } from '../../../core/services/servidor-integracao/servidor-integracao.service';

@Component({
  selector: 'app-modal-pdv-attach-company',
  templateUrl: './modal-pdv-attach-company.component.html',
  styleUrls: ['./modal-pdv-attach-company.component.scss']
})
export class ModalPdvAttachCompanyComponent implements OnChanges {
  @Input() server: Servidor;
  @Input() open: boolean;
  @Input() empresasNaoVinculadas: Company[] | any = [];
  @Output() closeModal = new EventEmitter();
  selectedCompanies: number[];
  companies: Company[] | any = [];

  constructor(
    private servidorIntegracaoService: ServidorIntegracaoService,
    private messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.server) {
      this.companies = [
        ...this.server?.empresas,
        ...this.empresasNaoVinculadas
      ];

      this.selectedCompanies = this.server?.empresas.map(empresa => {
        return empresa.id;
      });
    }
  }

  listenSelectedCompanies(event: any) {
    this.selectedCompanies = event.value;
  }

  close(event) {
    this.selectedCompanies = undefined as any;
    this.closeModal.emit(event);
  }

  checkIfPdvIsEqual() {
    let selectedCompanies = [];
    if (this.selectedCompanies?.length) {
      selectedCompanies = this.companies.filter(company => {
        return this.selectedCompanies.includes(company.id);
      });

      const hasDiff = selectedCompanies.some(
        (company: Company) =>
          company?.softwarePdv?.id !== this.server.softwarePdv.id
      );

      if (hasDiff) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          key: 'global-toast',
          sticky: true,
          detail:
            'O software PDV da(s) empresa(s) selecionada(s) é diferente do selecionado para o servidor. ' +
            'Apenas empresas com o mesmo software PDV selecionado devem ser vinculadas a este servidor.'
        });
        return false;
      }
      return true;
    }
  }

  async attach() {
    const request = {
      id: this.server.id,
      empresas: this.selectedCompanies
    };
    if (this.checkIfPdvIsEqual()) {
      const response = await lastValueFrom(
        this.servidorIntegracaoService.attach(request)
      );
      if (response?.mensagem) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          key: 'global-toast',
          sticky: true,
          detail: response?.mensagem
        });
      }
      this.close({ attach: true });
    }
  }
}
