import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CertificateValidate } from '../../models/requests/certificate-validate';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private url = `${environment.baseUrl}/certificado`;

  constructor(private http: HttpClient) {}

  validate(obj: CertificateValidate): Observable<any> {
    const formData = new FormData();
    formData.append('arquivo', obj.arquivo);
    formData.append('senha', obj.senha);
    formData.append('idEmpresa', obj.idEmpresa.toString());
    formData.append('indAceiteTermo', obj.indAceiteTermo.toString());
    return this.http.post<any>(`${this.url}`, formData).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }
}
