import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../../core/services/auth/auth.service';
import { StepService } from '../../../../core/services/step/step.service';

@Component({
  selector: 'app-finished-user-update',
  templateUrl: './finished-user-update.component.html',
  styleUrls: ['./finished-user-update.component.scss']
})
export class FinishedUserUpdateComponent implements OnInit {
  login: string;
  indIntegrador: boolean;

  constructor(
    private stepService: StepService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const { indIntegrador } = this.authService.getUserValue();
    const step = this.stepService.find(1);
    this.login = step?.data?.email;
    this.indIntegrador = indIntegrador;
  }

  next() {
    this.authService.logout().subscribe(res => {});
  }
}
