import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

import { CompanyListComponent } from '../../../components/company-list/company-list.component';
import { DigitalCertificateManagementComponent } from '../../../components/digital-certificate-management/digital-certificate-management.component';
import { PdvIntegrationManagementComponent } from '../../../components/pdv-integration-management/pdv-integration-management.component';
import { ChangePasswordComponent } from '../../components/change-password/change-password.component';
import { mockStep } from '../../mocks/mock-step';
import { Step } from '../../models/interfaceObjects/step/step';
import { InitialSetupService } from '../initial-setup/initial-setup.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbSetupService {
  private currentIndex = 0;
  private $currentIndex: BehaviorSubject<number> = new BehaviorSubject<number>(
    this.currentIndex
  );
  private $currentStep: BehaviorSubject<Step> = new BehaviorSubject<Step>(
    mockStep
  );
  private steps: Step[] = [mockStep];
  private $steps: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>(
    this.steps
  );
  componentsMap = {
    ChangePasswordComponent: ChangePasswordComponent,
    PdvIntegrationManagementComponent: PdvIntegrationManagementComponent,
    CompanyListComponent: CompanyListComponent,
    DigitalCertificateManagementComponent: DigitalCertificateManagementComponent
  };

  constructor(private initialSetupService: InitialSetupService) {}

  setCurrentIndex(index: number) {
    this.$currentIndex.next(index);
    this.currentIndex = index;
    if (this.steps.length && this.steps[index]) {
      Step.markAsVisited(this.steps, this.steps[index]);
      this.$currentStep.next(this.steps[index]);
    }
  }

  getCurrentIndex() {
    return this.$currentIndex.asObservable();
  }

  getCurrentStep(): Observable<Step> {
    return this.$currentStep.asObservable();
  }

  getSteps(): Observable<Step[]> {
    return this.$steps.asObservable();
  }

  async reload() {
    const response = await lastValueFrom(this.initialSetupService.list());
    if (response.dados) {
      this.steps = response.dados.map((step, index) => {
        const {
          id,
          descricao,
          icone,
          componente,
          indObrigatoria,
          indPermiteAvancar,
          indUltimaEtapa,
          ordem
        } = step;
        return {
          id,
          icon: icone?.nome,
          label: descricao,
          url: '',
          isCurrent: index === 0,
          isLastStep: indUltimaEtapa,
          isFirst: index === 0,
          isVisited: false,
          isRequired: indObrigatoria,
          next: indPermiteAvancar,
          order: ordem,
          component: this.componentsMap[componente]
        };
      });
    }
    this.$steps.next(this.steps);
    this.$currentStep.next(this.steps[this.currentIndex]);
  }
}
