import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DxButtonModule,
  DxDataGridModule,
  DxProgressBarModule,
  DxTooltipModule
} from 'devextreme-angular';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';

import { ContentTooltipInfoModule } from '../../core/components/content-tooltip-info/content-tooltip-info.module';
import { IconModule } from '../../core/components/icon/icon.module';
import { ModalModule } from '../../core/components/modal/modal.module';
import { PageWrappingModule } from '../../core/components/page-wrapping/page-wrapping.module';
import { SidebarModule } from '../../core/components/sidebar/sidebar.module';
import { StatusSefazModule } from '../../core/directives/status-sefaz/status-sefaz.module';
import { TimeModule } from '../../core/pipes/time/time.module';
import { ModalUserRegisterUpdateModule } from '../modals/modal-user-register-update/modal-user-register-update.module';
import { NfeAnalysisModule } from '../nfe-analysis/nfe-analysis.module';
import { HomeFiltersComponent } from './home-filters/home-filters/home-filters.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [HomeComponent, HomeFiltersComponent],
  exports: [HomeComponent],
  imports: [
    CommonModule,
    PageWrappingModule,
    DxDataGridModule,
    IconModule,
    DxProgressBarModule,
    DxButtonModule,
    DxTooltipModule,
    StatusSefazModule,
    TimeModule,
    ContentTooltipInfoModule,
    ModalModule,
    NfeAnalysisModule,
    ButtonModule,
    HomeRoutingModule,
    SidebarModule,
    MultiSelectModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    ModalUserRegisterUpdateModule,
    MessagesModule,
    MessageModule
  ]
})
export class HomeModule {}
