<app-modal
  modalTitle="Atualização Cadastral"
  [width]="'70vw'"
  [closable]="false"
  [display]="display">
  <section class="content-modal default-border-page">
    <app-steps *ngIf="steps" [steps]="steps">
      <app-head-modal-user-register-update
        [currentLogin]="currentLogin"
        *ngIf="!current.isLast"></app-head-modal-user-register-update>
    </app-steps>
  </section>
</app-modal>
