<app-modal
  [removeTitleMargin]="false"
  (closeModal)="close()"
  [display]="open"
  [width]="'60vw'">
  <section header>Etiquetas</section>
  <app-loading-fragment
    height="200px"
    [loading]="loading"></app-loading-fragment>
  <section class="grid grid-no-gutter" *ngIf="open">
    <div class="col-12 lg:col-6" *ngFor="let tag of form">
      <div class="cell">
        <dx-check-box
          (onValueChanged)="listenCheckbox($event, tag)"
          [iconSize]="24"
          [(ngModel)]="tag.value"
          [enableThreeStateBehavior]="true"></dx-check-box>
        <span class="ml-2">{{ tag.descricao }}</span>
      </div>
    </div>
  </section>
  <section class="container-footer-modal" footer>
    <button
      pButton
      title="Cancelar"
      (click)="close()"
      class="btn-dark-red"
      type="button"
      label="Cancelar"
      icon="pi pi-times"></button>
    <button
      pButton
      (click)="save()"
      title="Salvar etiquetas"
      class="btn-green mr-2"
      type="button"
      label="Salvar"
      icon="pi pi-check"></button>
  </section>
</app-modal>
