import { style } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { Company } from '../../../core/models/entities/company/company';

@Component({
  selector: 'app-grid-pdv-companies',
  templateUrl: './grid-pdv-companies.component.html',
  styleUrls: ['./grid-pdv-companies.component.scss']
})
export class GridPdvCompaniesComponent {
  @Input() mode = 'multiple';
  @Input() companies: Company[];
  @Input() selectedCompanies: number[];
  @Output() selectedRows = new EventEmitter();

  constructor() {}

  listenSelectedRows(event: any) {
    if (event.name === 'selectedRowKeys') {
      this.selectedRows.emit(event);
    }
  }

  cnpjTransform(cell) {
    return cell.cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
}
