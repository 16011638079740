import { MenuItem } from '../menu/menuItem';
import { Funcionalidade } from './funcionalidade';
import { Grupo } from './grupo';
import { Permissoes } from './permissoes';

export class User {
  id: number;
  apiKey: string;
  nome: string;
  login: string;
  loginMaster: string;
  grupo: Grupo[];
  menu: MenuItem[];
  empresas: any[];
  email: string;
  refreshToken: string;
  expiracao: string;
  expiracaoRefreshToken: string;
  permissoes: Permissoes;
  celular: string;
  indAtivo: boolean;
  indAdministradorSistema: boolean;
  indAdministradorEmpresas: boolean;
  indPossuiMultiplosMaster?: boolean;
  indContador: boolean;
  indFinanceiro: boolean;
  indAcodeCAP: boolean;
  indTrocaSenhaObrigatoria: boolean;
  indSetupInicialConcluido: boolean;
  idSetupInicialEtapa: number;
  indProtegerSaida: boolean;
  totalEmpresas: number;
  dataCadastro: Date;
  ultimoAcesso: Date;
  ultimoIDNfeBaixadaIntegracao: number;
  usuariosMaster: { id: number; nome: string; indLogado: boolean }[];
  versaoAtualWeb: string;
  tempoTokenMin: number;
  indSolicitaRecadastro: boolean;
  indIntegrador: boolean;
  refreshTokenExpired: boolean;
  sessionDuplicated: boolean;

  static isTokenExpired(user: User): boolean {
    return (
      this.getHoursDiff(
        this.getDateBR(),
        this.getDateBR(new Date(user.expiracao))
      ) <= 0
    );
  }

  /***
   * Recebe uma data em qualquer timezone e converte com o GMT de brasília
   * Caso nenhuma data seja passada, retorna a data e hora atual
   * ***/
  static getDateBR(date?: Date): Date {
    const today = new Date();
    const todayWithTimezone = new Date(
      new Date(today).toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' })
    );
    if (date) {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone: 'America/Sao_Paulo'
        })
      );
    }

    return todayWithTimezone;
  }

  static isRefreshTokenExpired(user: User): boolean {
    return (
      this.getHoursDiff(
        this.getDateBR(),
        this.getDateBR(new Date(user.expiracaoRefreshToken))
      ) <= 0
    );
  }

  static tokenTimeLeft(user: User): number {
    return this.getHoursDiff(
      this.getDateBR(),
      this.getDateBR(new Date(user.expiracao))
    );
  }

  static isMaster(group) {
    return group.some(grupo => {
      return grupo.id === 1;
    });
  }

  static getLoggedMaster(user) {
    if (user?.usuariosMaster?.length) {
      return user?.usuariosMaster.find(user => user.indLogado);
    }
    return undefined;
  }

  static getHoursDiff(startDate: Date, endDate: Date): number {
    const millisecondsInMin = 1000 * 60;

    const start = this.getDateBR(startDate).toLocaleString('en-US', {
      timeZone: 'America/Sao_Paulo'
    });
    const end = this.getDateBR(endDate).toLocaleString('en-US', {
      timeZone: 'America/Sao_Paulo'
    });
    return Math.round(
      (Date.parse(end) - Date.parse(start)) / millisecondsInMin
    );
  }

  static hasPermission(permissoes: Funcionalidade[], permissao: number) {
    return Boolean(
      permissoes?.find(item => {
        return item.id === permissao;
      })
    );
  }
}
