import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { exists } from '../../utils/exists';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}

  readonly MASTER = 1;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.messageService.clear('global-toast');
    if (!this.authService.isLogged()) {
      return this.router.createUrlTree(['/login']);
    }

    const user = this.authService.getUserValue();

    if (user?.indSolicitaRecadastro && !state.url.includes('inicio')) {
      return this.router.createUrlTree(['/inicio']);
    }

    if (!user.indSetupInicialConcluido) {
      if (user?.idSetupInicialEtapa) {
        this.router.navigate(['passo-a-passo-setup'], {
          queryParams: {
            step: user?.idSetupInicialEtapa
          }
        });
      } else {
        this.router.navigate(['pagina-inicial-setup']);
      }
    }

    if (user.indTrocaSenhaObrigatoria && user.indSetupInicialConcluido) {
      this.router.navigate(['alterar-senha-logado']);
    }

    const { grupo, indAtivo } = this.authService.getUserValue();

    if (grupo[0].id === this.MASTER && !indAtivo) {
      return this.router.createUrlTree(['/acesso-indisponivel']);
    }

    if (this.hasPermission(route)) {
      return true;
    }

    const funcionalidade = this.getFeature(route?.routeConfig?.path);

    if (exists(funcionalidade)) {
      return this.router.createUrlTree([
        '/comprar-modulo',
        { modulo: funcionalidade?.modulo?.id }
      ]);
    }

    return this.router.createUrlTree(['/sem-acesso']);
  }

  hasPermission(route) {
    const foundFeature = this.getFeature(route.routeConfig.path);

    if (!exists(foundFeature)) {
      return false;
    }

    /*O usuário possui acesso a todas as funcionalidades que não possuem módulo*/
    if (!foundFeature?.modulo) {
      return true;
    }

    if (foundFeature) {
      return foundFeature.modulo.indContratado;
    }

    return false;
  }

  getFeature(path) {
    if (path) {
      const { grupo } = this.authService.getUserValue();
      return grupo[0].funcionalidades.find(
        funcionalidade => funcionalidade.rota === path
      );
    }

    return null;
  }
}
