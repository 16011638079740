import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AnaliseMercadoMedicamento } from '../../models/entities/analise-mercado-medicamento/analise-mercado-medicamento';
import { AnaliseMercadoNfe } from '../../models/entities/analise-mercado-nfe/analise-mercado-nfe';
import { ProdutoCondicaoComercial } from '../../models/entities/condicao-comercial/condicao-comercial';
import { AnaliseNfe } from '../../models/entities/nfe-analise/analise-nfe';
import { NfeCartaCorrecao } from '../../models/entities/nfe-cc/nfe-carta-correcao';
import { NfeJustificativaManifestacao } from '../../models/entities/nfe-justificativa-manifestacao/nfe-justificativa-manifestacao';
import { ProdutoDetalhado } from '../../models/entities/nfe-produto/produto-detalhado';
import { NfeTag } from '../../models/entities/nfe-tag/nfe-tag';
import { EventoManifestacao } from '../../models/entities/nfe/evento-manifestacao';
import { ManifestacaoLog } from '../../models/entities/nfe/manifestacao-log';
import { Nfe } from '../../models/entities/nfe/nfe';
import { ResponseError } from '../../models/entities/response-error';
import { StatusSefaz } from '../../models/entities/status-sefaz/status-sefaz';
import { NfeManifestacaoRequest } from '../../models/requests/nfe-manifestacao-request';
import { DefaultResponse } from '../../models/responses/default-response';
import { NfeManifestacaoResponse } from '../../models/responses/nfe/nfe-manifestacao-response';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class NfeService {
  private url = `${environment.baseUrl}/nfe`;

  constructor(private http: HttpClient) {}

  list(): Observable<any> {
    // return of(this.mockJson);
    return this.http.get<any>(`${this.url}/monitoramento`).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
  }

  listLegend(): Observable<DefaultResponse<EventoManifestacao>> {
    return this.http
      .get<DefaultResponse<EventoManifestacao>>(
        `${this.url}/legenda-manifestacao`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );

    // return of(this.mockLegend);
  }

  listManifestationLog(
    nfeKey: string
  ): Observable<DefaultResponse<ManifestacaoLog>> {
    return this.http
      .get<DefaultResponse<ManifestacaoLog>>(
        `${this.url}/${nfeKey}/manifestacao/historico`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
    /*
    return of(this.mockManifestation);
*/
  }

  listStatusSefaz(): Observable<DefaultResponse<StatusSefaz>> {
    return this.http
      .get<DefaultResponse<StatusSefaz>>(`${this.url}/status-sefaz`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
    // return of(this.mockStatusSefazList);
  }

  nfeAnalysis(nfeKey: string): Observable<DefaultResponse<AnaliseNfe>> {
    return this.http
      .get<DefaultResponse<AnaliseNfe>>(`${this.url}/${nfeKey}/analisar`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );

    // return of(this.mockAnaliseNfe);
  }

  productAnalysis(
    nfeKey: string,
    productKey: string
  ): Observable<DefaultResponse<ProdutoDetalhado>> {
    return this.http
      .get<DefaultResponse<ProdutoDetalhado>>(
        `${this.url}/${nfeKey}/produto/${productKey}`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );

    // return of(this.mockDadosProdutoDetalhado);
  }

  getNfePdf(nfeKey: string): Observable<any> {
    return this.http
      .get(`${this.url}/${nfeKey}/imprimir`, {
        responseType: 'blob'
      })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getNfeXml(nfeKey: string): Observable<any> {
    return this.http
      .get(`${this.url}/${nfeKey}/xml`, {
        responseType: 'text'
      })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getCartaCorrecao(
    nfeKey: string
  ): Observable<DefaultResponse<NfeCartaCorrecao>> {
    return this.http
      .get<DefaultResponse<NfeCartaCorrecao>>(
        `${this.url}/${nfeKey}/cartas-correcao`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
    // return of(mockCartaCorrecao);
  }

  listNfeManifest(): Observable<DefaultResponse<Nfe>> {
    return this.http.get<DefaultResponse<Nfe>>(`${this.url}/manifestacao`).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
    // return of(mockListNfe);
  }

  listNfeLibrary(filterParams): Observable<DefaultResponse<Nfe>> {
    // return of(mockListNfe);
    /*}*/

    return this.http.post<any>(`${this.url}/biblioteca`, filterParams).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
  }

  getJustification(
    nfeKey: string
  ): Observable<DefaultResponse<NfeJustificativaManifestacao>> {
    return this.http
      .get<DefaultResponse<NfeJustificativaManifestacao>>(
        `${this.url}/${nfeKey}/justificativa-manifestacao`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
    // return of(mockCartaCorrecao);
  }

  manifest(
    object: NfeManifestacaoRequest
  ): Observable<DefaultResponse<NfeManifestacaoResponse>> {
    return this.http
      .post<DefaultResponse<NfeManifestacaoResponse>>(
        `${this.url}/manifestar`,
        object
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
    // return of({ dados: mockNfeManifestationResponse });
  }

  getNfeTags(object: any): Observable<DefaultResponse<NfeTag[]>> {
    return this.http
      .post<DefaultResponse<NfeTag[]>>(
        `${this.url}/etiquetas-nfe-listar`,
        object
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  saveEtiquetas(object): Observable<any> {
    return this.http
      .post<DefaultResponse<NfeTag[]>>(`${this.url}/etiquetas`, object)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listCommercialCondition(
    filterParams
  ): Observable<DefaultResponse<ProdutoCondicaoComercial>> {
    // return of(mockCommercialCondition);
    return this.http
      .post<any>(`${this.url}/condicao-comercial`, filterParams)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getSeparateDownload(obj: { chave: string; cnpj: string }): Observable<any> {
    return this.http
      .get(
        `${this.url}/baixar-nfe-avulsa?chave=${obj.chave}&cnpj=${obj.cnpj}`,
        {
          responseType: 'blob'
        }
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listMarketAnalysis(
    filterParams: any
  ): Observable<DefaultResponse<AnaliseMercadoNfe>> {
    // return of(mockAnaliseMercadoNfe);
    return this.http
      .post<DefaultResponse<AnaliseMercadoNfe>>(
        `${this.url}/analise-mercado-nfe`,
        filterParams
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  listDrugRank(): Observable<DefaultResponse<AnaliseMercadoMedicamento>> {
    return this.http
      .get<DefaultResponse<AnaliseMercadoMedicamento>>(
        `${this.url}/ranqueamento-medicamentos`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  uploadFileMarketAnalysis(file: any): Observable<DefaultResponse<any>> {
    const formData = new FormData();
    formData.append('arquivo', file);
    return this.http
      .post<DefaultResponse<any>>(`${this.url}/analise-mercado`, formData)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  downloadInLot(object: any): Observable<any> {
    return this.http
      .post(`${this.url}/download`, object, { responseType: 'arraybuffer' })
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
