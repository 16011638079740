import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../../core/components/icon/icon.module';
import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalNfeManifestationLogComponent } from './modal-nfe-manifestation-log.component';

@NgModule({
  declarations: [ModalNfeManifestationLogComponent],
  exports: [ModalNfeManifestationLogComponent],
  imports: [CommonModule, IconModule, ModalModule, LoadingFragmentModule]
})
export class ModalNfeManifestationLogModule {}
