import { Component, Input } from '@angular/core';

import { Icon } from '../../models/entities/icon/icon';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() icon: Icon | undefined;
  @Input() margin: string;
  @Input() marginTop: string;
  @Input() marginLeft: string;
  @Input() marginBottom: string;
  @Input() marginRight: string;
  @Input() justifyContent: string;
  @Input() fontSize: string;

  constructor() {}
}
