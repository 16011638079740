<section id="main-container">
  <app-topbar></app-topbar>
  <div id="container-menu-breadcrumb-dashboard-footer">
    <app-menu></app-menu>
    <div class="container-icon">
      <em
        (click)="toggleMenu()"
        id="menu-icon"
        [style.right.px]="[display ? -10 : -17]"
        [ngClass]="[
          display ? 'pi pi-chevron-left' : 'pi pi-chevron-right'
        ]"></em>
    </div>
    <div id="main-content">
      <!--      <app-breadcrumb></app-breadcrumb>-->
      <app-dashboard></app-dashboard>
      <app-footer></app-footer>
    </div>
  </div>
</section>
