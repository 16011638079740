<app-modal (closeModal)="close()" [display]="open">
  <section header>
    Verificar situação da NF-e
    <span class="nfe-key-header-modal">{{ nfe?.numeroNfe }}</span>
    na SEFAZ estadual
  </section>
  <app-loading-fragment
    [height]="'150px'"
    [loading]="loading"></app-loading-fragment>
  <section *ngIf="!loading" class="content-icon-message">
    <div class="content">
      <span class="material-icons-outlined icon">error</span>
      <span>{{ status?.descricao }}</span>
    </div>
  </section>
</app-modal>
