<app-modal (closeModal)="close()" [display]="open" [width]="'90vw'">
  <section header>Relatório Avançado por Produto</section>
  <app-loading-fragment [loading]="loading"></app-loading-fragment>
  <ng-container *ngIf="open">
    <app-grid-product-advanced-report
      (dataLoading)="listenDataLoading($event)"
      [nfes]="nfes"></app-grid-product-advanced-report>
  </ng-container>
  <div class="warning text-red mt-3">
    Caso alguns produtos não tenham sido exibidos, tente novamente mais tarde,
    pois as NF-e mais recentes podem estar em processo de indexação.
  </div>
</app-modal>
