import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { Step } from '../../../models/interfaceObjects/step/step';
import { AuthService } from '../../../services/auth/auth.service';
import { BreadcrumbSetupService } from '../../../services/breadcrumb-setup-service/breadcrumb-setup.service';
import { InitialSetupService } from '../../../services/initial-setup/initial-setup.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';

@Component({
  selector: 'app-breadcrumb-setup',
  templateUrl: './breadcrumb-setup.component.html',
  styleUrls: ['./breadcrumb-setup.component.scss']
})
export class BreadcrumbSetupComponent implements OnInit {
  steps: Step[] = [];
  current: Step;
  currentIndex: number;
  lastVisitedStep = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private initialSetupService: InitialSetupService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbSetupService
  ) {}

  ngOnInit() {
    this.breadcrumbService.getSteps().subscribe(steps => {
      this.steps = steps;
    });
    this.breadcrumbService.getCurrentIndex().subscribe(index => {
      this.currentIndex = index;
    });
    this.breadcrumbService.getCurrentStep().subscribe(current => {
      this.current = current;
    });
  }

  async next() {
    await this.breadcrumbService.reload();
    if (this.current.next) {
      if (this.currentIndex++ < this.steps.length - 1) {
        this.breadcrumbService.setCurrentIndex(this.currentIndex++);
        if (this.lastVisitedStep < this.currentIndex) {
          this.lastVisitedStep = this.currentIndex;
          this.update();
        }
      } else {
        this.currentIndex = this.currentIndex++;
        await this.update();
        this.router.navigate(['pagina-conclusao-setup']);
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        key: 'global-toast',
        sticky: true,
        summary: 'Atenção',
        detail:
          'Você não concluiu essa etapa! ' +
          'Por favor, conclua a etapa para avançar para a próxima'
      });
    }
  }

  update() {
    const user = this.authService.getUserValue();
    this.initialSetupService.update(this.currentIndex).subscribe(res => {
      user.idSetupInicialEtapa = this.currentIndex;
      this.localStorageService.updateProperty('user', user);
    });
  }

  async previous() {
    if (this.currentIndex-- >= 0) {
      this.breadcrumbService.setCurrentIndex(this.currentIndex--);
    }
  }
}
