<dx-data-grid
  class="grid-container"
  [hoverStateEnabled]="true"
  [columnMinWidth]="100"
  [allowColumnResizing]="true"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [allowColumnReordering]="true"
  [rowAlternationEnabled]="true"
  [showBorders]="true">
  <dxi-column
    [width]="150"
    dataType="string"
    alignment="left"
    caption="CNPJ"
    dataField="cnpj"
    cellTemplate="cnpjTemplate"></dxi-column>
  <div *dxTemplate="let cell of 'cnpjTemplate'">
    {{ cell.value | mask: '00.000.000/0000-00' }}
  </div>
  <dxi-column
    [width]="350"
    [allowFiltering]="false"
    [allowSearch]="false"
    dataType="string"
    alignment="left"
    caption="RAZÃO SOCIAL"
    dataField="razaoSocial"></dxi-column>
  <dxi-column
    [width]="350"
    [allowFiltering]="false"
    [allowSearch]="false"
    dataType="string"
    alignment="left"
    caption="NOME FANTASIA"
    dataField="nomeFantasia"></dxi-column>
  <dxi-column
    [width]="40"
    [allowFiltering]="false"
    [allowSearch]="false"
    dataType="string"
    alignment="left"
    caption="UF"
    dataField="uf"></dxi-column>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
</dx-data-grid>
