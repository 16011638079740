import { MenuItem } from '../../entities/menu/menuItem';
import { IOIconMenu } from './IOIconMenu';

export class IOMenuItem {
  id: number;
  parentId: number | null;
  label: string;
  level: number;
  root: boolean;
  order: string;
  expanded: boolean;
  actived: boolean;
  url: string;
  children: IOMenuItem[];
  icon: IOIconMenu;

  static searchItem(
    treeItems: IOMenuItem[],
    searchId: number | null | undefined
  ): IOMenuItem | null {
    let foundItem: IOMenuItem | null = null;

    for (let item of treeItems) {
      if (item.id === searchId) {
        foundItem = item;
        break;
      }

      if (item.children !== undefined && item.children.length > 0) {
        foundItem = this.searchItem(item.children, searchId);
      }
      if (foundItem !== null) break;
    }

    return foundItem;
  }

  static parentKeyAbsent(treeItem: IOMenuItem | null): boolean {
    return treeItem?.parentId === undefined || treeItem.parentId === null;
  }

  static buildTree(node: MenuItem, parentId: number | null): IOMenuItem {
    let children: IOMenuItem[] = [];
    if (node?.itemMenu?.length > 0) {
      node.itemMenu.forEach(child =>
        children.push(this.buildTree(child, node.id))
      );
    }
    return {
      id: node.id,
      parentId: node.nivel === 1 ? null : parentId,
      label: node.titulo,
      level: node.nivel,
      root: node.nivel === 1,
      order: node.ordem,
      expanded: false,
      actived: false,
      url: node.url,
      children: children,
      icon: {
        lib: node?.icone?.lib ?? '',
        name: node?.icone?.nome ?? '',
        class: node?.icone?.class ?? ''
      }
    };
  }
}
