import { Component, OnInit, Renderer2 } from '@angular/core';

import packageInfo from '../../../../package.json';
import { User } from '../../models/entities/user/user';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: string = packageInfo.version;
  user: User;

  constructor(private authService: AuthService, private render: Renderer2) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.authService.getUserObservable().subscribe(response => {
      if (response) {
        this.user = response;
      }
    });
  }
}
