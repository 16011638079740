<section id="page-template-login" class="grid grid-nogutter">
  <div
    id="container-login"
    class="xl:col-10 lg:col-12 md:col-12 sm:col-12 col-12 grid grid-nogutter">
    <div id="content-left" class="xl:col-6 lg:col-6 md:col-4 col-4">
      <div id="container-logo-confear">
        <img
          id="logo-confear"
          alt="Logo do sistema Confear"
          src="assets/logos/logo-confear.png"
          class="col-12" />
        <hr id="line" />
        <p id="confear-description">A diferença na gestão de NF-e</p>
      </div>
      <div id="container-logo-egtec">
        <p id="copyright">Desenvolvido por</p>
        <img
          id="logo-egtec"
          alt="Logo da estrutural egtec"
          src="assets/logos/logo-egtec.png" />
      </div>
    </div>
    <div id="content-right" class="xl:col-6 lg:col-6 md:col-6 col-11">
      <a *ngIf="backButtonLink" id="btn-voltar" [routerLink]="backButtonLink">
        <em class="pi pi-arrow-left mr-1"></em>
        <p class="m-0">Voltar</p>
      </a>
      <div
        id="container-logo-confear-mobile"
        class="md:col-10 col-10 mt-3 pl-0">
        <img
          id="logo-confear-mobile"
          alt="Logo do sistema Confear"
          src="./assets/logos/logo-confear.png"
          class="col-12" />
      </div>
      <div
        [class.mb-2]="!description"
        class="md:col-9 col-10 md:mt-4 mt-4 pl-0">
        <h1 class="form-title">{{ title }}</h1>
      </div>
      <div *ngIf="description" class="md:col-9 col-10 mb-2 pl-0">
        <p class="form-description">{{ description }}</p>
      </div>
      <ng-content></ng-content>
      <div id="container-logo-egtec-mobile" class="md:col-9 col-10 mt-2">
        <p id="copyright-mobile">Desenvolvido por</p>
        <img
          id="logo-egtec-mobile"
          alt="Logo da estrutural egtec"
          src="./assets/logos/logo-egtec.png" />
      </div>
    </div>
  </div>
</section>
