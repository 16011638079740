import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalSefazSituationComponent } from './modal-sefaz-situation.component';

@NgModule({
  declarations: [ModalSefazSituationComponent],
  exports: [ModalSefazSituationComponent, ModalSefazSituationComponent],
  imports: [CommonModule, ModalModule, LoadingFragmentModule]
})
export class ModalSefazSituationModule {}
