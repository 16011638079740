import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { StepService } from '../../../../../core/services/step/step.service';

@Component({
  selector: 'app-form-email-validation',
  templateUrl: './form-email-validation.component.html',
  styleUrls: ['./form-email-validation.component.scss']
})
export class FormEmailValidationComponent implements OnInit {
  constructor(
    private stepService: StepService,
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  form: FormGroup;

  ngOnInit(): void {
    this.buildForm();
  }

  get email() {
    return this.form.get('email');
  }

  buildForm() {
    this.form = this.fb.group(
      {
        email: [null, [Validators.required, Validators.email]],
        emailConfirmation: [null, [Validators.required, Validators.email]]
      },
      { validators: this.emailValidator }
    );
  }

  emailValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const email = control.get('email');
    const emailConfirmation = control.get('emailConfirmation');

    return email && emailConfirmation && email.value !== emailConfirmation.value
      ? { emailEqual: false }
      : null;
  };

  async next() {
    if (this.form.valid) {
      const loginExists = await lastValueFrom(
        this.authService.getUserByEmailOrLogin(this.email?.value, 'login')
      );

      if (loginExists.dados?.length) {
        this.messageService.add({
          key: 'global-toast',
          severity: 'warn',
          summary: 'Atenção',
          detail:
            'Este email está sendo utilizado por outro usuário do sistema. Informe outro e-mail',
          sticky: true
        });
      } else {
        const response = await lastValueFrom(
          this.authService.emailValidation(this.email?.value)
        );

        if (response?.mensagem) {
          this.stepService.setData({
            email: this.email?.value,
            mensagem: response?.mensagem
          });
          this.stepService.next();
        }
      }
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  @HostListener('window:keydown', ['$event'])
  shortcutKeyModalNfePrint(event) {
    if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
      event.preventDefault();
    }
    if (event.ctrlKey && (event.key === 'v' || event.key === 'V')) {
      event.preventDefault();
    }
  }
}
