import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalCostLegendComponent } from './modal-cost-legend.component';

@NgModule({
  declarations: [ModalCostLegendComponent],
  exports: [ModalCostLegendComponent],
  imports: [CommonModule, ModalModule, ButtonModule]
})
export class ModalCostLegendModule {}
