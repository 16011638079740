import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimateModule } from 'primeng/animate';
import { SharedModule } from 'primeng/api';
import { SidebarModule as PSidebarModule } from 'primeng/sidebar';

import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [CommonModule, PSidebarModule, SharedModule, AnimateModule]
})
export class SidebarModule {}
