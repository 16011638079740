import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';

import { TemplateLoginModule } from '../template-login/template-login.module';
import { ChangePasswordRoutingModule } from './change-password-routing.module';
import { ChangePasswordComponent } from './change-password.component';
import { FormChangePasswordComponent } from './form-change-password/form-change-password.component';

@NgModule({
  declarations: [ChangePasswordComponent, FormChangePasswordComponent],
  imports: [
    CommonModule,
    TemplateLoginModule,
    ReactiveFormsModule,
    InputTextModule,
    ToastModule,
    ChangePasswordRoutingModule
  ],
  exports: [ChangePasswordComponent],
  providers: [MessageService]
})
export class ChangePasswordModule {}
