import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SoftwarePdv } from '../../models/entities/company/softwarePdv';
import { DefaultResponse } from '../../models/responses/default-response';
import { ConsultaCnpjResponse } from '../../models/responses/receitaws/consulta-cnpj-response';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class EgtecService {
  private url = `${environment.baseUrl}/egtecservicos`;
  constructor(private http: HttpClient) {}

  listSoftwares(): Observable<DefaultResponse<SoftwarePdv>> {
    return this.http
      .get<DefaultResponse<SoftwarePdv>>(`${this.url}/softwares`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getCompany(cnpj: string): Observable<DefaultResponse<ConsultaCnpjResponse>> {
    return this.http
      .get<DefaultResponse<ConsultaCnpjResponse>>(`${this.url}/cnpj/${cnpj}`)
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
