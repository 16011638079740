import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { ManifestacaoLog } from '../../../core/models/entities/nfe/manifestacao-log';
import { Nfe } from '../../../core/models/entities/nfe/nfe';
import { DefaultResponse } from '../../../core/models/responses/default-response';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-modal-nfe-manifestation-log',
  templateUrl: './modal-nfe-manifestation-log.component.html',
  styleUrls: ['./modal-nfe-manifestation-log.component.scss']
})
export class ModalNfeManifestationLogComponent implements OnInit {
  open: boolean;
  loading: boolean;
  nfe: Nfe;
  subscription: Subscription[] = [];
  logs: ManifestacaoLog[];
  errorMessage: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.HISTORICO_MANIFESTACAO && res.open) {
        this.nfe = res.data;
        this.getManifestationLogs();
      }
    });

    this.subscription.push(subscription);
  }

  getManifestationLogs() {
    this.nfeService
      .listManifestationLog(this.nfe.chaveNfe)
      .subscribe((logs: any) => {
        this.errorMessage = '';
        if (logs.dados) {
          this.logs = logs.dados as ManifestacaoLog[];
          this.loading = true;
          this.open = true;
        }

        if (logs?.error) {
          this.errorMessage = logs.error?.mensagem?.reduce((acc, cc) => {
            this.errorMessage.concat(cc);
          });
        }
        this.loading = false;
      });
  }

  close() {
    this.loading = false;
    this.open = false;
    this.errorMessage = '';
  }
}
