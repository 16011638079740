<app-modal
  modalTitle="Solicitar Exclusão Empresa"
  width="50vw"
  (closeModal)="close()"
  [closable]="false"
  [display]="open">
  <form class="p-fluid grid formgrid" [formGroup]="form">
    <span class="lg:col-12 col-12 mb-2">
      Ao preencher o formulário abaixo, sua solicitação de exclusão de empresa
      será enviada e retornaremos assim que concluirmos
    </span>
    <div class="input-group field col-12">
      <label for="motivo" class="">Motivo</label>
      <p-dropdown
        id="motivo"
        placeholder="Selecione um motivo"
        [options]="deleteReasons"
        formControlName="motivoExclusao"
        optionLabel="descricao"></p-dropdown>
    </div>
    <div class="input-group field col-12">
      <label for="justificativa" class="">Observações</label>
      <span class="info mb-1">
        Se desejar, nos envie mais informações a respeito da sua solicitação
      </span>
      <textarea
        id="justificativa"
        formControlName="observacoes"
        [maxLength]="255"
        [rows]="7"
        pInputTextarea></textarea>
    </div>
    <form
      [formGroup]="form"
      (ngSubmit)="delete()"
      class="container-footer-modal"
      footer>
      <button
        type="button"
        (click)="close()"
        pButton
        label="Cancelar"
        class="btn-dark-red mr-2"
        icon="pi pi-times"></button>
      <button
        type="submit"
        pButton
        label="Enviar solicitação"
        class="btn-green"
        icon="pi pi-check"></button>
    </form>
  </form>
</app-modal>
