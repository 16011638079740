<section id="main-container">
  <app-topbar-setup></app-topbar-setup>
  <app-breadcrumb-setup></app-breadcrumb-setup>
  <section
    *ngIf="currentStep"
    id="dashboard-setup-container"
    [style.min-height]="minHeight">
    <div class="flex flex-column">
      <div id="dashboard-setup-content" *ngIf="steps.length">
        <ng-container *ngComponentOutlet="currentStep.component"></ng-container>
      </div>
    </div>
  </section>
</section>
