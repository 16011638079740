import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription, lastValueFrom } from 'rxjs';
import XmlBeautufy from 'xml-beautify';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-modal-nfe-xml',
  templateUrl: './modal-nfe-xml.component.html',
  styleUrls: ['./modal-nfe-xml.component.scss']
})
export class ModalNfeXmlComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfe: any;
  subscription: Subscription[] = [];
  xml;
  xmlSrc: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (res.id === CONFEAR_PERMISSIONS.ABRIR_XML && res.open) {
        this.nfe = res.data;
        this.loading = true;
        this.getXml();
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.resetFields();
    this.open = false;
    this.loading = false;
  }

  async getXml() {
    //TODO
    const chave = this.nfe?.chaveNfe ? this.nfe?.chaveNfe : this.nfe.chave;
    const response = await lastValueFrom(this.nfeService.getNfeXml(chave));

    if (response?.error) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Alerta',
        detail: 'Não foi possível obter o arquivo desta NF-e',
        key: 'global-toast',
        sticky: true
      });
    } else {
      this.xml = new XmlBeautufy().beautify(response, {
        indent: '  ',
        useSelfClosingElement: true
      });
      this.open = true;
    }
    this.loading = false;
  }

  resetFields() {
    this.xml = null;
    this.loading = false;
  }

  download() {
    const file = new Blob([this.xml], { type: 'text/xml' });
    this.xmlSrc = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = this.xmlSrc;
    a.target = '_blank';
    a.download = `${this.nfe.chaveNfe}.xml` ?? `${this.nfe.chaveNfe}.xml`;
    a.click();
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
