<section
  class="container-page-wrapping"
  [class]="classList"
  [style.height]="height"
  [style.max-height]="maxHeight">
  <div *ngIf="hasTitleContainer" class="container-page-header">
    <h1 class="page-title">{{ pageTitle }}</h1>
    <div id="action-buttons">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
  <section
    class="content-page"
    [style.overflow-x]="overflowX"
    [style.overflow-y]="overflowY">
    <ng-content select="[content]"></ng-content>
    <ng-content></ng-content>
  </section>
</section>
