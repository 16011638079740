import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-certificate-contract',
  templateUrl: './modal-certificate-contract.component.html',
  styleUrls: ['./modal-certificate-contract.component.scss']
})
export class ModalCertificateContractComponent {
  @Input() open: boolean;
  @Output() closeModal = new EventEmitter();
  constructor() {}

  close() {
    this.closeModal.emit({ close: true });
  }
}
