import { Component, Input, OnInit } from '@angular/core';

import { Company } from '../../../core/models/entities/company/company';

@Component({
  selector: 'app-pdv-integration-grid-detail',
  templateUrl: './pdv-integration-grid-detail.component.html',
  styleUrls: ['./pdv-integration-grid-detail.component.scss']
})
export class PdvIntegrationGridDetailComponent {
  @Input() dataSource: Company[];
}
