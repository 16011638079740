<app-modal
  [width]="'70vw'"
  [height]="'40vw'"
  (closeModal)="close()"
  [display]="open">
  <section header>Dados do produto</section>
  <section *ngIf="productDetailed" class="container-product-analysis">
    <div class="item title">Nome do Produto:</div>
    <div class="item">{{ productDetailed?.produto }}</div>
    <div class="item title">Código do Produto:</div>
    <div class="item">{{ productDetailed?.codigoProduto }}</div>
    <div class="item title">Código NCM:</div>
    <div class="item">
      {{ productDetailed?.ncm === null ? '-' : productDetailed?.ncm }}
    </div>
    <div class="item title">Código CEST:</div>
    <div class="item">
      {{ productDetailed?.cest === null ? '-' : productDetailed?.cest }}
    </div>
    <div class="item title">Código de Benefício Fiscal na UF:</div>
    <div class="item">
      {{
        productDetailed?.codigoBeneficioFiscal === null
          ? '-'
          : productDetailed?.codigoBeneficioFiscal
      }}
    </div>
    <div class="item title">CFOP:</div>
    <div class="item">{{ productDetailed?.cfop }}</div>
    <div class="item title">Outras Despesas Acessórias (R$):</div>
    <div class="item">
      {{ productDetailed?.outrasDespesasAcessorias | currency }}
    </div>
    <div class="item title">Valor do Desconto (R$):</div>
    <div class="item">
      {{ productDetailed?.valorDesconto | currency }}
    </div>
    <div class="item title">Código EAN Comercial:</div>
    <div class="item">
      {{ productDetailed?.eanComercial }}
    </div>
    <div class="item title">Unidade Comercial:</div>
    <div class="item">
      {{ productDetailed?.unidadeComercial }}
    </div>
    <div class="item title">Quantidade Comercial:</div>
    <div class="item">
      {{ productDetailed?.qtdComercial }}
    </div>
    <div class="item title">Código Ean Tributável:</div>
    <div class="item">
      {{ productDetailed?.eanTributavel }}
    </div>
    <div class="item title">Unidade Tributável:</div>
    <div class="item">
      {{ productDetailed?.unidadeTributavel }}
    </div>
    <div class="item title">Quantidade Tributável:</div>
    <div class="item">
      {{ productDetailed?.qtdTributavel }}
    </div>
    <div class="item title">Valor unitário de comercialização (R$):</div>
    <div class="item">
      {{ productDetailed?.valorUnComercializacao | currency }}
    </div>
    <div class="item title">Valor unitário de tributação (R$):</div>
    <div class="item">
      {{ productDetailed?.valorUnTributacao | currency }}
    </div>
    <div class="item title">Número do pedido de compra:</div>
    <div class="item">
      {{
        productDetailed?.numPedidoCompra === null
          ? '-'
          : productDetailed?.numPedidoCompra
      }}
    </div>
    <div class="item title">Número da FCI:</div>
    <div class="item">
      {{ productDetailed?.numFci === null ? '-' : productDetailed?.numFci }}
    </div>

    <div class="titulo-secao"><h3>Rastro</h3></div>
    <div class="item title">Número do Lote do produto:</div>
    <div class="item">
      {{ productDetailed?.rastro?.numeroLote }}
    </div>
    <div class="item title">Quantidade de produto no Lote:</div>
    <div class="item">
      {{ productDetailed?.rastro?.qtdLote | number: '1.4-4' }}
    </div>
    <div class="item title">Data de fabricação/Produção:</div>
    <div class="item">
      {{ productDetailed?.rastro?.dataFabricacao | date: 'dd/MM/yyyy' }}
    </div>
    <div class="item title">Data de validade:</div>
    <div class="item">
      {{ productDetailed?.rastro?.dataValidade | date: 'dd/MM/yyyy' }}
    </div>

    <div class="titulo-secao"><h3>ICMS Normal e ST</h3></div>
    <div class="item title">Percentual Redução de BC do ICMS Normal:</div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.percentualReducaoBCICMSNormal
          | number: '1.4-4'
      }}%
    </div>
    <div class="item title">Base de Cálculo:</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.baseCalculo | number: '1.1-2' }}
    </div>
    <div class="item title">Alíquota:</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.aliquota | number: '1.4-4' }}%
    </div>
    <div class="item title">Valor do ICMS (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorIcms | currency }}
    </div>
    <div class="item title">Percentual da Redução de BC do ICMS ST:</div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.percentualReducaoBCICMSST
          | number: '1.4-4'
      }}%
    </div>
    <div class="item title">Valor da Base de Cálculo do FCP (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorBaseCalculoFCP | currency }}
    </div>
    <div class="item title">
      Percentual do Fundo de Combate à Pobreza (FCP):
    </div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.percentualFcp | number: '1.4-4' }}%
    </div>
    <div class="item title">
      Valor do Fundo de Combate à Pobreza (FCP) (R$):
    </div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorFcp | currency }}
    </div>
    <div class="item title">Percentual da MVA do ICMS ST:</div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.percentualMvaIcmsSt | number: '1.4-4'
      }}%
    </div>
    <div class="item title">Valor da BC do ICMS ST (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorBcIcmsSt | currency }}
    </div>
    <div class="item title">Alíquota do Imposto do ICMS ST:</div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.aliquotaImpostoICMSST | number: '1.4-4'
      }}%
    </div>
    <div class="item title">
      Valor da Base de Cálculo do FCP retido por Substituição Tributária (R$):
    </div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.valorBaseCalculoFCPRetidoST | currency
      }}
    </div>
    <div class="item title">
      Percentual do FCP retido por Substituição Tributária:
    </div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.percentualRetidoFCP | number: '1.4-4'
      }}%
    </div>
    <div class="item title">
      Valor do FCP retido por Substituição Tributária (R$):
    </div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorRetidoFCP | currency }}
    </div>
    <div class="item title">Valor do ICMS ST (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorIcmsSt | currency }}
    </div>
    <div class="item title">Valor ICMS Desonerado (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorIcmsDesonerado | currency }}
    </div>
    <div class="item title">Alíquota aplicável de cálculo do crédito:</div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.aliquotaCalculoCredito | number: '1.4-4'
      }}%
    </div>
    <div class="item title">Valor de crédito do ICMS (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorCreditoIcms | currency }}
    </div>
    <div class="item title">Valor da BC do ICMS ST retido (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorBcIcmsRetido | currency }}
    </div>
    <div class="item title">Alíquota suportada pelo Consumidor Final:</div>
    <div class="item">
      {{
        productDetailed?.icmsNormalST?.aliquotaConsumidorFinal
          | number: '1.4-4'
      }}%
    </div>
    <div class="item title">Valor do ICMS Próprio do Substituto (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorIcmsSubstituto | currency }}
    </div>
    <div class="item title">Valor do ICMS ST retido (R$):</div>
    <div class="item">
      {{ productDetailed?.icmsNormalST?.valorIcmsStRetido | currency }}
    </div>

    <div class="titulo-secao">
      <h3>Detalhamento específico dos medicamentos</h3>
    </div>
    <div class="item title">Código de Produto da ANVISA:</div>
    <div class="item">
      {{ productDetailed?.detalheMedicamentos?.codigoProdutoAnvisa }}
    </div>
    <div class="item title">Preço Máximo Consumidor (R$):</div>
    <div class="item">
      {{
        productDetailed?.detalheMedicamentos?.precoMaximoConsumidor | currency
      }}
    </div>

    <div class="titulo-secao"><h3>Informações adicionais do produto</h3></div>
    <div class="item title">Descrição:</div>
    <div class="item">
      {{ productDetailed?.informacoesAdicionais?.descricao }}
    </div>
  </section>
</app-modal>
