import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription, lastValueFrom } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { Etiqueta } from '../../../core/models/entities/etiqueta/etiqueta';
import { NfeTag } from '../../../core/models/entities/nfe-tag/nfe-tag';
import { Nfe } from '../../../core/models/entities/nfe/nfe';
import { EtiquetaService } from '../../../core/services/etiqueta/etiqueta.service';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-modal-tag-selection',
  templateUrl: './modal-tag-selection.component.html',
  styleUrls: ['./modal-tag-selection.component.scss']
})
export class ModalTagSelectionComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  nfes: Nfe[] = [];
  subscription: Subscription[] = [];
  tagDataResponse: NfeTag[];
  tagMap = new Map();
  tagList: Etiqueta[];
  form: any;
  jsonToSend: any;

  constructor(
    private modalService: ModalService,
    private etiquetaService: EtiquetaService,
    private nfeService: NfeService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService
      .listenChanges()
      .subscribe(async res => {
        if (res.id === CONFEAR_PERMISSIONS.ETIQUETAS && res.open) {
          this.open = true;
          this.loading = true;
          if (res?.data.hasOwnProperty('nfes')) {
            this.nfes = res.data?.nfes;
          }
          if (!res?.data.hasOwnProperty('nfes')) {
            this.nfes.push(res?.data);
          }

          await this.listTags();
          await this.getSelectedNfesTag();
          this.buildTagMap();
          this.filterTags();
          this.buildForm();
          this.loading = false;
        }
      });

    this.subscription.push(subscription);
  }

  async listTags() {
    const response = await lastValueFrom(this.etiquetaService.list(false));

    if (response?.dados) {
      this.tagList = response.dados;
    }
  }

  buildForm() {
    this.form = this.tagList.map(tag => {
      const value = this.getCheckboxState(tag);
      return {
        id: tag.id,
        descricao: tag.descricao,
        value
      };
    });
  }

  async getSelectedNfesTag() {
    const nfes = this.nfes.map(nfe => {
      return nfe.id;
    });
    const response = await lastValueFrom(
      this.nfeService.getNfeTags({ nfes: nfes })
    );

    if (response?.dados) {
      this.tagDataResponse = response?.dados;
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  clear() {
    this.tagMap = new Map();
    this.tagList = [];
    this.tagDataResponse = undefined as any;
    this.nfes = [];
    this.jsonToSend = undefined;
    this.form = undefined;
  }

  filterTags() {
    this.tagDataResponse.forEach(data => {
      data?.etiquetas.forEach(tag => {
        const tagValue = this.tagMap.get(tag.id);
        if (tagValue) {
          tagValue.count++;
        }
      });
    });
  }

  buildTagMap() {
    this.tagList.forEach(tag => {
      this.tagMap.set(tag.id, {
        descricao: tag.descricao,
        id: tag.id,
        count: 0
      });
    });
  }

  close() {
    this.open = false;
    this.clear();
  }

  getCheckboxState(tag: Etiqueta) {
    const tagObj = this.tagMap.get(tag.id);
    const tagCount = tagObj?.count;

    let value: any = false;

    if (tagCount > 0 && tagCount < this.nfes.length) {
      value = null;
    }

    if (tagCount === this.nfes.length) {
      value = true;
    }

    return value;
  }

  buildJsonToSend() {
    this.jsonToSend = this.tagDataResponse.map(data => {
      return {
        id: data.id,
        etiquetas: data.etiquetas.map(tag => {
          return tag.id;
        })
      };
    });
  }

  transformJson() {
    this.form.forEach(tag => {
      if (tag.value === true) {
        this.jsonToSend.forEach(nfe => {
          const hasTag = nfe.etiquetas?.find(id => {
            return tag.id === id;
          });

          if (!hasTag) {
            nfe.etiquetas.push(tag.id);
          }
        });
      } else if (tag.value === false) {
        this.jsonToSend.forEach(nfe => {
          const hasTag = nfe.etiquetas?.find(id => {
            return tag.id === id;
          });
          if (hasTag) {
            nfe.etiquetas = nfe.etiquetas?.filter(id => id !== tag.id);
          }
        });
      }
    });
  }

  async save() {
    this.buildJsonToSend();
    this.transformJson();
    const response = await lastValueFrom(
      this.nfeService.saveEtiquetas({ nfes: this.jsonToSend })
    );

    if (response?.dados) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        key: 'global-toast',
        sticky: true,
        detail: response?.dados?.mensagem
      });
      this.modalService.answer({
        id: CONFEAR_PERMISSIONS.ETIQUETAS,
        name: 'TAG_MODAL_ANSWER',
        data: {
          success: true
        }
      });
    }
    this.close();
    this.clear();
  }

  listenCheckbox(event: any, tag) {
    if (event.value === null || event.value === undefined) {
      tag.value = true;
    }
  }
}
