<app-modal (closeModal)="close()" [display]="open" [width]="'90vw'">
  <section header>Analisar NF-e</section>
  <app-loading-fragment [loading]="loading"></app-loading-fragment>
  <ng-container *ngIf="open && !messageError">
    <app-nfe-analysis
      [nfe]="nfe"
      (nfeLoaded)="listenNfeLoading($event)"></app-nfe-analysis>
  </ng-container>

  <ng-container *ngIf="messageError">
    {{ messageError }}
  </ng-container>
</app-modal>
