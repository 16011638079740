import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalCartaCorrecaoComponent } from './modal-carta-correcao.component';

@NgModule({
  declarations: [ModalCartaCorrecaoComponent],
  exports: [ModalCartaCorrecaoComponent],
  imports: [
    CommonModule,
    ModalModule,
    NgxMaskModule.forRoot(),
    LoadingFragmentModule
  ]
})
export class ModalCartaCorrecaoModule {}
