import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

import { ChangePasswordModule } from '../../core/components/change-password/change-password.module';
import { InitialSetupModule } from '../../core/components/initial-setup/initial-setup.module';
import { WelcomePageSetupModule } from '../../core/components/initial-setup/welcome-page-setup/welcome-page-setup.module';
import { LoadingPageModule } from '../../core/components/loading-page/loading-page.module';
import { MainModule } from '../../core/components/main/main.module';
import { ModalModule } from '../../core/components/modal/modal.module';
import { ErrorInterceptor } from '../../core/interceptors/error/error.interceptor';
import { JwtInterceptor } from '../../core/interceptors/jwt/jwt.interceptor';
import { AuthService } from '../../core/services/auth/auth.service';
import { LocalStorageService } from '../../core/services/local-storage/local-storage.service';
import { HomeModule } from '../home/home.module';
import { ModalCartaCorrecaoModule } from '../modals/modal-carta-correcao/modal-carta-correcao.module';
import { ModalCustomGridConfirmationModule } from '../modals/modal-custom-grid-confirmation/modal-custom-grid-confirmation.module';
import { ModalGridLegendModule } from '../modals/modal-grid-legend/modal-grid-legend.module';
import { ModalNfeAnalysisModule } from '../modals/modal-nfe-analysis/modal-nfe-analysis.module';
import { ModalNfeManifestConfirmationModule } from '../modals/modal-nfe-manifest-confirmation/modal-nfe-manifest-confirmation.module';
import { ModalNfeManifestResumeModule } from '../modals/modal-nfe-manifest-resume/modal-nfe-manifest-resume.module';
import { ModalNfeManifestationLogModule } from '../modals/modal-nfe-manifestation-log/modal-nfe-manifestation-log.module';
import { ModalNfePrintModule } from '../modals/modal-nfe-print/modal-nfe-print.module';
import { ModalNfeProductAnalysisModule } from '../modals/modal-nfe-product-analysis/modal-nfe-product-analysis.module';
import { ModalNfeXmlModule } from '../modals/modal-nfe-xml/modal-nfe-xml.module';
import { ModalProductAdvancedReportModule } from '../modals/modal-product-advanced-report/modal-product-advanced-report.module';
import { ModalSefazSituationModule } from '../modals/modal-sefaz-situation/modal-sefaz-situation.module';
import { ModalSelectMasterModule } from '../modals/modal-select-master/modal-select-master.module';
import { ModalTagSelectionModule } from '../modals/modal-tag-selection/modal-tag-selection.module';
import { ModalViewManifestationJustificationModule } from '../modals/modal-view-demonstration-justification/modal-view-manifestation-justification.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(ptBr);

const JWT_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true
};

const ERROR_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DialogModule,
    HttpClientModule,
    LoadingPageModule,
    MainModule,
    MessagesModule,
    ModalCartaCorrecaoModule,
    ModalCustomGridConfirmationModule,
    ModalGridLegendModule,
    ModalModule,
    ModalNfeAnalysisModule,
    ModalNfeManifestationLogModule,
    ModalNfeManifestConfirmationModule,
    ModalNfeManifestResumeModule,
    ModalNfePrintModule,
    ModalNfeProductAnalysisModule,
    ModalNfeXmlModule,
    ModalProductAdvancedReportModule,
    ModalSefazSituationModule,
    ModalSelectMasterModule,
    ModalTagSelectionModule,
    ModalViewManifestationJustificationModule,
    ToastModule,
    HomeModule,
    NgxGoogleAnalyticsModule.forRoot('G-RKK93S0VCM'),
    NgxGoogleAnalyticsRouterModule,
    WelcomePageSetupModule,
    InitialSetupModule,
    ChangePasswordModule
  ],
  providers: [
    JWT_INTERCEPTOR,
    ERROR_INTERCEPTOR,
    AuthService,
    LocalStorageService,
    MessageService,
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
