<app-template-login *ngIf="externalPage" [title]="title">
  <app-form-change-password
    [chaveExpirada]="chaveExpirada"
    [email]="dadosChaveValidada?.email"
    [login]="dadosChaveValidada?.login"
    (formChanges)="submit($event)"></app-form-change-password>
</app-template-login>
<p-toast position="top-right"></p-toast>

<div
  *ngIf="!externalPage"
  class="container-change-passwod-inside grid grid-nogutter col-6">
  <h1 class="form-title md:col-9 col-10 md:mt-4 mt-4 pl-0 mb-2">
    {{ title }}
  </h1>
  <app-form-change-password
    [externalPage]="externalPage"
    (formChanges)="submit($event)"></app-form-change-password>
</div>
