import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DefaultResponse } from '../../models/responses/default-response';
import { RelatorioAvancadoProduto } from '../../models/responses/relatorio-avancado-produto/relatorio-avancado-produto';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private url = `${environment.baseUrl}/relatorios`;

  constructor(private http: HttpClient) {}

  getAdvancedByProduct(
    nfes: number[]
  ): Observable<DefaultResponse<RelatorioAvancadoProduto>> {
    return this.http
      .post<DefaultResponse<RelatorioAvancadoProduto>>(
        `${this.url}/avancado-produtos`,
        { nfes }
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }

  getConsolidated(obj): Observable<any> {
    return this.http
      .get<any>(
        `${this.url}/consolidado?dataInicio=${obj.dataInicio}&dataFim=${obj.dataFim}`
      )
      .pipe(
        catchError(err => {
          return handleResponseError(err);
        })
      );
  }
}
