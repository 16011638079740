<app-modal
  modalTitle="Resumo da manifestação"
  (closeModal)="close()"
  [display]="open">
  <section class="container-modal-nfe-resume">
    <p-accordion [multiple]="true">
      <p-accordionTab [header]="totalNfeSelecionadasLabel">
        <span class="word-break">
          {{ nfes }}
        </span>
      </p-accordionTab>
      <p-accordionTab [header]="nfeManifestadasLabel">
        <section class="mb-2" *ngFor="let nfe of data?.nfeManifestadas">
          <div>Número NF-e: {{ nfe.numero }}</div>
          <div>Status NF-e: {{ nfe.status }}</div>
        </section>
      </p-accordionTab>
      <p-accordionTab [header]="nfeStatusModificadoLabel">
        <section class="mb-2" *ngFor="let nfe of data?.nfeStatusModificado">
          <div>Número NF-e: {{ nfe.numero }}</div>
          <div>Status NF-e: {{ nfe.status }}</div>
        </section>
      </p-accordionTab>
      <p-accordionTab [header]="nfeNaoManifestadasLabel">
        <section class="mb-2" *ngFor="let nfe of data?.nfeNaoManifestadas">
          <div>Número NF-e: {{ nfe.numero }}</div>
          <div>Status NF-e: {{ nfe.status }}</div>
        </section>
      </p-accordionTab>
      <p-accordionTab [header]="nfeIgnoradasLabel">
        <section class="mb-2" *ngFor="let nfe of data?.nfeIgnoradas">
          <div>Número NF-e: {{ nfe.numero }}</div>
          <div>Status NF-e: {{ nfe.status }}</div>
        </section>
      </p-accordionTab>
    </p-accordion>

    <div class="container-comments mt-2">
      <h3 class="mb-2">Observações:</h3>
      <span class="mb-2">
        - As NF-e com apenas o status de manifestação modificado referem-se às
        notas onde a manifestação selecionada já foi realizada anteriormente.
      </span>
      <span class="mb-2">
        - As NF-e não manifestadas referem-se às notas que apresentaram erro na
        manifestação. Geralmente ocorre por falta de conectividade com a SEFAZ.
      </span>
      <span class="mb-2">
        - As NF-e ignoradas referem-se às notas que já foram manifestadas
        anteriormente ou que foram recebidas por e-mail (através do Receptor).
      </span>
    </div>
  </section>

  <ng-template pTemplate="footer">
    <button (click)="close()" class="p-button btn-blue">Fechar</button>
  </ng-template>
</app-modal>
