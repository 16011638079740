import { GridOptionsEnum } from '../enums/grid-options.enum';

export const GRID_OPTIONS = [
  {
    value: GridOptionsEnum.REDUZIDA,
    name: 'Grid Reduzida',
    icon: 'contentlayout'
  },
  {
    value: GridOptionsEnum.COMPLETA,
    name: 'Grid Completa',
    icon: 'smalliconslayout'
  },
  {
    value: GridOptionsEnum.PERSONALIZADA,
    name: 'Grid Personalizada',
    icon: 'mediumiconslayout'
  }
];
