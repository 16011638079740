<section class="grid grid-nogutter container-basic-info">
  <div class="info">
    <b>Destinatário:&nbsp;</b>
    <span>{{ data?.dados?.destinatario }}</span>
  </div>
  <div class="info">
    <b>Data Emissão:&nbsp;</b>
    <span aria-describedby="Data Emissão">
      {{ data?.dados?.dataEmissao | date: 'dd/MM/yyyy HH:mm:ss' }}
    </span>
  </div>
  <div class="info">
    <b>Fornecedor:&nbsp;</b>
    <span>{{ data?.dados?.fornecedor }}</span>
  </div>
  <div class="info">
    <b>Número da NFe:&nbsp;</b>
    <span>{{ data?.dados?.numeroNfe }}</span>
  </div>
  <div class="info">
    <b>Interestadual:&nbsp;</b>
    <span>
      {{ data?.dados?.interestadual === true ? 'SIM' : 'NÃO' }} ({{
        data?.dados?.ufEmitente
      }})
    </span>
  </div>
  <div class="info warning-container">
    <span class="material-icons-outlined warning-icon">warning</span>
    <span>{{ data?.dados?.aviso }}</span>
  </div>
  <div
    class="info warning-container"
    *ngIf="
      data?.dados?.ufEmitente === 'RJ' && data?.dados?.ufDestinatario === 'RJ'
    ">
    <span class="material-icons-outlined warning-icon">warning</span>
    <span>
      Em alguns distribuidores o repasse pode estar incluso no desconto
      apresentado.
    </span>
  </div>
</section>
<app-page-wrapping
  overflowX="hidden"
  [hasTitleContainer]="false"
  [pageTitle]="pageTitle">
  <section content class="container-nfe-analysis">
    <section class="container-home">
      <dx-data-grid
        #dataGrid
        class="grid-container"
        id="grid-nfe-analysis"
        (onOptionChanged)="listenGridChanges($event)"
        (onCellPrepared)="onCellPrepared($event)"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [dataSource]="dataSource"
        [remoteOperations]="false"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        (onRowDblClick)="openProductModal($event)"
        [showBorders]="true">
        <dxo-search-panel
          [width]="240"
          [visible]="true"
          [highlightCaseSensitive]="true"></dxo-search-panel>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-group-panel
          emptyPanelText="Arraste uma coluna para agrupar"
          [visible]="true"></dxo-group-panel>
        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-pager
          [visible]="false"
          [showPageSizeSelector]="true"
          [showInfo]="true"
          [showNavigationButtons]="true"></dxo-pager>
        <dxo-export [enabled]="true"></dxo-export>

        <dxo-column-chooser
          sortOrder="asc"
          mode="select"
          title="Personalizar Colunas"
          [enabled]="true"></dxo-column-chooser>

        <dxo-toolbar>
          <dxi-item location="after" name="legend">
            <div
              (click)="openModalCostLegend()"
              class="btn-cost-legend"
              pTooltip="Clique para visualizar a legenda da classificação de compra.">
              <span class="material-icons-outlined">subtitles</span>
              <span>Legenda</span>
            </div>
          </dxi-item>
          <dxi-item location="after" name="searchPanel"></dxi-item>

          <dxi-item
            location="after"
            title="Exportar Dados"
            id="dropdown-export">
            <dx-drop-down-button
              [dropDownOptions]="{ width: 150 }"
              icon="download"
              displayExpr="name"
              [items]="exportOptions"
              (onItemClick)="
                export($event, 'confear-analise-nfe')
              "></dx-drop-down-button>
          </dxi-item>

          <dxi-item
            location="after"
            title="Escolha o tipo de grid"
            text="Editar grid"
            [visible]="true">
            <dx-drop-down-button
              [elementAttr]="{ id: 'btn-select-grid' }"
              icon="columnfield"
              displayExpr="name"
              keyExpr="value"
              class="btn-dropdown-grid"
              [width]="200"
              [useSelectMode]="true"
              [selectedItemKey]="selectedGridOption"
              [items]="gridOptions"
              (onSelectionChanged)="
                selectGridOption($event)
              "></dx-drop-down-button>
          </dxi-item>

          <dxi-item location="after" name="save">
            <button
              [disabled]="disableSaveGrid"
              (click)="handleCustomGrid()"
              class="btn-save-grid"
              title="Salvar Personalização da Grid"
              pButton
              type="button"
              icon="pi pi-save"
              iconPos="left"></button>
          </dxi-item>

          <dxi-item location="after" name="delete">
            <button
              [disabled]="disableDeleteGrid"
              (click)="confirmResetGridConfig()"
              class="btn-delete-grid"
              title="Excluir Personalização da Grid"
              pButton
              type="button">
              <span class="material-icons-outlined">grid_off</span>
            </button>
          </dxi-item>

          <dxi-item name="columnChooserButton" location="after"></dxi-item>
          <dxi-item location="before" name="groupPanel"></dxi-item>
        </dxo-toolbar>

        <dxi-column
          [allowSearch]="true"
          [width]="57"
          alignment="left"
          caption="ITEM"
          dataField="item"
          [showInColumnChooser]="false"
          cellTemplate="cellTemplateItem"></dxi-column>
        <div class="text-center" *dxTemplate="let data of 'cellTemplateItem'">
          {{ data.value }}
        </div>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="270"
          alignment="left"
          caption="PRODUTO"
          [showInColumnChooser]="false"
          dataField="produto"
          cellTemplate="cellTemplateProduto"></dxi-column>
        <div
          class="flex align-items-start"
          *dxTemplate="let data of 'cellTemplateProduto'">
          <em
            (click)="openProductModal(data)"
            class="pi pi-eye eye-icon"
            title="Detalhes do produto"></em>
          {{ data.value }}
        </div>
        <dxi-column
          [allowSearch]="true"
          alignment="center"
          [minWidth]="90"
          caption="DESC. (%)"
          [customizeText]="percentFormat"
          dataField="descontoPercentual"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="100"
          alignment="right"
          caption="CUSTO UNITÁRIO"
          dataField="custoUnitario"
          cellTemplate="cellTemplateCustoUnitario"></dxi-column>
        <div
          class="align-items-end"
          *dxTemplate="let data of 'cellTemplateCustoUnitario'">
          {{ data.value | currency }}
        </div>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="100"
          alignment="right"
          caption="DESC. (-)"
          dataField="descontovalor"
          cellTemplate="cellTemplateDescontoValor"></dxi-column>
        <div
          class="align-items-end"
          *dxTemplate="let data of 'cellTemplateDescontoValor'">
          <span class="cell-discount">{{ data.value | currency }}</span>
        </div>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="70"
          alignment="right"
          caption="ST/FCP (+)"
          dataField="stFcp"
          cellTemplate="cellTemplateSTFCP"></dxi-column>
        <div
          class="align-items-end"
          *dxTemplate="let data of 'cellTemplateSTFCP'">
          {{ data.value | currency }}
        </div>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="70"
          alignment="right"
          caption="IPI (+)"
          dataField="ipi"
          cellTemplate="cellTemplateIPI"></dxi-column>
        <div
          class="align-items-end"
          *dxTemplate="let data of 'cellTemplateIPI'">
          {{ data.value | currency }}
        </div>

        <dxi-column
          [allowSearch]="true"
          [width]="'87px'"
          alignment="right"
          caption="OUTRAS DESP. (+)"
          dataField="outrasDespesas"
          cellTemplate="cellTemplateOutrasDespesas"></dxi-column>
        <div
          class="align-items-end"
          *dxTemplate="let data of 'cellTemplateOutrasDespesas'">
          {{ data.value | currency }}
        </div>
        <dxi-column
          [minWidth]="120"
          alignment="center"
          dataField="classificacaoCompra.descricao"
          dataType="string"
          caption="CLASS. COMPRA"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="90"
          [allowFiltering]="false"
          alignment="center"
          dataField="custoReal"
          dataType="string"
          cellTemplate="templateCustoReal"
          caption="CUSTO REAL"></dxi-column>
        <div
          #custoRealColumn
          (mouseenter)="toggleCustoReal(row)"
          (mouseleave)="toggleCustoReal(row)"
          *dxTemplate="let row of 'templateCustoReal'">
          <b>
            {{ row?.data?.custoReal | currency: 'BRL':'symbol':'0.2-2' }}
          </b>
          <dx-tooltip
            *ngIf="currentTooltip === row?.data?.idGrid"
            [target]="custoRealColumn"
            [(visible)]="showToggleCustoReal"
            [hideOnOutsideClick]="false">
            {{ row?.data?.custoReal | custoReal: row?.data?.valorMedio }}
          </dx-tooltip>
        </div>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="70"
          [allowFiltering]="false"
          alignment="right"
          dataField="valorMedio"
          caption="VALOR MÉDIO"
          headerCellTemplate="titleHeaderTemplate"
          [format]="formatCurrency"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="80"
          [allowFiltering]="false"
          alignment="right"
          dataField="precoFabrica"
          caption="PREÇO FÁBRICA"
          headerCellTemplate="titleHeaderTemplate"
          [format]="formatCurrency"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="110"
          alignment="center"
          caption="QTD. COMPRADA"
          dataField="qtdComprada">
          <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
        </dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="150"
          alignment="right"
          caption="CUSTO TOTAL DOS PRODUTOS"
          dataField="custoTotalProdutos"
          cellTemplate="cellTemplateCustoTotalProdutos"></dxi-column>
        <div *dxTemplate="let data of 'cellTemplateCustoTotalProdutos'">
          <b>{{ data.value | currency }}</b>
        </div>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="130"
          alignment="center"
          dataField="tipoProduto"
          caption="TIPO PRODUTO"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="250"
          alignment="left"
          dataField="fabricante"
          caption="FABRICANTE">
          <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
        </dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="80"
          alignment="center"
          caption="CFOP"
          dataField="cfop"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="90"
          alignment="center"
          caption="O/CST"
          dataField="ocst"></dxi-column>
        <dxi-column
          [allowSearch]="true"
          [minWidth]="100"
          alignment="center"
          caption="NCM"
          dataField="ncm"></dxi-column>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="100"
          alignment="center"
          caption="CEST"
          dataField="cest"></dxi-column>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="150"
          alignment="center"
          caption="EAN"
          dataField="ean"></dxi-column>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="150"
          alignment="center"
          caption="REDUTOR DE ENTRADA"
          dataField="redutorEntrada"></dxi-column>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="150"
          alignment="center"
          caption="REDUTOR DE SAÍDA"
          dataField="redutorSaida"></dxi-column>

        <dxi-column
          [allowSearch]="true"
          [minWidth]="100"
          alignment="center"
          caption="LPNN"
          dataField="lpnn"></dxi-column>
        <div
          class="custom-header"
          *dxTemplate="let info of 'titleHeaderTemplate'">
          {{ info.column.caption }}
        </div>
      </dx-data-grid>
    </section>
  </section>
</app-page-wrapping>
<div class="coast-alert mt-2" [class]="getClassCoastAlert()">
  O custo total da NF-e foi {{ getCoastAlertMessage() }} a média praticada pelo
  mercado.
</div>
<div class="container-footer">
  <section class="container-detail-info">
    <div class="info">
      <span><b>Total produtos:</b></span>
      <span>
        {{ data?.dados?.totalizadores?.valorTotalProdutos | currency }}
      </span>
    </div>
    <div class="info">
      <span><b>Outras despesas (+):</b></span>
      <span>
        {{ data?.dados?.totalizadores?.outrasDespesas | currency }}
      </span>
    </div>
    <div class="info">
      <span><b>Descontos (-):</b></span>
      <span>{{ data?.dados?.totalizadores?.descontos | currency }}</span>
    </div>
    <div class="info">
      <span><b>IPI + Frete (+):</b></span>
      <span>{{ data?.dados?.totalizadores?.ipiFrete | currency }}</span>
    </div>
    <div class="info">
      <span><b>ST (+):</b></span>
      <span>{{ data?.dados?.totalizadores?.st | currency }}</span>
    </div>
    <div class="info">
      <span><b>Total Unid. Comprada:</b></span>
      <span>
        {{ data?.dados?.totalizadores?.totalUnidComprada | number: '1.2-2' }}
      </span>
    </div>
    <div class="info">
      <span><b>FCP (+):</b></span>
      <span>{{ data?.dados?.totalizadores?.fcp | currency }}</span>
    </div>
    <div class="info">
      <span><b>Total na Nota:</b></span>
      <span>{{ data?.dados?.totalizadores?.totalNota | currency }}</span>
    </div>
  </section>
  <section class="container-legend">
    <span>*ST: Substituição Tributária</span>
    <span>*FCP: Fundo de Combate à Pobreza</span>
  </section>
</div>
<app-modal-cost-legend
  (closeModal)="closeModalCostLegend()"
  [open]="showModalCostLegend"></app-modal-cost-legend>
