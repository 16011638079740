import { Component, Input, OnInit } from '@angular/core';

import { Step } from '../../../core/models/interfaceObjects/step/step';
import { AuthService } from '../../../core/services/auth/auth.service';
import { StepService } from '../../../core/services/step/step.service';
import { FinishedUserUpdateComponent } from './finished-user-update/finished-user-update.component';
import { FormCodeValidationComponent } from './forms/form-code-validation/form-code-validation.component';
import { FormDataUpdateComponent } from './forms/form-data-update/form-data-update.component';
import { FormEmailValidationComponent } from './forms/form-email-validation/form-email-validation.component';

@Component({
  selector: 'app-modal-user-register-update',
  templateUrl: './modal-user-register-update.component.html',
  styleUrls: ['./modal-user-register-update.component.scss']
})
export class ModalUserRegisterUpdateComponent implements OnInit {
  @Input() display: boolean;
  steps: Step[] = [
    {
      id: 1,
      label: 'Atualizar e-mail',
      isCurrent: true,
      isLast: false,
      isFirst: true,
      isVisited: false,
      next: false,
      previous: false,
      component: FormEmailValidationComponent,
      order: 1
    },
    {
      id: 2,
      label: 'Verificar código',
      isCurrent: false,
      isLast: false,
      isFirst: false,
      isVisited: false,
      next: false,
      previous: false,
      component: FormCodeValidationComponent,
      order: 2
    },
    {
      id: 3,
      label: 'Atualizar dados',
      isCurrent: false,
      isLast: false,
      isFirst: false,
      isVisited: false,
      next: false,
      previous: false,
      component: FormDataUpdateComponent,
      order: 3
    },
    {
      id: 4,
      label: 'Confirmação',
      isCurrent: false,
      isLast: true,
      isFirst: false,
      isVisited: false,
      next: false,
      previous: false,
      component: FinishedUserUpdateComponent,
      order: 4
    }
  ];

  current: Step;
  currentLogin: string;

  constructor(
    private stepService: StepService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const { login } = this.authService.getUserValue();
    this.currentLogin = login;
    this.stepService.steps = this.steps;
    this.stepService.current = this.steps[0];
    this.stepService.listenCurrent().subscribe(current => {
      this.current = current;
    });
  }
}
