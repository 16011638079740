import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DefaultResponse } from '../../models/responses/default-response';
import { handleResponseError } from '../../utils/handleResponseError';

@Injectable({
  providedIn: 'root'
})
export class PluginService {
  private url = `${environment.baseUrl}/plugin`;

  constructor(private http: HttpClient) {}

  download(chaveNfe: string): Observable<DefaultResponse<{ url: string }>> {
    return this.http.get(`${this.url}/${chaveNfe}/download`, {}).pipe(
      catchError(err => {
        return handleResponseError(err);
      })
    );
  }
}
