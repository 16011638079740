import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ModalAnswer } from '../../models/interfaceObjects/modal/modal-answer';
import { ModalOptions } from '../../models/interfaceObjects/modal/modal-options';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private subject: BehaviorSubject<ModalOptions> =
    new BehaviorSubject<ModalOptions>({ id: 0, open: false, data: null });
  private answerSubject: BehaviorSubject<ModalAnswer> =
    new BehaviorSubject<ModalAnswer>({ id: 0, data: null });

  constructor() {}

  open = (options: ModalOptions) => this.subject.next(options);

  listenChanges = (): Observable<ModalOptions> => this.subject.asObservable();

  answer = (answer: ModalAnswer) => this.answerSubject.next(answer);

  listenAnswer = (): Observable<ModalAnswer> =>
    this.answerSubject.asObservable();

  clear() {
    this.answerSubject.next({ id: 0, data: null });
    this.subject.next({ id: 0, open: false, data: null });
  }
}
