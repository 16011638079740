<section class="container-pdv-grid-companies">
  <dx-data-grid
    #dataGrid
    class="grid-container"
    id="grid-company"
    keyExpr="id"
    (onOptionChanged)="listenSelectedRows($event)"
    [hoverStateEnabled]="true"
    [columnMinWidth]="100"
    [allowColumnResizing]="true"
    [dataSource]="companies"
    [selectedRowKeys]="selectedCompanies"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="true">
    <dxo-search-panel
      [width]="240"
      [visible]="true"
      [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-selection [mode]="mode"></dxo-selection>
    <dxo-group-panel
      emptyPanelText="Arraste uma coluna para agrupar"
      [visible]="true"></dxo-group-panel>
    <dxo-toolbar>
      <dxi-item name="searchPanel" location="after"></dxi-item>
      <dxi-item name="groupPanel"></dxi-item>
    </dxo-toolbar>
    <dxi-column
      dataType="string"
      alignment="left"
      caption="CNPJ"
      [allowSearch]="true"
      [calculateCellValue]="cnpjTransform"
      [allowFiltering]="false"
      dataField="cnpj"></dxi-column>
    <dxi-column
      [visible]="false"
      editorOptions
      dataType="string"
      alignment="center"
      caption="CNPJ COM MÁSCARA"
      dataField="cnpjMask">
      <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
    </dxi-column>
    <dxi-column
      [allowFiltering]="false"
      [allowSearch]="false"
      dataType="string"
      alignment="left"
      caption="RAZÃO SOCIAL"
      dataField="razaoSocial"></dxi-column>
    <dxi-column
      [allowFiltering]="false"
      [allowSearch]="false"
      dataType="string"
      alignment="left"
      caption="NOME FANTASIA"
      dataField="nomeFantasia"></dxi-column>
    <dxi-column
      [width]="60"
      [allowFiltering]="false"
      [allowSearch]="false"
      dataType="string"
      alignment="center"
      caption="UF"
      dataField="uf"></dxi-column>
    <dxi-column
      [width]="120"
      dataType="string"
      alignment="center"
      caption="SOFT. DE PDV"
      dataField="reponsavel"
      cellTemplate="softwarePdvTemplate"></dxi-column>
    <div *dxTemplate="let row of 'softwarePdvTemplate'">
      {{ row?.data?.softwarePdv?.descricao }}
    </div>
    <dxo-paging [pageSize]="10"></dxo-paging>
  </dx-data-grid>
</section>
