export const DOWNLOAD_OPTIONS = [
  {
    value: 1,
    name: 'Baixar PDF',
    icon: 'download',
    downloadPdf: true,
    downloadXml: false
  },
  {
    value: 2,
    name: 'Baixar XML',
    icon: 'download',
    downloadPdf: false,
    downloadXml: true
  },
  {
    value: 3,
    name: 'Baixar PDF + XML',
    icon: 'download',
    downloadPdf: true,
    downloadXml: true
  }
];
