import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustoRealPipe } from './custo-real.pipe';

@NgModule({
  declarations: [CustoRealPipe],
  imports: [CommonModule],
  exports: [CustoRealPipe]
})
export class CustoRealModule {}
