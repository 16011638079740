<section id="container-topbar-setup">
  <img id="img-logo-topbar" src="assets/logos/logo-confear-branco.png" alt="" />
  <div class="env-tag" *ngIf="env.test">{{ env.name }}</div>
  <div class="container-right">
    <section class="container-top-links">
      <div class="top-link mr-2" (click)="openVideo()">
        <span class="material-icons-outlined">live_tv</span>
        VÍDEO TUTORIAL
      </div>
      <div class="top-link mr-2">
        <span class="material-icons-outlined">help</span>
        COMO USAR ESSA TELA?
      </div>
    </section>
    <section class="container-user-info">
      <div id="vertical-line"></div>

      <div id="container-user-info-detail" #container>
        <em class="pi pi-user" (click)="userMenu.toggle($event)"></em>
        <div id="container-company-email">
          <p id="company-name" (click)="userMenu.toggle($event)">
            {{ user?.nome?.toUpperCase() }}
          </p>
          <p id="email" (click)="userMenu.toggle($event)">
            Login: {{ user?.login?.toLowerCase() }}
          </p>
          <p id="master">
            Master: {{ masterLogged?.nome | titlecase }} (Cód:
            {{ masterLogged?.id }})
          </p>
        </div>
        <em class="material-icons-outlined" (click)="userMenu.toggle($event)">
          arrow_drop_down
        </em>
        <p-tieredMenu
          [appendTo]="container"
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          styleClass="menu-user-info"
          #userMenu
          [model]="itemsUserMenu"
          [popup]="true"></p-tieredMenu>
      </div>
    </section>
  </div>
</section>
<app-modal-video-setup
  (responseModal)="listenModalVideoSetup($event)"
  [open]="openVideoTutorial"></app-modal-video-setup>
