import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalNfeManifestConfirmationComponent } from './modal-nfe-manifest-confirmation.component';

@NgModule({
  declarations: [ModalNfeManifestConfirmationComponent],
  exports: [ModalNfeManifestConfirmationComponent],
  imports: [
    CommonModule,
    ModalModule,
    InputTextareaModule,
    ButtonModule,
    ReactiveFormsModule
  ]
})
export class ModalNfeManifestConfirmationModule {}
