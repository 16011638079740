import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { BaseGridComponent } from '../../core/components/base-grid/base-grid';
import { CONFEAR_PERMISSIONS } from '../../core/consts/CONFEAR-PERMISSIONS';
import { DefaultResponse } from '../../core/models/responses/default-response';
import { RelatorioAvancadoProduto } from '../../core/models/responses/relatorio-avancado-produto/relatorio-avancado-produto';
import { AuthService } from '../../core/services/auth/auth.service';
import { LoadingService } from '../../core/services/loading/loading.service';
import { ModalService } from '../../core/services/modal/modal.service';
import { NfeService } from '../../core/services/nfe/nfe.service';
import { ReportService } from '../../core/services/report/report.service';

@Component({
  selector: 'app-grid-product-advanced-report',
  templateUrl: './grid-product-advanced-report.component.html',
  styleUrls: ['./grid-product-advanced-report.component.scss']
})
export class GridProductAdvancedReportComponent
  extends BaseGridComponent<RelatorioAvancadoProduto>
  implements OnChanges, OnInit
{
  @Input() pageTitle: string;
  @Input() nfes;
  @Output() dataLoading = new EventEmitter<boolean>();
  @Input() getData: boolean;
  override data: DefaultResponse<RelatorioAvancadoProduto>;

  constructor(
    protected override nfeService: NfeService,
    protected override authService: AuthService,
    protected override modalService: ModalService,
    protected override messageService: MessageService,
    protected override loadingService: LoadingService,
    protected reportService: ReportService
  ) {
    super(
      nfeService,
      authService,
      modalService,
      messageService,
      loadingService
    );
  }

  async ngOnInit() {
    this.gridPermission = CONFEAR_PERMISSIONS.RELATORIO_AVANCADO_PRODUTO;
    await this.loadInitalConfigs();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.nfes?.length) {
      await this.getReport();
    }
  }

  async getReport() {
    const response = await lastValueFrom(
      this.reportService.getAdvancedByProduct(this.nfes)
    );
    if (response?.dados) {
      this.data = response;
      this.dataSource = response.dados;
    }
    this.dataLoading.emit(true);
  }
}
