import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-fragment',
  templateUrl: './loading-fragment.component.html',
  styleUrls: ['./loading-fragment.component.scss']
})
export class LoadingFragmentComponent {
  @Input() loading: boolean;
  @Input() height;
  constructor() {}
}
