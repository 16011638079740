import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { ModalService } from '../../../core/services/modal/modal.service';

@Component({
  selector: 'app-modal-product-advanced-report',
  templateUrl: './modal-product-advanced-report.component.html',
  styleUrls: ['./modal-product-advanced-report.component.scss']
})
export class ModalProductAdvancedReportComponent implements OnInit, OnDestroy {
  open: boolean;
  loading: boolean;
  data: any;
  nfes: string[];
  subscription: Subscription[] = [];

  constructor(
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.RELATORIO_AVANCADO_PRODUTO &&
        res.open
      ) {
        this.resetField(res.data);
        if (res?.data?.selectedRows?.length) {
          this.loading = true;
          this.open = true;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Atenção',
            key: 'global-toast',
            sticky: true,
            detail: 'Por favor, selecione pelo menos uma nota fiscal.'
          });
        }
      }
    });

    this.subscription.push(subscription);
  }

  resetField(data) {
    this.data = undefined;
    setTimeout(() => {
      this.data = data;
      if (this.data?.selectedRows?.length) {
        this.nfes = this.data?.selectedRows.map(nfe => {
          return nfe?.id?.toString();
        });
      }
    }, 1000);
  }

  close() {
    this.loading = false;
    this.open = false;
  }

  listenDataLoading(loaded: boolean) {
    this.loading = false;
    this.open = loaded;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  listenData(event: any) {}
}
