<app-modal
  modalTitle="Gerenciar empresas do servidor"
  [modalFull]="true"
  (closeModal)="close({ cancel: true })"
  [display]="open">
  <section class="container-modal-attach-company">
    <div class="server-info font-bold mb-3">
      <span>Nome do servidor: {{ server?.nome }}</span>
      <span>Chave: {{ server?.chaveDownload }}</span>
      <span>Software de PDV: {{ server?.softwarePdv?.descricao }}</span>
    </div>
    <div class="default-border-page">
      <app-grid-pdv-companies
        [selectedCompanies]="selectedCompanies"
        (selectedRows)="listenSelectedCompanies($event)"
        [companies]="companies"></app-grid-pdv-companies>
    </div>
  </section>
  <section class="container-footer-modal" footer>
    <button
      pButton
      title="Cancelar"
      (click)="close({ cancel: true })"
      class="btn-dark-red"
      type="button"
      label="Cancelar"
      icon="pi pi-times"></button>
    <button
      [disabled]="!selectedCompanies?.length"
      pButton
      (click)="attach()"
      title="Salvar Alterações"
      class="btn-green mr-2"
      type="button"
      label="Salvar Alterações"
      icon="pi pi-check"></button>
  </section>
</app-modal>
