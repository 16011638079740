import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { InitialSetupService } from '../../../services/initial-setup/initial-setup.service';

@Component({
  selector: 'app-welcome-page-setup',
  templateUrl: './welcome-page-setup.component.html',
  styleUrls: ['./welcome-page-setup.component.scss']
})
export class WelcomePageSetupComponent {
  stepBoxes = [
    {
      label: 'alterar senha',
      icon: 'lock_reset'
    },
    {
      label: 'configurar empresas',
      icon: 'domain'
    },
    {
      label: 'configurar certificado digital',
      icon: 'verified_user'
    },
    {
      label: 'configurar integração com pdv',
      icon: 'reset_tv'
    }
  ];

  constructor(
    private initialSetupService: InitialSetupService,
    private router: Router
  ) {}

  start() {
    this.router.navigate(['passo-a-passo-setup']);
  }
}
