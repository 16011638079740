<app-page-wrapping [pageTitle]="pageTitle">
  <section content class="container-home">
    <dx-data-grid
      class="grid-container"
      id="gridContainer"
      [hoverStateEnabled]="true"
      [columnMinWidth]="100"
      [allowColumnResizing]="true"
      [dataSource]="dataSource"
      [remoteOperations]="false"
      [allowColumnReordering]="true"
      [rowAlternationEnabled]="true"
      [showBorders]="true">
      <dxo-search-panel
        [width]="240"
        [visible]="true"
        [highlightCaseSensitive]="true"></dxo-search-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-group-panel
        emptyPanelText="Arraste uma coluna para agrupar"
        [visible]="true"></dxo-group-panel>
      <dxo-toolbar>
        <dxi-item name="searchPanel" location="after"></dxi-item>
        <dxi-item title="Atualiza lista" text="Refresh" [visible]="true">
          <dx-button icon="refresh" (onClick)="refreshDataGrid()"></dx-button>
        </dxi-item>
        <dxi-item name="groupPanel"></dxi-item>
      </dxo-toolbar>
      <dxi-column
        [allowSearch]="false"
        [width]="100"
        cssClass="container-icon-status"
        alignment="center"
        [allowFiltering]="false"
        [allowSorting]="false"
        [allowGrouping]="false"
        caption="VALIDAR"
        dataType="number"
        dataField="status"
        cellTemplate="statusTemplate"></dxi-column>
      <div *dxTemplate="let cell of 'statusTemplate'">
        <span
          (click)="openModalValidate(cell?.data)"
          class="material-icons-outlined certificate-icon">
          add_moderator
        </span>
      </div>
      <dxi-column
        [width]="150"
        dataType="string"
        alignment="center"
        [allowFiltering]="false"
        [allowSorting]="false"
        [allowGrouping]="false"
        [allowSearch]="true"
        caption="CNPJ"
        [calculateCellValue]="cnpjTransform"
        dataField="cnpj"
        cellTemplate="cnpjTemplate">
        <dxo-header-filter [allowSearch]="false"></dxo-header-filter>
      </dxi-column>
      <dxi-column
        [visible]="false"
        editorOptions
        dataType="string"
        alignment="center"
        caption="CNPJ COM MÁSCARA"
        dataField="cnpjMask">
        <dxo-header-filter [allowSearch]="true"></dxo-header-filter>
      </dxi-column>
      <dxi-column
        [width]="80"
        dataType="string"
        alignment="center"
        [allowFiltering]="false"
        [allowSorting]="false"
        [allowGrouping]="false"
        [allowSearch]="false"
        caption="ATIVA / INATIVA"
        dataField="indAtivo"
        cellTemplate="activeTemplate"></dxi-column>
      <div class="container-active" *dxTemplate="let cell of 'activeTemplate'">
        <span [class.text-red]="cell?.data?.indAtivo === false">
          {{ cell?.data?.indAtivo ? 'Ativa' : 'Inativa' }}
        </span>
      </div>
      <div
        [class.text-red]="cell?.data?.diasExpiracao <= 30"
        [class.font-bold]="cell?.data?.diasExpiracao <= 0"
        *dxTemplate="let cell of 'cnpjTemplate'">
        {{ cell.value | mask: '00.000.000/0000-00' }}
      </div>
      <dxi-column
        [width]="120"
        dataType="string"
        alignment="center"
        caption="MATRIZ/FILIAL"
        dataField="filial"
        cellTemplate="matrizTemplate"></dxi-column>
      <div
        [class.text-red]="cell.value === '0001'"
        *dxTemplate="let cell of 'matrizTemplate'">
        {{ cell.value === '0001' ? 'Matriz' : 'Filial' }}
      </div>
      <dxi-column
        dataType="string"
        alignment="left"
        caption="RAZÃO SOCIAL"
        dataField="razaoSocial"
        cellTemplate="empresaTemplate"></dxi-column>
      <div
        *dxTemplate="let data of 'empresaTemplate'"
        [title]="
          'Em operação desde ' +
          (data?.data?.dataIntegracaoCertificado | date: 'dd/MM/yyyy HH:mm')
        ">
        {{ data?.value }}
      </div>
      <dxi-column
        caption="VALIDADE"
        dataType="date"
        dataField="dataValidade"
        cellTemplate="progressBarTemplate"></dxi-column>
      <div *dxTemplate="let data of 'progressBarTemplate'">
        <div class="container-progressbar">
          <dx-progress-bar
            [class.progressbar-blue]="
              data?.data.progress >= 0 && data?.data.progress < 30
            "
            [class.progressbar-green]="
              data?.data.progress >= 30 && data?.data.progress < 60
            "
            [class.progressbar-yellow]="
              data?.data.progress >= 60 && data?.data.progress < 91
            "
            [statusFormat]="formatBarLabel"
            [class.progressbar-red]="data?.data.progress >= 91"
            [class.complete]="data?.data.progress === 100"
            [min]="0"
            [max]="100"
            [value]="data?.data.progress"></dx-progress-bar>
          <span class="label-progressbar">
            {{ formatLabelProgressbar(data) }}
          </span>
        </div>
      </div>
      <dxi-column
        [width]="170"
        [allowFiltering]="false"
        [allowSorting]="false"
        [allowGrouping]="false"
        [allowSearch]="false"
        dataType="string"
        alignment="center"
        caption="ADQUIRIR"
        dataField="options"
        cellTemplate="options"></dxi-column>
      <div *dxTemplate="let cell of 'options'">
        <button
          (click)="newCertificate()"
          pButton
          class="btn-green"
          title="Adquirir novo certificado"
          type="button"
          label="novo certificado"
          icon="pi pi-shopping-cart"></button>
      </div>
    </dx-data-grid>
  </section>
</app-page-wrapping>

<app-modal-validate-certificate
  (closeModal)="listenModalValidate($event)"
  [company]="selectedCompany"
  [open]="showModalCertificate"></app-modal-validate-certificate>
