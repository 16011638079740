import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalNfeXmlComponent } from './modal-nfe-xml.component';

@NgModule({
  declarations: [ModalNfeXmlComponent],
  exports: [ModalNfeXmlComponent],
  imports: [CommonModule, ModalModule, ButtonModule, LoadingFragmentModule]
})
export class ModalNfeXmlModule {}
