import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalCustomGridConfirmationComponent } from './modal-custom-grid-confirmation.component';

@NgModule({
  declarations: [ModalCustomGridConfirmationComponent],
  exports: [ModalCustomGridConfirmationComponent],
  imports: [CommonModule, ModalModule, ButtonModule]
})
export class ModalCustomGridConfirmationModule {}
