export const EXPORT_OPTIONS = [
  {
    value: 1,
    name: 'Exportar PDF',
    icon: 'pdffile'
  },
  {
    value: 2,
    name: 'Exportar CSV',
    icon: 'textdocument'
  },
  {
    value: 3,
    name: 'Exportar XLSX',
    icon: 'xlsxfile'
  },
  {
    value: 4,
    name: 'Exportar XLS',
    icon: 'xlsfile'
  }
];
