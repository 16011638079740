import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationDropdownService {
  private display: boolean;
  private displaySubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  open(): void {
    this.displaySubject.next(true);
    this.display = true;
  }

  close(): void {
    this.displaySubject.next(false);
    this.display = false;
  }

  getDisplay(): Observable<boolean> {
    return this.displaySubject.asObservable();
  }
}
