<app-modal
  [width]="'50vw'"
  modalTitle="Legenda"
  (closeModal)="close()"
  [removeTitleMargin]="false"
  [display]="open">
  <section content class="container-modal-legend">
    <div class="content-legend">
      <span class="mb-4">
        LEGENDA DE COMPARAÇÃO ENTRE CR (CUSTO REAL) E CM (CUSTO MÉDIO)
      </span>
      <div class="row">
        <span class="legend-box legend-blue-box">Azul (Excelente)</span>
        <span class="description">CR menor que 5% do CM</span>
      </div>
      <div class="row">
        <span class="legend-box legend-green-box">Verde (Boa)</span>
        <span class="description">CR entre 2% e 5% menor que o CM</span>
      </div>
      <div class="row">
        <span class="legend-box legend-yellow-box">Amarelo (Na Média)</span>
        <span class="description">
          CR difere em 2% para mais ou para menos do CM
        </span>
      </div>
      <div class="row">
        <span class="legend-box legend-red-box">Vermelho (Ruim)</span>
        <span class="description">CR maior que 2% do CM</span>
      </div>
      <div class="row">
        <span class="legend-box legend-purple-box">
          Roxo (Provável compra desmembrada)
        </span>
        <span class="description">
          CR menor que 30% do CM ou CR maior que 50% do CM
        </span>
      </div>
    </div>
  </section>
  <section footer class="container-buttons">
    <button
      pButton
      type="button"
      (click)="close()"
      label="Cancelar"
      class="btn-cancel"
      icon="pi pi-times"></button>
  </section>
</app-modal>
