import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PageWrappingComponent } from './page-wrapping.component';

@NgModule({
  declarations: [PageWrappingComponent],
  exports: [PageWrappingComponent],
  imports: [CommonModule]
})
export class PageWrappingModule {}
