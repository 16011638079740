import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxCheckBoxModule } from 'devextreme-angular';
import { ButtonModule } from 'primeng/button';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { ModalTagSelectionComponent } from './modal-tag-selection.component';

@NgModule({
  declarations: [ModalTagSelectionComponent],
  exports: [ModalTagSelectionComponent],
  imports: [
    CommonModule,
    ModalModule,
    LoadingFragmentModule,
    DxCheckBoxModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule
  ]
})
export class ModalTagSelectionModule {}
