import { Component, OnInit } from '@angular/core';

import { CompanyListComponent } from '../../../components/company-list/company-list.component';
import { DigitalCertificateManagementComponent } from '../../../components/digital-certificate-management/digital-certificate-management.component';
import { PdvIntegrationManagementComponent } from '../../../components/pdv-integration-management/pdv-integration-management.component';
import { Step } from '../../models/interfaceObjects/step/step';
import { AuthService } from '../../services/auth/auth.service';
import { BreadcrumbSetupService } from '../../services/breadcrumb-setup-service/breadcrumb-setup.service';
import { InitialSetupService } from '../../services/initial-setup/initial-setup.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-initial-setup',
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss']
})
export class InitialSetupComponent implements OnInit {
  stepsSetupPage = false;
  minHeight = `min-height: calc(100vh - (90px + 47px))`;
  steps: Step[] = [];

  currentStep: Step;

  componentsMap = {
    ChangePasswordComponent: ChangePasswordComponent,
    PdvIntegrationManagementComponent: PdvIntegrationManagementComponent,
    CompanyListComponent: CompanyListComponent,
    DigitalCertificateManagementComponent: DigitalCertificateManagementComponent
  };

  constructor(
    private initialSetupService: InitialSetupService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbSetupService
  ) {}

  async ngOnInit() {
    let { idSetupInicialEtapa } = this.authService.getUserValue();
    idSetupInicialEtapa = idSetupInicialEtapa++;
    const index = idSetupInicialEtapa > 0 ? idSetupInicialEtapa : 0;
    await this.breadcrumbService.reload();
    this.breadcrumbService.setCurrentIndex(index);
    this.breadcrumbService.getSteps().subscribe(steps => {
      this.steps = steps;
    });
    this.breadcrumbService.getCurrentStep().subscribe(current => {
      this.currentStep = current;
    });
  }
}
