import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxDataGridModule, DxDropDownButtonModule } from 'devextreme-angular';
import { NgxMaskModule } from 'ngx-mask';
import { ButtonModule } from 'primeng/button';

import { PageWrappingModule } from '../../core/components/page-wrapping/page-wrapping.module';
import { GridProductAdvancedReportComponent } from './grid-product-advanced-report.component';

@NgModule({
  declarations: [GridProductAdvancedReportComponent],
  imports: [
    CommonModule,
    PageWrappingModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    ButtonModule,
    NgxMaskModule
  ],
  exports: [GridProductAdvancedReportComponent]
})
export class GridProductAdvancedReportModule {}
