<section *ngIf="form" class="p-fluid grid formgrid mt-2" [formGroup]="form">
  <div class="lg:col-12 flex">
    <div class="input-group field lg:col-6 md:col-6 col-12">
      <label for="emitente">Usuarios Master</label>
      <div class="input-group field container-periodo-button">
        <p-multiSelect
          optionLabel="nomeLogin"
          optionValue="id"
          id="usuarioMaster"
          [itemSize]="30"
          [virtualScroll]="true"
          [options]="usuariosMaster"
          formControlName="idUsuariosMaster"></p-multiSelect>
      </div>
    </div>
  </div>
  <div class="lg:col-12 flex">
    <div class="input-group field lg:col-6 md:col-6 col-12">
      <label for="empresa">Empresas</label>
      <div class="input-group field container-periodo-button">
        <input
          formControlName="cnpjEmpresas"
          id="empresa"
          type="text"
          pInputText />
      </div>
    </div>
  </div>
</section>
