export function exists(data) {
  if (data?.constructor === Object) {
    return Object.keys(data).length;
  }

  if (!data) {
    return false;
  }
  return !data?.length;
}
