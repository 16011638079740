import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { LoadingFragmentModule } from '../../../core/components/loading-fragment/loading-fragment.module';
import { ModalModule } from '../../../core/components/modal/modal.module';
import { SafeModule } from '../../../core/pipes/safe/safe.module';
import { ModalNfePrintComponent } from './modal-nfe-print.component';

@NgModule({
  declarations: [ModalNfePrintComponent],
  exports: [ModalNfePrintComponent],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    LoadingFragmentModule,
    SafeModule
  ]
})
export class ModalNfePrintModule {}
