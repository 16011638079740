<p-sidebar
  class="sidebar-filters-container"
  [(visible)]="visible"
  [style]="{ width: width }"
  [baseZIndex]="9000"
  (onHide)="close()"
  position="right">
  <ng-template *ngIf="titleSidebar" pTemplate="header">
    {{ titleSidebar }}
  </ng-template>
  <ng-template content pTemplate="content">
    <ng-content select="[content]"></ng-content>
  </ng-template>

  <ng-template content pTemplate="footer">
    <ng-content select="[footer]"></ng-content>
    <!--<i
      class="fadeoutdown animation-duration-1000 animation-iteration-infinite font-bold color-blue border-round m-2 px-5 py-3 pi pi-arrow-down"></i>-->
  </ng-template>
</p-sidebar>
