import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TieredMenuModule } from 'primeng/tieredmenu';

import { DashboardModule } from '../dashboard/dashboard.module';
import { ModalModule } from '../modal/modal.module';
import { BreadcrumbSetupComponent } from './breadcrumb-setup/breadcrumb-setup.component';
import { InitialSetupRoutingModule } from './initial-setup-routing.module';
import { InitialSetupComponent } from './initial-setup.component';
import { ModalVideoSetupComponent } from './modal-video-setup/modal-video-setup.component';
import { TopbarSetupComponent } from './topbar-setup/topbar-setup.component';

@NgModule({
  declarations: [
    InitialSetupComponent,
    TopbarSetupComponent,
    BreadcrumbSetupComponent,
    ModalVideoSetupComponent
  ],
  exports: [InitialSetupComponent],
  imports: [
    CommonModule,
    DashboardModule,
    RouterModule,
    InitialSetupRoutingModule,
    TieredMenuModule,
    ModalModule
  ]
})
export class InitialSetupModule {}
