export const CONFEAR_PERMISSIONS = {
  CONTROLE: 1,
  CONSOLIDADO: 2,
  MONITORAMENTO_NFE: 3,
  MANIFESTACAO_NFE: 4,
  ALERTAS: 5,
  CONDICAO_COMERCIAL: 6,
  ANALISE_MERCADO: 7,
  FECHAMENTO: 8,
  BIBLIOTECA_NFE: 9,
  IMPORTACAO_NOTAS: 10,
  CONTAS_PAGAR: 11,
  GESTAO_EMPRESAS: 12,
  GESTAO_USUARIOS: 13,
  GESTAO_FORNECEDORES: 14,
  GESTAO_ALERTAS: 15,
  GESTAO_ETIQUETAS: 16,
  GRUPOS_USUARIOS: 17,
  EXCLUIR_ALERTAS: 20,
  ABRIR_NFE_IMPRESSAO: 21,
  ABRIR_XML: 23,
  VISUALIZAR_JUSTIFICATIVA_CORRECAO: 25,
  VISUALIZAR_CARTA_CORRECAO: 26,
  VERIFICAR_SITUACAO_NFE_SEFAZ_ESTADUAL: 27,
  ANALISAR_NFE: 28,
  ETIQUETAS: 29,
  HISTORICO_MANIFESTACAO: 30,
  ABRIR_NFE_SITE_SEFAZ: 38,
  RELATORIO_AVANCADO_PRODUTO: 39,
  TODOS: 99
};
