import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { EVENTO_MANIFESTACAO } from '../../../core/consts/EVENTO_MANIFESTACAO';
import { ModalService } from '../../../core/services/modal/modal.service';

@Component({
  selector: 'app-modal-nfe-manifest-confirmation',
  templateUrl: './modal-nfe-manifest-confirmation.component.html',
  styleUrls: ['./modal-nfe-manifest-confirmation.component.scss']
})
export class ModalNfeManifestConfirmationComponent
  implements OnInit, OnDestroy
{
  open: boolean;
  loading: boolean;
  data: any;
  nfes: string = '';
  subscription: Subscription[] = [];
  form: FormGroup;
  private modalName: string | undefined;
  OPERACAO_NAO_REALIZADA = EVENTO_MANIFESTACAO.OPERACAO_NAO_REALIZADA;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.listenModal();
  }

  buildForm() {
    this.form = this.fb.group({
      justificativa: [
        null,
        [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(255)
        ]
      ]
    });
  }

  get justificativaControl() {
    return this.form.get('justificativa');
  }

  setValidators() {
    this.justificativaControl?.setValidators([
      Validators.required,
      Validators.minLength(15),
      Validators.maxLength(255)
    ]);
  }

  removeValidators() {
    this.justificativaControl?.clearValidators();
    this.justificativaControl?.updateValueAndValidity();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res.id === CONFEAR_PERMISSIONS.MANIFESTACAO_NFE &&
        res.open &&
        res.name === 'MODAL_CONFIRMACAO_MANIFESTACAO'
      ) {
        this.data = res.data;
        this.modalName = res.name;
        this.open = true;

        if (this.data?.nfes?.length) {
          this.data?.nfes?.forEach((element, index) => {
            this.nfes =
              index === 0
                ? this.nfes.concat(element?.numeroNfe)
                : this.nfes.concat(`,${element?.numeroNfe}`);
          });
        }
      }
    });

    this.subscription.push(subscription);
  }

  close() {
    this.form.reset();
    this.open = false;
    this.data = undefined;
    this.nfes = '';
    this.setValidators();
  }

  confirm() {
    if (this.OPERACAO_NAO_REALIZADA !== this.data?.eventoManifestacao?.id) {
      this.removeValidators();
    }
    if (this.form.valid) {
      this.modalService.answer({
        id: CONFEAR_PERMISSIONS.MANIFESTACAO_NFE,
        name: this.modalName,
        data: {
          justificativa: this.form.value.justificativa,
          eventoManifestacao: this.data.eventoManifestacao,
          nfes: this.data?.nfes
        }
      });
      this.close();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Justificativa inválida',
        key: 'global-toast',
        sticky: true,
        detail:
          'A justificativa deve conter no mínimo 15 e no máximo 255 caracteres.'
      });
    }
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
