<section class="container-icon" [style.justifyContent]="justifyContent">
  <em
    *ngIf="icon"
    [style.fontSize]="fontSize"
    [style.margin]="margin"
    [style.marginTop]="marginTop"
    [style.marginLeft]="marginLeft"
    [style.marginRight]="marginRight"
    [style.marginBottom]="marginBottom"
    [style.color]="icon?.cor"
    [class]="[icon.class]">
    {{ icon?.lib === 'material' ? icon?.nome : '' }}
  </em>
</section>
