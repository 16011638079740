import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { environment } from '../../../../environments/environment';
import { User } from '../../../models/entities/user/user';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-topbar-setup',
  templateUrl: './topbar-setup.component.html',
  styleUrls: ['./topbar-setup.component.scss']
})
export class TopbarSetupComponent implements OnInit {
  itemsUserMenu: MenuItem[] = [
    {
      id: '3',
      label: 'Sair',
      icon: 'pi pi-sign-out',
      command: () => this.exit()
    }
  ];
  user: User;
  dropdownDisplay: boolean;
  masterLogged: any;
  env;
  openVideoTutorial: boolean;

  constructor(private authService: AuthService) {}

  async ngOnInit() {
    this.env = environment;
    await this.getUser();
  }

  async getUser() {
    this.authService.getUserObservable().subscribe(response => {
      if (response) {
        this.user = response;
        this.masterLogged = User.getLoggedMaster(this.user);
        if (!this.user?.indPossuiMultiplosMaster) {
          this.itemsUserMenu = this.itemsUserMenu.filter(
            item => item.id !== '1'
          );
        }
      }
    });
  }

  exit() {
    this.authService.logout().subscribe();
  }

  openVideo() {
    this.openVideoTutorial = true;
  }

  listenModalVideoSetup(event) {
    if (event.close) {
      this.openVideoTutorial = false;
    }
  }
}
