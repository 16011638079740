import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Etiqueta } from '../../models/entities/etiqueta/etiqueta';
import { DefaultResponse } from '../../models/responses/default-response';

@Injectable({
  providedIn: 'root'
})
export class EtiquetaService {
  private url = `${environment.baseUrl}/etiquetas`;

  constructor(private http: HttpClient) {}

  list(
    listarTodas = true,
    indIncluirNFeSemEtiquetas = false
  ): Observable<DefaultResponse<Etiqueta>> {
    return this.http
      .get<DefaultResponse<Etiqueta>>(
        `${this.url}?listarTodas=${listarTodas}&IndIncluirNFeSemEtiquetas=${indIncluirNFeSemEtiquetas}`
      )
      .pipe(
        map(etiquetas => {
          return etiquetas;
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.url}/${id}`).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  edit(tag: Etiqueta): Observable<any> {
    return this.http.put<any>(`${this.url}/${tag.id}`, tag).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }

  save(tag: Etiqueta): Observable<any> {
    return this.http.post<any>(`${this.url}`, tag).pipe(
      catchError(err => {
        return of(err);
      })
    );
  }
}
