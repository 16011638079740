import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFEAR_PERMISSIONS } from '../../../core/consts/CONFEAR-PERMISSIONS';
import { Nfe } from '../../../core/models/entities/nfe/nfe';
import { ModalService } from '../../../core/services/modal/modal.service';
import { NfeService } from '../../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-modal-view-manifestation-justification',
  templateUrl: './modal-view-manifestation-justification.component.html',
  styleUrls: ['./modal-view-manifestation-justification.component.scss']
})
export class ModalViewManifestationJustificationComponent
  implements OnInit, OnDestroy
{
  open: boolean;
  loading: boolean;
  nfe: Nfe;
  subscription: Subscription[] = [];
  errorMessage: string;
  justificativaManifestacao: string;

  constructor(
    private nfeService: NfeService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.listenModal();
  }

  listenModal() {
    const subscription = this.modalService.listenChanges().subscribe(res => {
      if (
        res?.id === CONFEAR_PERMISSIONS.VISUALIZAR_JUSTIFICATIVA_CORRECAO &&
        res.open
      ) {
        this.nfe = res.data;
        if (this.nfe.chaveNfe != null) {
          this.loading = true;
          this.open = true;
        }
        this.getJustification();
      }
    });

    this.subscription.push(subscription);
  }

  getJustification() {
    this.nfeService
      .getJustification(this.nfe.chaveNfe)
      .subscribe((response: any) => {
        if (response?.dados) {
          this.justificativaManifestacao =
            response.dados?.justificativaManifestacao;
        }
        if (response?.error) {
          this.errorMessage = response.error?.mensagem?.reduce((acc, cc) => {
            this.errorMessage.concat(cc);
          });
        }
        this.loading = false;
      });
  }

  close() {
    this.errorMessage = '';
    this.open = false;
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
